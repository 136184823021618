import Handlebars from 'handlebars';
import Widget from '../modules/Widget';
import { formatCurrency } from '@yesplz/core';

const LAYOUT_TEMPLATE = `{{{productsHtml}}}`;

const PRODUCT_TEMPLATE = `
<a href="{{url}}" class="yesplz-product-detail">
  <div class="yesplz-product-detail-image">
    <img src="{{frontImg}}" width="400" id="objImg">
  </div>
  <div class="yesplz-product-detail-description">
    <h4>{{brand}}</h4>
    <h2>{{name}}</h2>
    <p class="yesplz-product-detail-price">
    {{#if isSale}}
      {{originalPrice}} - {{price}}
    {{else}}
      {{price}}
    {{/if}}
    </p>
  </div>
</a>
`;

const { document } = window;

class CFProducts extends Widget {
  defaultParams = {
    limit: 20,
    templates: {
      product: PRODUCT_TEMPLATE,
      layout: LAYOUT_TEMPLATE,
    },
    productUrl: (product) => (`product.html?productId=${product.productId}`),
    currency: {},
    onRendered: null,
    productLinkTarget: '_self',
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-similar-products';
    this.mainElement = element;

    this.productTemplate = Handlebars.compile(this.params.templates.product || PRODUCT_TEMPLATE);
    this.layoutTemplate = Handlebars.compile(this.params.templates.layout || LAYOUT_TEMPLATE);
  }

  didMount() {
    this.main.fetchCFProducts(0, this.params.limit)
      .then((data) => {
        console.log(data);
        this.renderProducts(data);
      });
  }

  formatCurrency(number) {
    return formatCurrency(number,  this.params.currency);
  }

  renderProducts(data) {
    this.mainElement.innerHTML = '';
    let productsHtml = '';
    const renderedProducts = [];
    data.recommends.forEach(item => {
      const { product } = item;
      const discountValue = (
        product.originalPrice && product.salePrice !== product.originalPrice
          ? 100 - Math.ceil((product.salePrice / product.originalPrice) * 100)
          : ''
      );

      const productUrl = `${this.params.productUrl(product)}&productId=${product.productId}&yesplz=Y&ymal`;
      const renderedProduct = {
        ...product,
        url: productUrl,
        srcUrl: productUrl,
        isSale: product.originalPrice && product.salePrice !== product.originalPrice,
        price: this.formatCurrency(product.salePrice),
        originalPrice: this.formatCurrency(product.originalPrice),
        discountValue,
        frontImg: product.frontImgSrc,
        brand: product.brandName,
        brandName: product.brandName,
        name: product.productName,
        linkTarget: this.params.productLinkTarget,
      };

      productsHtml = productsHtml + this.productTemplate(renderedProduct);

      renderedProducts.push(renderedProduct);
    });

    const renderedLayout = this.layoutTemplate({ productsHtml });

    this.mainElement.insertAdjacentHTML('beforeend', renderedLayout);

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(renderedProducts);
    }
  }

  render() {
    return this.mainElement;
  }
}

export default (params) => {
  return new CFProducts(params);
};
