import isEqual from 'lodash/isEqual';
import client from '@yesplz/client';
import Widget from '../modules/Widget';

const { document } = window;

class PriceFromToFilter extends Widget {
  defaultParams = {
    title: 'Price',
    searchButtonText: 'Search',
    showSearchButton: true,
    currencyMark: '$',
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-price-from-to-filter';
    this.mainElement = element;

    this.pricesByCategories = {};

    this.keyUpTimeout = null;
  }

  async didMount() {
    const { categoryId } = this.state.filter;
    const { searchAdditionalParams } = this.state.config;
    this.pricesByCategories[categoryId] = await client.pricesByCategories(categoryId, searchAdditionalParams);
  }

  willUnmount() {
    if (this.keyUpTimeout) {
      clearTimeout(this.keyUpTimeout);
    }
  }

  didUpdate(prevState) {
    if (
      !isEqual(prevState.filter.params.price, this.state.filter.params.price)
    ) {
      const price = this.state.filter.params.price;

      this.priceFromInput.value = price ? price.split('-')[0] : '';
      this.priceToInput.value = price ? price.split('-')[1] : '';
    }
  }

  priceInputChange = () => {
    const priceFrom = parseInt(this.priceFromInput.value);
    const priceTo = parseInt(this.priceToInput.value);

    if (!this.isLowerPriceValid(priceFrom)) {
      this.priceFromInput.classList.add('is-invalid');
      return;
    }

    if (!this.isHigherPriceValid(priceFrom, priceTo)) {
      this.priceToInput.classList.add('is-invalid');
      return;
    }

    this.priceFromInput.classList.remove('is-invalid');
    this.priceToInput.classList.remove('is-invalid');

    const { params } = this.state.filter;
    this.setFilter({
      params: {
        ...params,
        price: `${priceFrom  || ''}-${priceTo && priceTo > priceFrom ? priceTo : ''}`,
      },
    });
  }

  get currentCategoryPrices() {
    const { categoryId } = this.state.filter;
    return this.pricesByCategories[categoryId];
  }

  get lowestPrice() {
    const prices = this.currentCategoryPrices;
    return parseInt(Object.values(prices)[0]);
  }

  isLowerPriceValid(priceString) {
    const price = parseInt(priceString);

    return price >= this.lowestPrice;
  }

  isHigherPriceValid(lowerPriceString, higherPriceString) {
    if (higherPriceString === '') return true;

    const lowerPrice = parseInt(lowerPriceString);
    const higherPrice = parseInt(higherPriceString);
    return higherPrice > this.lowestPrice && lowerPrice < higherPrice;
  }

  handleInputKeyUp = (e) => {
    let value = e.target.value.replace(/\D/, '');

    e.target.value = value;

    if (this.keyUpTimeout) {
      clearTimeout(this.keyUpTimeout);
    }
    this.keyUpTimeout = setTimeout(this.priceInputChange, 500);
  }

  clearPrices() {
    this.mainElement.innerHTML = '';
  }

  get prices() {
    const { params } = this.state.filter;
    return {
      from: params.price ? params.price.split('-')[0] : '',
      to: params.price ? params.price.split('-')[1] : '',
    };
  }

  renderPrices() {
    const prices = this.prices;
    let priceInputHtml = `
      <span class="price-input">
        <input type="text" id="priceFrom" value="${prices.from || ''}" />
      </span>
      <span class="price-input">
        <input type="text" id="priceTo" value="${prices.to || ''}" />
      </span>
    `;

    if (this.params.showSearchButton) {
      priceInputHtml += `<button>${this.params.searchButtonText}</button>`;
    }

    this.mainElement.innerHTML = priceInputHtml;

    this.priceFromInput = this.mainElement.querySelector('#priceFrom');
    this.priceToInput   = this.mainElement.querySelector('#priceTo');

    if (this.params.showSearchButton) {
      this.mainElement.querySelector('button')
        .addEventListener('click', (e) => {
          e.stopPropagation();
          this.priceInputChange();
        });
    }

    this.priceFromInput.addEventListener('keyup', this.handleInputKeyUp);
    this.priceToInput.addEventListener('keyup', this.handleInputKeyUp);
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    this.renderPrices();
    return this.mainElement;
  }
}

export default (params) => {
  return new PriceFromToFilter(params);
};
