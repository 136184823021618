import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function Home({ history }) {
  const classes = useStyles();
  const [shops, setShops] = useState([]);
  const fetchShops = async () => {
    const { data } = await axios.get('/shops.json');
    setShops(Object.keys(data));
  };
  useEffect(() => {
    fetchShops();
  }, []);

  const handleChange = (shop) => {
    history.push(`/${shop}/review`);
  }

  return (
    <div className={classes.root}>
      <h2>Review Recommendations</h2>
      <h3>Select Shopify Merchant</h3>
      <Select
        value={'default'}
        onChange={(event) => {
          handleChange(
            event.target.value !== 'default'
              ? event.target.value
              : ''
          );
        }}
      >
        <MenuItem
          value={'default'}
          className='category-menu-item'
        >Select merchant ....</MenuItem>
        {shops.map(shop => (
          <MenuItem
            key={`category-${shop}`}
            value={shop}
            className='category-menu-item'
          >
            {shop}
          </MenuItem>
        ))}
      </Select>
      <br /><br /><br /><br />
      <h2>Review Style Filter Filtering</h2>
      <h3>Select Shopify Merchant</h3>
      <Select
        value={'default'}
        onChange={(event) => {
          history.push(`/${event.target.value}/visual-filter`);
        }}
      >
        <MenuItem
          value={'default'}
          className='category-menu-item'
        >Select merchant ....</MenuItem>
        {[
          'yesplz-fashion',
          'up-swag',
          'ayahshop-jo',
          'markati-me',
        ].map(shop => (
          <MenuItem
            key={`category-${shop}`}
            value={shop}
            className='category-menu-item'
          >
            {shop}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    padding: '30px',
  },
});
