
const SET_CATEGORY_LATEST_STYLE = 'latestStyles/SET_CATEGORY_LATEST_STYLE'

export default (state = {}, action = {}) => {
  switch (action.type) {
    case SET_CATEGORY_LATEST_STYLE:
      const cleanedFilter = { ...action.filter };
      delete cleanedFilter.offset;
      delete cleanedFilter.limit;
      if (cleanedFilter.params)
        delete cleanedFilter.params.editorspick;

      return {
        ...state,
        [action.categoryId]: { ...cleanedFilter },
      };
    default:
      return state;
  }
}

export function setCategoryLatestStyle(categoryId, filter) {
  return { type: SET_CATEGORY_LATEST_STYLE, categoryId, filter };
}
