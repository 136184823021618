import { createReducer } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import { saveCategoryFilter } from '../actions/savedFiltersActions';

const initialState = {};
const FILTER_PROPS_TO_SAVE = ['categoryId', 'category', 'categorySlice', 'bodyPart', 'params', 'occasion', 'presetIndex'];

const savedFilters = createReducer(initialState, {
  [saveCategoryFilter.type]: (state, { payload }) => ({
    ...state,
    [payload.categoryId]: cloneDeep(pick(payload, FILTER_PROPS_TO_SAVE)),
  }),
});

export default savedFilters;
