import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import ArrowBack from '@material-ui/icons/ArrowBack';
import TooltipSwitch from 'components/TooltipSwitch';
import SearchBar from 'modules/review/SearchBar';
import { BASE_IMG_PATH } from '@yesplz/core-web/config/constants';

import Loading from 'components/Loading';
import Footer from 'components/Footer';
import ProductGrid from './ProductGrid';

import './ReviewProduct.scss';

export default ({ match, history }) => {
  const classes = useStyles();
  const { params: { productId, shop } } = match;

  const [isTooltipActive, setIsTooltipActive] = useState(true);
  const [product, setProduct] = useState(null);
  const [recommends, setRecommends] = useState(null);

  async function fetchRecommendedProducts(product_id) {
    const { data } = await axios.get('/yml.json', {
      params: {
        shop,
        product_id,
        variant_id: null,
        admin: 1,
      },
    });
    setProduct(data.product);
    setRecommends(data.recommends)
  }

  useEffect(() => {
    fetchRecommendedProducts(productId)
  }, [productId]);

  if (!product) return <Loading />;

  const backUrl = null;
  let imageUrl = (
    product.front_img_src
      ? product.front_img_src
      : product.front_img
        ? product.front_img
        : product.variants[0].images[0].srcURI
  );

  if (!imageUrl.startsWith('http')) {
    imageUrl = BASE_IMG_PATH + '/' + imageUrl;
  }

  return (
    <div className="ReviewProduct-product-detail" id="MainScroll">
      <Sticky
        scrollElement="#MainScroll"
        stickyClassName={classes.sticky}
      >
        <div className={classes.header}>
          <div className={classes.searchRoot}>
            <SearchBar
              onSearch={(search) => {
                history.push(`/${shop}/review?search=${search}`);
              }}
              onRandomClick={() => {
                history.push(`/${shop}/review?sort=random`);
              }}
            />
          </div>
          <div className={classes.switchRoot}>
            <TooltipSwitch
              checked={isTooltipActive}
              onChange={() => setIsTooltipActive(!isTooltipActive)}
            />
          </div>
        </div>
      </Sticky>
      <div className='ReviewProduct-product-detail-container'>
        <div className='horizontal-container'>
          <div className='product-image'>
            <img src={imageUrl} alt='' />
          </div>
          <div className='product-detail'>
            <div className='product-id'>{product.productId || product.product_id}</div>
            <h3 className='product-name'>{product.productName || product.name}</h3>
            <p className='product-brand'>{product.brandName || product.name}</p>
            <div className='product-sizes' />
            <div className="product-prediction-wrap">
              <div className='product-prediction'>{product.text_predictions}</div>
              <div className='product-prediction'>{product.raw_predictions}</div>
            </div>
            {backUrl && <div className="ReviewProduct-product-detail-button-container">
              <Button
                component={Link}
                variant="outlined"
                color="default"
                size="large"
                to={backUrl}
              >
                <SvgIcon>
                  <ArrowBack />
                </SvgIcon> Back to the list
              </Button>
            </div>}
          </div>
        </div>
      </div>
      <div className='product-list-container'>
        <h3>YOU MAY ALSO LIKE</h3>
        {
          recommends
          &&
          <div className="ReviewProduct-recommended">
            {recommends.map((product, index) => (
              <ProductGrid
                key={(product.product.product_id) + index}
                product={product}
                isTooltipActive={isTooltipActive}
              />
            ))}
          </div>
        }
      </div>
      {backUrl && <div className="ReviewProduct-product-detail-button-bottom-container">
        <Button
          component={Link}
          variant="outlined"
          color="default"
          size="large"
          to={backUrl}
        >
          <SvgIcon>
            <ArrowBack />
          </SvgIcon> Back to the list
        </Button>
      </div>}
      <Footer />
    </div>
  );
}

const useStyles = makeStyles({
  sticky: {
    zIndex: 1000,
    boxShadow: '0 10px 8px 0 rgba(0, 0, 0, 0.08)',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px 15px 20px',
    backgroundColor: '#ffffff',
    position: 'relative',
  },
  searchRoot: {
    flex: 1,
  },
  switchRoot: {},
});
