import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const days = [
  { label: '1 day', value: '1' },
  { label: '7 days', value: '7' },
  { label: '30 days', value: '30' },
];

class NewArrivalsFilter extends Widget {
  defaultParams = {
    title: 'New Arrivals',
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.ul = document.createElement('ul');

    this.mainElement.appendChild(this.ul);
  }

  didMount() {
    this.renderOptions();
  }

  didUpdate(prevState) {
    if (
      !isEqual(
        prevState.search?.filters?.newArrivals,
        this.state.search?.filters?.newArrivals
      )
    ) {
      this.renderOptions();
    }
    else if (
      !isEqual(prevState.filter.params.newArrivals, this.state.filter.params.newArrivals)
    ) {
      const prevNewArrivals = prevState.filter.params.newArrivals;
      const newArrivals = this.state.filter.params.newArrivals;
      const addedItems = difference(newArrivals, prevNewArrivals);
      const removedItems = difference(prevNewArrivals, newArrivals);

      addedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-new-arrivals-id="${val}"]`);
        if (!el) return;

        el.classList.add('active');
      });
      removedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-new-arrivals-id="${val}"]`);
        if (!el) return;

        el.classList.remove('active');
      });
    }
  }

  handleItemClick = (e, value) => {
    e.stopPropagation();
    const { categoryId, params } = this.state.filter;
    const newParams = { ...params };

    EventEmitter.emit('newArrivalsClick', {
      categoryId,
      newArrivals: value,
    });

    if (!Array.isArray(newParams.newArrivals) || !newParams.newArrivals) newParams.newArrivals = [];

    newParams.newArrivals = [
      value,
    ];

    EventEmitter.emit('newArrivalsApplied', {
      categoryId,
      newArrivals: value,
    });

    if (newParams.newArrivals.length === 0) delete newParams.newArrivals;

    this.setFilter({
      params: newParams,
    });
  }

  isOptionAvailable = (value) => {
    if (!this.state.search?.filters?.newArrivals) return true;

    const options = this.state.search?.filters?.newArrivals || [];
    return options.includes(value);
  }

  renderOptions() {
    const { params } = this.state.filter;
    this.ul.innerHTML = '';

    days.forEach((option, refIndex) => {
      if (!this.isOptionAvailable(option.value)) return;

      const li = document.createElement('li');
      li.innerHTML = `${option.label}`;
      li.setAttribute('data-new-arrivals-id', option.value);
      li.setAttribute('data-new-arrivals-ref-index', refIndex);
      this.ul.appendChild(li);

      if (params.newArrivals && params.newArrivals.includes(option.value)) {
        li.classList.add('active');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, option.value));
    });
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new NewArrivalsFilter(params);
};
