import Cookies from "js-cookie";
import openerImage from "./assets/images/ai-stylist-opener.png";
import { getYPID } from "@yesplz/visualfilter/src/modules/analytics";
import "./AiStylist.scss";
import EventEmitter from "@yesplz/visualfilter/src/modules/EventEmitter";
import { debounce } from "lodash";

const ypid = getYPID();
const state = JSON.parse(
  window.localStorage.getItem("yesplz-stylist-store")
) || {
  isOpen: false,
  messages: [],
};
const frameId = "yesplz-iframe";

export class AiStylist {
  static #userId;
  static #isLoginRequiredForFavorites;
  constructor(params = {}) {
    let {
      opener,
      useFloatingButton = false,
      url,
      navigateOnProductClick = true,
      hideOpenerOnFrameOpen = true,
      preloadFrame = false,
      isLoginRequiredForFavorites = false,
    } = params;
    this.opener = opener;
    this.frame = null;
    this.buildProductUrl = params?.buildProductUrl || null;
    this.navigateOnProductClick = navigateOnProductClick;
    this.hideOpenerOnFrameOpen = hideOpenerOnFrameOpen;
    this.isFrameCreated = false;
    this.onFavoriteProduct = params?.onFavoriteProduct ? debounce(params.onFavoriteProduct, 300, { leading: true, trailing: true }) : null;
    this.onAccessFavoriteList = params?.onAccessFavoriteList ? debounce(params.onAccessFavoriteList, 300,{ leading: true, trailing: true }) : null;
    this.isLoginRequiredForFavorites = isLoginRequiredForFavorites;
    AiStylist.#isLoginRequiredForFavorites = isLoginRequiredForFavorites;
    AiStylist.#userId = localStorage.getItem('ypuid') || null;

    if (typeof opener === "string") {
      this.opener = document.querySelector(opener);

      if (!this.opener) {
        console.error(new Error(`AI Stylist opener ${opener} doesn't exist on the page.`));
        return;
      }
    }

    if(this.isLoginRequiredForFavorites && !(this.onFavoriteProduct || this.onAccessFavoriteList)) {
        console.error(new Error("onFavoriteProduct and onAccessFavoriteList are required when isLoginRequiredForFavorites is true"));
    }

    this.url = url || process.env.STYLIST_EMBEDDED_URL || "https://ai-stylist-embedded.yesplz.ai/wconcept";

    if(preloadFrame) {
      this.insertIframe();
    }
    if (!opener && !useFloatingButton) {
      throw new Error(
        "opener - param was not provided. Impossible to assign an open event"
      );
    }

    if (useFloatingButton) {
      this.insertFloatingButton();
    }

    if(this.opener?.dataset) {
      this.opener.dataset.testid = "stylist-opener";
    }

    this.eventListers = {};

    setTimeout(() => {
      this.assignEvents();
      if (state.isOpen) {
        this.open();
      }
    }, 100);
  }

  static {
    AiStylist.#userId = localStorage.getItem('ypuid') || null;
  }

  assignEvents() {
    if (this.opener.addEventListener) {
      this.opener.addEventListener("click", this.open);
      if (this.isTouchScreen()) {
        this.opener.addEventListener("touchstart", this.insertIframe, {
          once: true,
        });
      } else {
        this.opener.addEventListener("mouseenter", this.insertIframe, {
          once: true,
        });
      }
    }

    window.addEventListener("message", this.handleMessageEvent, false);

    const countrySelector = document.querySelector('x-select-country');
    if(countrySelector) {
      const observer = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          if(mutation.attributeName === 'text') {
            const { countryIso, currencyIso } = this.getEswLti();
            AiStylist.setCountryCurrency(countryIso, currencyIso);
          }
        }
      });
      observer.observe(countrySelector, { attributes: true });
    }
  }

  insertFloatingButton() {
    const button = document.createElement("button");
    button.className = "yesplz-stylist-opener";
    button.id = "yesplz-stylist-opener";
    const image = document.createElement("img");
    image.src = openerImage;
    button.appendChild(image);
    document.body.appendChild(button);

    this.opener = button;
  }

  insertIframe = (afterIframeLoadedCallback) => {
    if (this.isFrameCreated) return;
    this.isFrameCreated = true;
    const frame = document.createElement("iframe");
    frame.src = this.withSearchParams(this.url);
    frame.width = 411;
    frame.height = 780;
    frame.id = frameId;
    frame.className = frameId;

    if (typeof afterIframeLoadedCallback === "function") {
      frame.onload = () => {
        setTimeout(() => {
          afterIframeLoadedCallback();
        });
      };
    }
    document.body.appendChild(frame);
    this.frame = frame;
  };

  isTouchScreen() {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  }

  handleMessageEvent = (event) => {
    if (
      [
        "https://wconcept-stylist.dev.yesplz.ai",
        "https://sf-app-stylist.dev.yesplz.ai",
        "http://localhost:5173",
        "https://ai-stylist-embedded.dev.yesplz.ai",
        "https://ai-stylist.yesplz.ai",
        "https://ai-stylist.dev.yesplz.ai",
        "https://ai-stylist-embedded.yesplz.ai",
        "https://ai-stylist-embedded-dev.yesplz.ai",
      ].includes(event.origin) === false
    )
      return;

    try {
      const message = event.data;
      if (message.type === "ready") {
        this.frame.contentWindow.postMessage(
          {
            type: "init",
            data: {
              ypid,
              messages: state.messages,
              locationPathname: state.locationPathname,
              userId: AiStylist.#userId || null,
              isLoginRequiredForFavorites: this.isLoginRequiredForFavorites,
            },
          },
          "*"
        );
      } else if (message.type === "close") {
        this.close();
        this.updateStore({
          isOpen: false,
          isMinimized: false,
        });
      } else if (message.type === "minimize") {
        this.minimize();
        this.updateStore({
          isOpen: true,
          isMinimized: true,
        });
      } else if (message.type === "maximize") {
        this.maximize();
        this.updateStore({
          isOpen: true,
          isMinimized: false,
        });
      } else if (
        message.type === "hybridSearchProductClick" &&
        this.navigateOnProductClick &&
        message.productId
      ) {
        const productUrl =
          typeof this.buildProductUrl === "function"
            ? this.buildProductUrl(message)
            : message.productUrl ||
              `product.html?productId=${message.productId}`;
        window.location.href = productUrl;
      } else if (
        message.type === "productClick" &&
        message.productId &&
        this.navigateOnProductClick
      ) {
        try {
          this.updateStore({
            isOpen: true,
            isMinimized: false,
            messages: message.messages,
            locationPathname: message.locationPathname,
          });
          const productUrl =
            typeof this.buildProductUrl === "function"
              ? this.buildProductUrl(message)
              : message.productUrl ||
                `product.html?productId=${message.productId}`;
          window.location.href = productUrl;
        } catch (e) {
          console.log("Error on productClick event:");
          console.error(e);
        }
      } else if (message.type === "addToCartClick") {
        if (message.productId) {
          this.notifyEventListeners("addToCartClick", {
            productId: message.productId,
          });
        }
      } else if (message.type === "favoriteProduct") {
        if(this.onFavoriteProduct && message.productId) {
          this.onFavoriteProduct({ productId: message.productId, isFavorite: message.isFavorite });
        }
      }  else if (message.type === "accessFavoriteList") {
        if(this.onAccessFavoriteList) {
          this.onAccessFavoriteList();
        }
      }

    } catch {
      return;
    }
  };

  updateStore(data) {
    window.localStorage.setItem("yesplz-stylist-store", JSON.stringify(data));
  }

  handleOutsideClick = (e) => {
    if (
      this.frame.classList.contains("is-open") &&
      !this.frame.contains(e.target) &&
      !this.opener.contains(e.target)
    ) {
      this.close();
    }
  };

  open = () => {
    if (this.frame) {
      this.frame.classList.add("is-open");
      this.frame.classList.remove("is-minimized");
      if (this.hideOpenerOnFrameOpen) {
        this.opener.style.display = "none";
      }
      document.body.classList.add("yesplz-stylist-open");

      // Detect click outside of iframe
      document.addEventListener("click", this.handleOutsideClick);
    } else {
      this.insertIframe(this.open);
    }
    EventEmitter.emit("aistOpenClick");
  };

  close = () => {
    if (this.frame) {
      this.frame.classList.remove("is-open");
      this.frame.classList.remove("is-minimized");
      if (this.hideOpenerOnFrameOpen) {
        this.opener.style.display = "block";
      }
      document.body.classList.remove("yesplz-stylist-open");

      document.removeEventListener("click", this.handleOutsideClick);
    }
  };

  minimize = () => {
    if (this.frame) {
      this.frame.classList.add("is-minimized");
      document.body.classList.remove("yesplz-stylist-open");
    }
  };

  maximize = () => {
    if (this.frame) {
      this.frame.classList.remove("is-minimized");
      document.body.classList.add("yesplz-stylist-open");
    }
  };

  notifyEventListeners(eventName, data) {
    if (this.eventListers[eventName]) {
      this.eventListers[eventName].forEach((cb) => cb(data));
    }
  }

  addEventListener(eventName, callback) {
    if (typeof callback !== "function") {
      throw new Error("callback is not a function");
    }

    if (this.eventListers[eventName]) {
      this.eventListers[eventName].push(callback);
    } else {
      this.eventListers[eventName] = [callback];
    }
  }

  removeEventListener(eventName, callback) {
    if (this.eventListers[eventName]) {
      this.eventListers[eventName] = this.eventListers[eventName].filter(
        (cb) => cb !== callback
      );
    }
  }

  static async favoriteProduct(productId) {
    if(!productId) {
      throw new Error("productId is required");
    }
    if(this.#isLoginRequiredForFavorites && !this.#userId) {
      return false;
    }
    const result = await this.#debounceToggleFavoriteProduct(productId);
    if(result?.action) {
      const $frame = document.querySelector(`#${frameId}`);
      if($frame) {
        $frame.contentWindow.postMessage(
            {
              type: "favoritesUpdated",
            },
            "*"
        );
      }
      return true;
    }
  }

  static async unFavoriteProduct(productId) {
    if(!productId) {
      throw new Error("productId is required");
    }
    if(this.#isLoginRequiredForFavorites && !this.#userId) {
      return false;
    }
    const result = await this.#debounceToggleFavoriteProduct(productId, true);
    if(result?.action) {
      const $frame = document.querySelector(`#${frameId}`);
      if($frame) {
        $frame.contentWindow.postMessage(
            {
              type: "favoritesUpdated",
            },
            "*"
        );
      }
      return true;
    }
  }

  static #debounceToggleFavoriteProduct = debounce(async function toggleFavoriteProduct(productId, isDelete = false) {
    const retailerMap = {
      wconcept2024: "wconcept",
      usdemo: "demo",
    }
    const response = await fetch(`${process.env.SUPABASE_URL}/functions/v1/user-data/favorites`, {
      method: isDelete ? "DELETE" : "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${process.env.SUPABASE_ANON_KEY}`,
        "x-ypid": ypid,
      },
      body: JSON.stringify({
        "product_id": productId,
        "reaction": "like",
        "is_group": false,
        "retailer": process.env.RETAILER in retailerMap ? retailerMap[process.env.RETAILER] : process.env.RETAILER,
        "user_id": this.#userId || null,
      }),
    });

    return response.json();
  }, 300, { leading: true, trailing: true });

  static setUserId(userId) {
    if(typeof userId !== "string" && userId !== null) {
      throw new Error("userId is required to be a string or null");
      return false;
    }
    if(typeof userId === "string" && userId.length === 0) {
      throw new Error("userId is required to be a string or null");
      return false;
    }
    this.#userId = userId || null;

    if(this.#userId) {
      localStorage.setItem('ypuid', this.#userId);
    } else {
      localStorage.removeItem('ypuid');
    }

    const $frame = document.querySelector(`#${frameId}`);
    if($frame) {
      $frame.contentWindow.postMessage(
          {
            type: "setRetailerUserId",
            data: {
              userId: this.#userId,
            },
          },
          "*"
      );
    }
    return true;
  }

  static getUserId() {
    return localStorage.getItem('ypuid') || null;
  }

  withSearchParams(url) {
    let paramsArr = [];
    let eswLti = this.getEswLti();

    if(eswLti?.countryIso) {
      paramsArr.push(`ltiCountry=${eswLti.countryIso}`);
    }
    if(eswLti?.currencyIso) {
      paramsArr.push(`ltiCurrency=${eswLti.currencyIso}`);
    }

    if(paramsArr.length > 0) {
      const urlObj = new URL(url);
      if(!urlObj.search.startsWith('?')) {
        url += '?';
      }
      url += paramsArr.join('&');
    }

    return url;
  }

  getEswLti() {
    let eswLti = Cookies.get('ESW_LTI');
    if(eswLti) {
      eswLti = JSON.parse(eswLti);
    }
    return {
        countryIso: eswLti?.countryIso === 'null' ? null : (eswLti?.countryIso || null),
        currencyIso: eswLti?.currencyIso === 'null' ? null : (eswLti?.currencyIso || null),
    }
  }

  static setCountryCurrency(countryIso, currencyIso) {
    const $frame = document.querySelector(`#${frameId}`);
    if($frame) {
      $frame.contentWindow.postMessage(
          {
            type: "countryCurrencyChanged",
            data: {
              countryIso,
              currencyIso,
            },
          },
          "*"
      );
    }
  }
}

export default (params) => {
  return new AiStylist(params || {});
};