import numeral from 'numeral';
import Widget from '../modules/Widget';

const { document } = window;

class TotalFound extends Widget {
  defaultParams = {};

  constructor(params) {
    super(params);

    const element = document.createElement('span');
    element.className = this.params.containerClassName || '';
    this.mainElement = element;
  }

  update() {
    this.mainElement.innerHTML = (
      this.state.search && this.state.search.count
        ? numeral(this.state.search.count).format('0,0')
        : 0
    );
  }

  render() {
    this.update();
    return this.mainElement;
  }
}

export default (params) => {
  return new TotalFound(params);
};
