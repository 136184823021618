import axios from 'axios'

// Actions
const REQUEST_USER_INFO = 'admin/REQUEST_USER_INFO'
const REQUEST_USER_INFO_SUCCESS = 'admin/REQUEST_USER_INFO_SUCCESS'
const REQUEST_USER_INFO_FAILED = 'admin/REQUEST_USER_INFO_FAILED'
const REQUEST_ADMIN_LOGIN = 'admin/REQUEST_ADMIN_LOGIN'
const REQUEST_ADMIN_LOGIN_SUCCESS = 'admin/REQUEST_ADMIN_LOGIN_SUCCESS'
const REQUEST_ADMIN_LOGIN_FAILED = 'admin/REQUEST_ADMIN_LOGIN_FAILED'
const REQUEST_ADMIN_LOGOUT = 'admin/REQUEST_ADMIN_LOGOUT'
const REQUEST_ADMIN_CATEGORIES = 'admin/REQUEST_ADMIN_CATEGORIES'
const SET_ADMIN_CATEGORIES = 'admin/SET_ADMIN_CATEGORIES'
const REQUEST_RETAILERS_LIST = 'admin/REQUEST_RETAILERS_LIST'
const REQUEST_RETAILERS_LIST_FAILED = 'admin/REQUEST_RETAILERS_LIST_FAILED'
const REQUEST_RETAILERS_LIST_SUCCESS = 'admin/REQUEST_RETAILERS_LIST_SUCCESS'
const SET_LNG = 'admin/SET_LNG';
const SET_SERVER_TYPE = 'admin/SET_SERVER_TYPE';

const { localStorage } = window;

const accessToken = localStorage.getItem('accessToken');

const defaultState = {
  isLoadingUserInfo: true,
  userInfo: null,
  admin: null,
  token: accessToken,
  authorized: !!accessToken,
  lng: 'en',
  isAuthorizing: false,
  isLoadingCategories: false,
  isLoadingRetailers: false,
  categories: null,
  retailers: [],
  serverType: null,
}

function timeout(ms) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), ms);
  });
}

// Reducer
export default function reducer (state = defaultState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case REQUEST_USER_INFO:
      return {
        ...state,
        isLoadingUserInfo: true,
      }
    case REQUEST_USER_INFO_SUCCESS:
      return {
        ...state,
        lng: 'en',
        isLoadingUserInfo: false,
        serverType: 'production',
        userInfo: payload.userInfo,
      }
    case REQUEST_USER_INFO_FAILED:
      return {
        ...state,
        isLoadingUserInfo: false,
        userInfo: null,
      }
    case REQUEST_ADMIN_LOGIN:
      return {
        ...state,
        isAuthorizing: true
      }
    case REQUEST_ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        authorized: true,
        isAuthorizing: false,
        isLoadingUserInfo: true,
      }
    case REQUEST_ADMIN_LOGIN_FAILED:
      return {
        ...state,
        admin: null,
        authorized: false,
        isAuthorizing: false
      }
    case REQUEST_ADMIN_LOGOUT:
      return {
        ...state,
        admin: null,
        authorized: false,
        isAuthorizing: false,
        isLoadingUserInfo: false,
        userInfo: null,
      }
    case REQUEST_ADMIN_CATEGORIES:
      return {
        ...state,
        isLoadingCategories: true
      }
    case SET_ADMIN_CATEGORIES:
      return {
        ...state,
        isLoadingCategories: false,
        categories: payload.categories
      }
    case REQUEST_RETAILERS_LIST:
      return {
        ...state,
        isLoadingRetailers: true,
      };
    case REQUEST_RETAILERS_LIST_FAILED:
      return {
        ...state,
        isLoadingRetailers: false,
      };
    case REQUEST_RETAILERS_LIST_SUCCESS:
      return {
        ...state,
        isLoadingRetailers: false,
        retailers: payload.retailers,
      };
    case SET_LNG:
      return {
        ...state,
        lng: payload.lng,
      };
    case SET_SERVER_TYPE:
      return {
        ...state,
        serverType: payload.type,
      };
    default: return state
  }
}

// Actions creator
export function requestUserInfo () {
  return { type: REQUEST_USER_INFO }
}

export function requestUserInfoSuccess (userInfo) {
  return { type: REQUEST_USER_INFO_SUCCESS, payload: { userInfo } }
}

export function requestUserInfoFailed () {
  return { type: REQUEST_USER_INFO_FAILED }
}

export function requestAdminLogin () {
  return { type: REQUEST_ADMIN_LOGIN }
}

export function requestAdminLoginFailed () {
  return { type: REQUEST_ADMIN_LOGIN_FAILED }
}

export function setAdminLoginInfo (token) {
  return { type: REQUEST_ADMIN_LOGIN_SUCCESS, payload: { token } }
}

export function adminLogout () {
  return { type: REQUEST_ADMIN_LOGOUT }
}

export function requestAdminCategories () {
  return { type: REQUEST_ADMIN_CATEGORIES }
}

export function setAdminCategories (categories) {
  return { type: SET_ADMIN_CATEGORIES, payload: { categories } }
}

export function setLng(lng) {
  return { type: SET_LNG, payload: { lng } };
}

export function setServerType(type) {
  return { type: SET_SERVER_TYPE, payload: { type } };
}

function requestRetailersList() {
  return { type: REQUEST_RETAILERS_LIST };
}

function requestRetailersListError() {
  return { type: REQUEST_RETAILERS_LIST_FAILED };
}

function requestRetailersListSuccess(retailers) {
  return { type: REQUEST_RETAILERS_LIST_SUCCESS, payload: { retailers } };
}

/**
 * fetch Admin authentication
 * @param {Object} loginInfo
 */
export const validateUserInfo = () => async dispatch => {
  dispatch(requestUserInfo());
  try {
    const { data: userInfo } = await axios({
      method: 'get',
      url: '/user/me',
    });
    if (userInfo.isAdmin) {
      userInfo.retailer = window.localStorage.getItem('lastUsedRetailer') || userInfo.retailer;
      await dispatch(getRetailersList());
    }
    dispatch(requestUserInfoSuccess({
      ...userInfo,
      lngs: [
        { lng: 'en', label: 'Eng' },
        ...((userInfo.retailer === 'handsome' || userInfo.retailer === 'wconcept' || userInfo.retailer === 'wconcept2024') ? [{ lng: 'ko', label: 'Kor' }] : []),
      ]
    }));
  }
  catch(error) {
    console.error(error);
    dispatch(requestUserInfoFailed());
  }
}

export const getRetailersList = () => async (dispatch, getState) => {
  dispatch(requestRetailersList());
  try {
    const { data: retailers } = await axios({
      method: 'get',
      url: '/retailers',
    });
    dispatch(requestRetailersListSuccess(retailers));
  } catch (error) {
    dispatch(requestRetailersListError());
  }
}

export const changeAdminRetailer = (retailerSid) => async (dispatch, getState) => {
  const { userInfo } = getState().AdminAuth;
  window.localStorage.setItem('lastUsedRetailer', retailerSid);
  dispatch(requestUserInfoSuccess({
    ...userInfo,
    retailer: retailerSid,
    lngs: [
      { lng: 'en', label: 'Eng' },
      ...((retailerSid === 'handsome' || retailerSid === 'wconcept' || retailerSid === 'wconcept2024') ? [{ lng: 'ko', label: 'Kor' }] : []),
    ]
  }));
}

export const login = (username, password) => async dispatch => {
  dispatch(requestAdminLogin())
  try {
    const { data: { data } } = await axios.post('/api/graphql', {
      operationName: null,
      query: `
        mutation ($username: String! $password: String!) {
          login: authenticateUserWithPassword(username: $username, password: $password) {
            ...on UserAuthenticationWithPasswordSuccess {
              sessionToken
            }
            ...on UserAuthenticationWithPasswordFailure {
              message
            }
          }
        }
      `,
      variables: {username, password}
    });

    if (data.login.message) {
      throw new Error(data.login.message);
    }

    const accessToken = data.login.sessionToken;
    localStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    dispatch(setAdminLoginInfo(accessToken));

    await timeout(1000);

    dispatch(validateUserInfo());
  } catch (error) {
    dispatch(requestAdminLoginFailed());
  }
}

export const logout = () => dispatch => {
  localStorage.removeItem('accessToken');
  dispatch(adminLogout());
}

export const fetchCategories = () => async (dispatch, getState) => {
  dispatch(requestAdminCategories())
  try {
    const { AdminAuth } = getState();
    const response = await axios.get(`/retailers/${AdminAuth.userInfo.retailer}/configuration`);
    dispatch(setAdminCategories(response.data))
  } catch (e) {
    console.log('Error:', e)
  }
}
