import Handlebars from 'handlebars';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const { document } = window;

const DEFAULT_LAYOUT = `
  {{#each items}}
    <a href="#" data-limit="{{this.value}}">{{this.label}}</a>
  {{/each}}
`;

class PageSize extends Widget {
  defaultParams = {
    containerClassName: '',
    activeClass: 'is-active',
    items: [
      { label: '36 per page', value: 36 },
      { label: '72 per page', value: 72 },
      { label: '108 per page', value: 108 },
    ],
    templates: {
      layout: DEFAULT_LAYOUT,
    },
  };

  constructor(params) {
    super(params);

    this.layoutTemplate = Handlebars.compile(this.params.templates.layout);

    const element = document.createElement('span');
    element.className = this.params.containerClassName;
    this.mainElement = element;
  }

  didMount() {
    const activeElement = this.mainElement.querySelector(
      `a[data-limit="${this.state.filter.limit}"]`
    );
    if (activeElement) {
      activeElement.classList.add(this.params.activeClass);
    }

    this.mainElement.querySelectorAll('a[data-limit]')
      .forEach(element => {
        element.addEventListener('click', this.handleChange);
      });
  }

  handleChange = (e) => {
    e.preventDefault();

    const element = e.target;

    // No need to change Limit when it's already selected
    if (element.classList.contains(this.params.activeClass)) return;

    const activeElement = this.mainElement.querySelector(
      `a.${this.params.activeClass}`
    );
    if (activeElement) {
      activeElement.classList.remove(this.params.activeClass);
    }

    element.classList.add(this.params.activeClass);
    const value = element.getAttribute('data-limit');
    this.setFilter({
      limit: parseInt(value),
    });

    EventEmitter.emit('limitChanged', {
      limit: parseInt(value),
    });
  }

  update() {
    const { limit } = this.state.filter;
    const optionToSelect = this.mainElement.querySelector(`option[value="${limit}"`);
    if (optionToSelect) {
      optionToSelect.selected = true;
    }
  }

  render() {
    this.mainElement.innerHTML = this.layoutTemplate({
      items: this.params.items,
    });
    return this.mainElement;
  }
}

export default (params) => {
  return new PageSize(params);
};
