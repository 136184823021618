// import React from 'react';
// import VisualFilterPage from 'modules/visual-filter-page';

export default function VisualFilter({ match }) {
  // const { shop } = match.params;
  return (
    null
    // <VisualFilterPage
    //   {...shopsConfigurations[shop]}
    //   searchAdditionalParams={{ shop }}
    // />
  );
}
