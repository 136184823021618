import uniqBy from 'lodash/uniqBy'
import {
  CATEGORY_WTOP, CATEGORY_WSHOES, CATEGORY_WPANTS, CATEGORY_MSHIRTS, CATEGORY_MPANTS,
  PRODUCT_COUNT_PER_PAGE, PRD_CATEGORY
} from '@yesplz/core-web/config/constants'
import { LIKE_PRODUCT, UNLIKE_PRODUCT } from '../product'
import {
  SET_PRODUCTS,
  APPEND_PRODUCTS,
  ENABLE_INITIAL_FETCH,
  SET_RECOMMENDED_PRODUCTS,
  APPEND_RECOMMENDED_PRODUCTS,
  SET_ACTIVE_CATEGORY,
  SET_FAVORITE_PRODUCTS,
  SET_PRESETS,
  SET_PRESETS_ALL_CATEGORIES,
  CHANGE_GENDER,
  SET_LEFT_BAR_MENU,
  SET_PRESETS_NEW_ARRIVAL_MOBILE
} from './actions'
import { mapProductFavorites, updateProductFavorite } from '../helpers'
import { CATEGORY_MTSHIRTS, CATEGORY_MKNIT, CATEGORY_MOUTER, CATEGORY_MSHOES, CATEGORY_MWALLET,
  CATEGORY_MBAG, CATEGORY_WSKIRT, CATEGORY_WOUTER, CATEGORY_WDRESS, CATEGORY_WEARRING,
  CATEGORY_WKNIT, CATEGORY_WWALLET, CATEGORY_WBAG
} from '../../../core-web/config/constants';

function categoryDataTemplate() {
  return {
    data: null,
    presets: [],
    nextOffset: 0,
    totalCount: 0,
    fetched: false,
    willBeEmptyList: false
  }
}
const defaultState = {
  [CATEGORY_WTOP]: categoryDataTemplate(),
  [CATEGORY_WSHOES]: categoryDataTemplate(),
  [CATEGORY_WPANTS]: categoryDataTemplate(),
  [CATEGORY_MSHIRTS]: categoryDataTemplate(),
  [CATEGORY_MPANTS]: categoryDataTemplate(),
  [CATEGORY_MTSHIRTS]: categoryDataTemplate(),
  [CATEGORY_MKNIT]: categoryDataTemplate(),
  [CATEGORY_MOUTER]: categoryDataTemplate(),
  [CATEGORY_MSHOES]: categoryDataTemplate(),
  [CATEGORY_MWALLET]: categoryDataTemplate(),
  [CATEGORY_MBAG]: categoryDataTemplate(),
  [CATEGORY_WSKIRT]: categoryDataTemplate(),
  [CATEGORY_WOUTER]: categoryDataTemplate(),
  [CATEGORY_WDRESS]: categoryDataTemplate(),
  [CATEGORY_WEARRING]: categoryDataTemplate(),
  [CATEGORY_WKNIT]: categoryDataTemplate(),
  [CATEGORY_WWALLET]: categoryDataTemplate(),
  [CATEGORY_WBAG]: categoryDataTemplate(),

  presetsCategory: {
    [CATEGORY_WTOP]: [],
    [CATEGORY_WPANTS]: [],
    [CATEGORY_WSHOES]: [],
    [CATEGORY_MSHIRTS]: [],
    [CATEGORY_MPANTS]: [],
    [CATEGORY_MTSHIRTS]: [],
    [CATEGORY_MKNIT]: [],
    [CATEGORY_MOUTER]: [],
    [CATEGORY_MSHOES]: [],
    [CATEGORY_MWALLET]: [],
    [CATEGORY_MBAG]: [],
    [CATEGORY_WSKIRT]: [],
    [CATEGORY_WOUTER]: [],
    [CATEGORY_WDRESS]: [],
    [CATEGORY_WEARRING]: [],
    [CATEGORY_WKNIT]: [],
    [CATEGORY_WWALLET]: [],
    [CATEGORY_WBAG]: []
  },
  presetNewArrivalMobile: null,
  favoriteList: [],
  recommended: {
    data: [],
    nextOffset: 0,
    totalCount: 0
  },
  activeCategory: PRD_CATEGORY,
  gender: '',
  leftBarMenu: {}
}

// Reducer
export default function reducer (state = defaultState, action = {}) {
  const { type, payload = {} } = action
  const category = payload.category || state.activeCategory
  switch (type) {
    case SET_PRODUCTS:
      let updatedState = {}
      const list = mapProductFavorites(payload.favoriteProductIds, payload.products)

      // if list is updated with new set of data (filters change) and the date is empty,
      // don't update it directly, but instead, set `willBeEmptyList` flag to true.
      // we need this to warn user about empty result from current filters,
      // but still keeping the previous data set in the background.
      if (state.fetched && list.length === 0) {
        updatedState = {
          willBeEmptyList: true
        }
      } else {
        updatedState = {
          [category]: {
            data: list,
            presets: state[category].presets,
            willBeEmptyList: list.length === 0,
            fetched: true,
            totalCount: payload.totalCount,
            nextOffset: payload.countPerPage || PRODUCT_COUNT_PER_PAGE
          }
        }
      }

      return {
        ...state,
        ...updatedState
      }
    case APPEND_PRODUCTS:
      let newProductList = mapProductFavorites(payload.favoriteProductIds, payload.products)
      return {
        ...state,
        [category]: {
          ...state[category],
          data: uniqBy([...state[category].data, ...newProductList], 'product_id'),
          nextOffset: state[category].nextOffset + (payload.countPerPage || PRODUCT_COUNT_PER_PAGE)
        }
      }

    case SET_PRESETS:
      return {
        ...state,
        [category]: {
          ...state[category],
          presets: payload.presets
        }
      }

    case SET_PRESETS_ALL_CATEGORIES:
      return {
        ...state,
        presetsCategory: {
          ...state.presetsCategory,
          ...payload
        }
      }

    case SET_PRESETS_NEW_ARRIVAL_MOBILE:
      return {
        ...state,
        presetNewArrivalMobile: payload
      }

    case LIKE_PRODUCT:
      return {
        ...state,
        [category]: {
          ...state[category],
          data: updateProductFavorite(payload.productId, true, state[category].data)
        },
        recommended: {
          ...state.recommended,
          data: updateProductFavorite(payload.productId, true, state.recommended.data)
        }
      }

    case UNLIKE_PRODUCT:
      return {
        ...state,
        [category]: {
          ...state[category],
          data: updateProductFavorite(payload.productId, false, state[category].data)
        },
        recommended: {
          ...state.recommended,
          data: updateProductFavorite(payload.productId, false, state.recommended.data)
        }
      }

    case ENABLE_INITIAL_FETCH:
      return {
        ...state,
        [category]: {
          ...state[category],
          fetched: false,
          nextOffset: 0
        }
      }

    case SET_FAVORITE_PRODUCTS:
      return { ...state, favoriteList: payload.favoriteProducts }

    case SET_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        recommended: {
          ...state.recommended,
          data: mapProductFavorites(payload.favoriteProductIds, payload.products),
          nextOffset: (payload.countPerPage || PRODUCT_COUNT_PER_PAGE)
        }
      }

    case APPEND_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        recommended: {
          ...state.recommended,
          data: [
            ...state.recommended.data,
            ...mapProductFavorites(payload.favoriteProductIds, payload.products)
          ],
          nextOffset: state.recommended.nextOffset + (payload.countPerPage || PRODUCT_COUNT_PER_PAGE)
        }
      }

    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: payload.activeCategory
      }

    case CHANGE_GENDER:
      return {
        ...state,
        gender: payload.gender
      }
    case SET_LEFT_BAR_MENU:
      return  {
        ...state,
        leftBarMenu: payload.menu
      }
    default: return state
  }
}
