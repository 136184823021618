import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import { findLabelByLng } from '@yesplz/core';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

import designSolidSvg from '@yesplz/core-models/assets/svg/design-solid.svg';
import designPatternSvg from '@yesplz/core-models/assets/svg/design-pattern.svg';
import designDetailSvg from '@yesplz/core-models/assets/svg/patterns-detail.svg';
import designDestroyedSvg from '@yesplz/core-models/assets/svg/design-destroyed.svg';
import designAnimalSvg from '@yesplz/core-models/assets/svg/pattern-animal.svg';
import patternFloralSvg from '@yesplz/core-models/assets/svg/pattern-floral.svg';
import patternDot from '@yesplz/core-models/assets/svg/patterns-dot.svg';
import patternFruit from '@yesplz/core-models/assets/svg/patterns-fruit.svg';
import patternSparkle from '@yesplz/core-models/assets/svg/patterns-sparkle.svg';
import patternAbstract from '@yesplz/core-models/assets/svg/patterns-abstract.svg';
import patternStripeSvg from '@yesplz/core-models/assets/svg/pattern-stripe.svg';
import patternCheckerSvg from '@yesplz/core-models/assets/svg/pattern-checker.svg';
import patternGraphicSvg from '@yesplz/core-models/assets/svg/pattern-graphic.svg';
import patternColorBlockSvg from '@yesplz/core-models/assets/svg/pattern-color_block.svg';

import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';

const patternTypes = [
  { type: 'solid', label: [{ "lng": "en", "label": "Solid" }, { "lng": "ko", "label": "민무늬" }], image: designSolidSvg, remove: 'pattern' },
  { type: 'pattern', label: [{ "lng": "en", "label": "Pattern" }, { "lng": "ko", "label": "패턴" }], image: designPatternSvg, remove: 'solid', className: 'patterns-pattern' },
  { type: 'detail', label: [{ "lng": "en", "label": "Details" }, { "lng": "ko", "label": "디테일" }], image: designDetailSvg, className: 'patterns-detail' },
  // TODO: Change it to 'distressed'
  { type: 'ripped-off', label: [{ "lng": "en", "label": "Distressed" }, { "lng": "ko", "label": "디스트레스드" }], image: designDestroyedSvg },
  { type: 'animal', label: [{ "lng": "en", "label": "Animal" }, { "lng": "ko", "label": "애니멀" }], image: designAnimalSvg },
  { type: 'floral', label: [{ "lng": "en", "label": "Floral" }, { "lng": "ko", "label": "플로럴" }], image: patternFloralSvg, className: 'patterns-floral' },
  { type: 'dot', label: [{ "lng": "en", "label": "Dots" }, { "lng": "ko", "label": "도트" }], image: patternDot, className: 'wide-image' },
  { type: 'fruit', label: [{ "lng": "en", "label": "Fruit" }, { "lng": "ko", "label": "Fruit" }], image: patternFruit, className: 'wide-image' },
  { type: 'repeat', label: [{ "lng": "en", "label": "Abstract" }, { "lng": "ko", "label": "추상적" }], image: patternAbstract, className: 'wide-image' },
  { type: 'sparkle', label: [{ "lng": "en", "label": "Sparkle" }, { "lng": "ko", "label": "시퀸" }], image: patternSparkle },
  { type: 'stripe', label: [{ "lng": "en", "label": "Stripe" }, { "lng": "ko", "label": "스트라이프" }], image: patternStripeSvg },
  { type: 'checker', label: [{ "lng": "en", "label": "Checker" }, { "lng": "ko", "label": "체크" }], image: patternCheckerSvg },
  { type: 'graphic', label: [{ "lng": "en", "label": "Graphic" }, { "lng": "ko", "label": "그래픽" }], image: patternGraphicSvg },
  { type: 'colorblock', label: [{ "lng": "en", "label": "Color Block" }, { "lng": "ko", "label": "컬러블락" }], image: patternColorBlockSvg },
  { type: 'reset', label: [{ "lng": "en", "label": "Reset" }, { "lng": "ko", "label": "Reset" }], image: resetIconSvg },
].map(pattern => ({
  value: pattern.type,
  ...pattern,
}));

function convertList(list = {}) {
  return Object.values(list)
    .map(({ value, isActive }) => ({ label: capitalize(value), value, isActive }))
    .filter(({ value }) => value);
}

class PatternsFilter extends Widget {
  defaultParams = {
    title: 'Patterns',
    filterParamName: 'patterns',
    listParamName: 'patterns',
    listMiddleware: null,
    multiple: true,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'ThumbnailPicker';
    this.mainElement = element;

    if (!this.params.patternsTypes) {
      this.params.patternsTypes = patternTypes;
    }

    this.patternsTypes = this.params.patternsTypes.reduce((patterns, pattern) => {
      patterns[pattern.type] = pattern;
      return patterns;
    }, {});

    this.listRendered = false;
  }

  didUpdate(prevState) {
    const { listParamName, filterParamName } = this.params;
    
    if (!this.listRendered && !isEmpty(this.state.search?.filter?.[listParamName])) {
      this.renderItems();
      this.listRendered = true;
    }

    if (
      !this.params.multiple &&
      prevState.filter[filterParamName] !== this.state.filter[filterParamName]
    ) {
      const prevValue = prevState.filter[filterParamName];
      const nextValue = this.state.filter[filterParamName];

      const nextButton = this.mainElement.querySelector(`[data-item-value="${nextValue}"]`);
      if (nextButton) nextButton.classList.add('is-selected');

      const prevButton = this.mainElement.querySelector(`[data-item-value="${prevValue}"]`)
      if (prevButton) prevButton.classList.remove('is-selected');
    }
    else if (
      this.params.multiple &&
      !isEqual(prevState.filter[filterParamName], this.state.filter[filterParamName])
    ) {
      const selectedItems = this.state.filter[filterParamName];
      const prevSelectedItems = prevState.filter[filterParamName];

      const selected = difference(selectedItems, prevSelectedItems);
      const deselected = difference(prevSelectedItems, selectedItems);

      selected.forEach(value => {
        const button = this.mainElement.querySelector(`[data-item-value="${value}"]`);
        if (button) button.classList.add('is-active');
      });
      deselected.forEach(value => {
        const button = this.mainElement.querySelector(`[data-item-value="${value}"]`)
        if (button) button.classList.remove('is-active');
      });
    }

    if (!isEqual(
      prevState.search?.filter?.[listParamName],
      this.state.search?.filter?.[listParamName]
    )) {
      this.evaluateActiveItems();
    }
  }

  handleItemClick = (newValue) => {
    const item = find(this.items, { value: newValue });
    if ((!item || !item.isActive) && newValue !== 'reset') return;

    if (this.params.multiple) {
      if (newValue === 'reset') {
        this.main.resetList(this.params.filterParamName);
      }
      else {
        this.main.toggleList(this.params.filterParamName, newValue);
      }
    }
    else {
      this.main.toggleValue(this.params.filterParamName, newValue);
    }

    EventEmitter.emit(
      `txtSidebar${
        capitalize(this.params.filterParamName)
      }${(
        (this.state.filter[this.params.filterParamName] || []).includes(newValue)
          ? 'Applied'
          : 'Removed'
      )}`,
      newValue
    );
  }

  get items() {
    const { listParamName } = this.params;
    return (
      this.params.items
        ? this.params.items
        : typeof this.params.listMiddleware === 'function'
          ? this.params.listMiddleware(this.state.search?.filter?.[listParamName])
          : convertList(this.state.search?.filter?.[listParamName])
    );
  }

  renderItems() {
    const { filterParamName  } = this.params;
    const currentValues = this.state.filter[filterParamName] || [];
    const items = this.items;
    this.mainElement.innerHTML = '';
    [
      ...items,
      { value: 'reset', isActive: true, className: 'reset' },
    ].forEach((item) => {
      const labelElement = document.createElement('div');
      const pattern = this.patternsTypes[item.value];

      if (!pattern) return;

      labelElement.innerHTML = `
        <div class="ThumbnailPickerOption-thumbnail">
          <div class="ThumbnailPickerOption-imageWrapper">
            <img src="${pattern.image}" alt="" />
          </div>
        </div>
        <h5><span>${findLabelByLng(pattern.label)}</span></h5>
      `;
      labelElement.className = 'ThumbnailPickerOption' + (
        currentValues.includes(item.value) ? ' is-active' : ''
      ) + (
        !item.isActive ? ' is-disabled' : ''
      ) + (pattern.className ? ` ${pattern.className}` : ` pattern-${pattern.type}`);
      labelElement.setAttribute('data-item-value', item.value);
      this.mainElement.appendChild(labelElement);

      labelElement.addEventListener('click', () => {
        this.handleItemClick(item.value);
      });
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(items);
    }
  }

  evaluateActiveItems() {
    const { filterParamName } = this.params;
    const currentValues = this.state.filter[filterParamName] || [];
    const items = this.items;

    items.forEach(item => {
      const element = this.mainElement.querySelector(`[data-item-value="${item.value}"]`);

      if (!element) return;

      if (currentValues.includes(item.value)) {
        element.classList.add('is-active');
      }
      else {
        element.classList.remove('is-active');

        if (item.isActive) {
          element.classList.remove('is-disabled');
        }
        else {
          element.classList.add('is-disabled');
        }
      }
    });
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    this.renderItems();
    return this.mainElement;
  }
}

export default (params) => {
  return new PatternsFilter(params);
};
