const isDev = process.env.NODE_ENV === 'development'

// Actions
const SET_SIZES_POPUP_STATUS = 'popups/SET_SIZES_POPUP_STATUS'
const SET_FAQ_POPUP_STATUS = 'popups/SET_FAQ_POPUP_STATUS'

const defaultState = {
  openedSize: false,
  openedFAQ: false
}

// Reducer
export default function reducer (state = defaultState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SET_SIZES_POPUP_STATUS:
      return {
        ...state,
        openedSize: payload.status
      }
    case SET_FAQ_POPUP_STATUS:
      return {
        ...state,
        openedFAQ: payload.status
      }
    default: return state
  }
}

// Actions creator
export function setSizePopupStatus (status) {
  return { type: SET_SIZES_POPUP_STATUS, payload: { status } }
}

export function setFAQPopupStatus (status) {
  return { type: SET_FAQ_POPUP_STATUS, payload: { status } }
}
