
const SET_LATEST_CATEGORY_ID = 'latestCategoryId/SET_LATEST_CATEGORY_ID';

const initialState = {
  women: 'wtop',
  men: 'mtshirts',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LATEST_CATEGORY_ID:
      const gender = action.categoryId[0] === 'w' ? 'women' : 'men';
      return {
        ...state,
        [gender]: action.categoryId,
      };
    default:
      return state;
  }
}

export function setLatestCategoryId(categoryId) {
  return { type: SET_LATEST_CATEGORY_ID, categoryId };
}
