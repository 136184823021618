import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const pricesList = [
  { label: '$0 - $50', value: '0-50', from: 0, to: 50 },
  { label: '$50 - $150', value: '50-150', from: 50, to: 150 },
  { label: '$150 - $400', value: '150-400', from: 150, to: 400 },
  { label: '$400 - over $800', value: '400-', from: 400, to: null },
];

class SimplePricesList extends Widget {
  defaultParams = {
    title: 'Price',
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.ul = document.createElement('ul');

    this.mainElement.appendChild(this.ul);

    this.selected = [];
  }

  didMount() {
    this.renderPrices();
  }

  didUpdate(prevState) {
    if (
      !isEqual(
        prevState.search?.filters?.salePrice,
        this.state.search?.filters?.salePrice
      )
    ) {
      this.renderPrices();
    }
    else if (prevState.filter.params.price !== this.state.filter.params.price) {
      // const prevPrice = prevState.filter.params.price;
      const price = this.state.filter.params.price;

      const prevPriceElement = this.mainElement.querySelector(`.active`);
      if (prevPriceElement) prevPriceElement.classList.remove('active');

      const priceElement = this.mainElement.querySelector(`[data-pricing-option-id="${price}"]`);
      if (priceElement) priceElement.classList.add('active');
    }
  }

  handleItemClick = (e, value) => {
    e.stopPropagation();
    const { categoryId, params } = this.state.filter;
    const newParams = { ...params };

    EventEmitter.emit('priceOptionClick', {
      categoryId,
      price: value,
    });

    if (newParams.price === value) {
      delete newParams.price;

      EventEmitter.emit('priceOptionRemoved', {
        categoryId,
        price: value,
      });
    }
    else {
      newParams.price = value;

      EventEmitter.emit('priceOptionApplied', {
        categoryId,
        price: value,
      });
    }

    this.setFilter({
      params: newParams,
    });
  }

  roundMinPrice = (minPrice, step) => {
    if (minPrice && step) {
      return Math.floor(minPrice / step) * step;
    }
    return 0;
  }

  roundMaxPrice = (maxPrice, step) => {
    if (maxPrice && step) {
      return Math.floor(maxPrice / step) * step;
    }
    return 800;
  }

  renderPrices() {
    const { params } = this.state.filter;
    this.ul.innerHTML = '';

    const minPrice = this.roundMinPrice(
      this.state.search?.filters?.salePrice?.minPrice,
      this.state.search?.filters?.salePrice?.step
    );
    const maxPrice = this.roundMaxPrice(
      this.state.search?.filters?.salePrice?.maxPrice,
      this.state.search?.filters?.salePrice?.step
    );

    pricesList.forEach((price, refIndex) => {
      if ((price.to && price.to < minPrice) || (price.from > maxPrice)) return;

      const value = `${price.from < minPrice ? minPrice : price.from}-${!price.to ? '' : price.to > maxPrice ? maxPrice : price.to}`;
      const li = document.createElement('li');
      li.innerHTML = `${price.label}`;
      li.setAttribute('data-pricing-option-id', value);
      li.setAttribute('data-pricing-option-ref-index', refIndex);
      this.ul.appendChild(li);

      if (params.price === value) {
        li.classList.add('active');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, value));
    });
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new SimplePricesList(params);
};
