import Widget from '../modules/Widget';
import { findLabelByLng } from '@yesplz/core';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import capitalize from 'lodash/capitalize';
import EventEmitter from "../modules/EventEmitter";

class BodyPartsList extends Widget {
  defaultParams = {
    title: 'Design',
    items: [],
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.filter = {};
  }

  didMount() {
    this.main.$hooks.register('updateSearchQuery', this.handleUpdateSearchQuery)

    if (this.state.search?.filter?.predictions) {
      this.updatePredictions();
      return;
    }
    this.renderOptions();
  }

  willUnmount() {
    this.main.$hooks.unRegister('updateSearchQuery', this.handleUpdateSearchQuery)
  }

  handleUpdateSearchQuery = (filter) => {
    let { category: prevCategory } = this.state.filter;
    if (Array.isArray(prevCategory)) prevCategory = prevCategory[0];

    let { category: newCategory } = filter;
    if (Array.isArray(newCategory)) newCategory = newCategory[0];

    if (prevCategory && prevCategory !== newCategory) {
      Object.keys(filter).forEach(key => {
        if (key.includes(prevCategory + '.')) {
          delete filter[key];
        }
      });
    }

    return filter;
  }

  didUpdate(prevState) {
    const { parts } = this.params;
    let { category } = this.state.filter;
    if (Array.isArray(category)) category = category[0];
    if (!parts) return;
    parts.forEach(({ name }) => {
      const paramName = `${category}.${name}`;
      if (prevState.filter[paramName] !== this.state.filter[paramName]) {
        const deselected = difference(prevState.filter[paramName], this.state.filter[paramName]);
        const selected = difference(this.state.filter[paramName], prevState.filter[paramName]);
        if (deselected.length) {
          deselected.forEach(value => {
            const selector = `[${this.paramNameToSelector(paramName)}="${value}"]`;
            const el = this.mainElement.querySelector(selector);
            if (el) {
              el.classList.remove('active');
            }
          });
        }
        if (selected.length) {
          selected.forEach(value => {
            const selector = `[${this.paramNameToSelector(paramName)}="${value}"]`;
            const el = this.mainElement.querySelector(selector);
            if (el) {
              el.classList.add('active');
            }
          });
        }
      }
    });

    if (
      !isEqual(category, prevState.filter.category)
      ||
      !isEqual(prevState.search?.filter?.predictions, this.state.search?.filter?.predictions)
    ) {
      this.updatePredictions();
    }
  }

  paramNameToSelector(paramName) {
    return `data-body-part-${paramName.replace('.', '-')}-id`;
  }

  updatePredictions() {
    const { parts } = this.params;
    let { category } = this.state.filter;
    if (Array.isArray(category)) category = category[0];
    const { predictions } = this.state.search.filter;

    this.filter = {};
    if (!parts) return;
    parts.forEach(({ name }) => {
      const keyPrefix = category + capitalize(name);
      const pickedKeys = Object.keys(predictions).filter(key => (
        key.includes(keyPrefix)
        && !key.includes(',')
      ));
      const filterMap = {};
      pickedKeys.forEach(key => {
        const value = predictions[key];
        const bodyPart = value.value.replace(`${category}.${name}.`, '').toLowerCase();
        if (bodyPart.includes('.')) return;
        value.value = bodyPart;
        filterMap[bodyPart] = value;
      });
      this.filter[name] = filterMap;
    });

    this.renderOptions();
  }

  handleItemClick = (e, paramName, value) => {
    e.stopPropagation();
    const newValue = value !== 'all' ? value : null;

    /**
     * @type {boolean}
     */
    const resultToggleStatus = this.main.toggleList(paramName, newValue);

    EventEmitter.emit(`txtSidebarBodyPart${resultToggleStatus ? 'Applied' : 'Removed'}`, {
      bodyPart: paramName,
      style: value,
    });
  }

  renderOptions() {
    const filter = this.state.filter;
    let { category } = this.state.filter;
    if (Array.isArray(category)) category = category[0];
    const { parts } = this.params;
    this.mainElement.innerHTML = '';

    let isEmpty = true;
 
    if (category && !['all', 'women', 'men'].includes(category)) {
      parts.forEach(({ name, label }) => {
        const title = document.createElement('h4');
        title.innerText = findLabelByLng(label);
        const ul = document.createElement('ul');
        const items = Object.values(this.filter[name] || {})
          .filter((i) => i.value && i.value !== 'default');
        items.forEach((option) => {
          const labelBase = option.value.replace(`${category}.${name}.`, '');
          const label = labelBase.split('_').map(p => capitalize(p)).join(' ');
          const paramName = `${category}.${name}`;
          const value = labelBase;

          const li = document.createElement('li');
          li.innerHTML = label;
          li.setAttribute(this.paramNameToSelector(paramName), value);
          ul.appendChild(li);

          if (!option.isActive) {
            li.classList.add('is-disabled');
          }
          else if (filter[paramName] && (filter[paramName] === value || filter[paramName].includes(value))) {
            li.classList.add('active');
          }

          if (isEmpty) isEmpty = false;

          li.addEventListener('click', (e) => this.handleItemClick(e, paramName, value));
        });
        if (items.length > 0) {
          this.mainElement.appendChild(title);
          this.mainElement.appendChild(ul);
        }
      });
    }

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(isEmpty);
    }
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new BodyPartsList(params);
};
