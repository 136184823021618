import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import green from '@material-ui/core/colors/green';

// core-web
import { RETAILER_MENU_ITEMS, ADMIN_MENU_ITEMS } from '@yesplz/core-web/config/constants';

// desktop
import LeftBG from 'assets/images/home-bg1.png';
import RightBG1 from 'assets/images/home-bg2-1.png';

import './Home.scss';
import { useUser } from 'components/UserProvider';

export default function Home() {
  const classes = useStyles();
  const history = useHistory();
  const { userInfo: { isAdmin } } = useUser();

  const handleChange = event => {
    const path = event.target.value;
    history.push(`/${path}`);
  }

  return (
    <div id='Admin-Home' className='Admin-Home'>
      <div className='left-bg'>
        <img src={LeftBG} alt='' />
      </div>
      <div className='right-bg'>
        <img src={RightBG1} alt='' />
      </div>
      <div className='main-container'>
        <div className='menu-title'>Welcome to the Admin Page</div>
        <div className='menu-group'>
          <FormControl component='fieldset' className={classes.formControl}>
            <RadioGroup
              aria-label='menu'
              name='menu'
              className={classes.group}
              onChange={handleChange}
            >
              {
                (isAdmin ? ADMIN_MENU_ITEMS : RETAILER_MENU_ITEMS).map(item => (
                  <FormControlLabel
                    key={`menu-${item.value}`}
                    value={item.value}
                    control={
                      <Radio
                        classes={{
                          root: classes.radioRoot,
                          checked: classes.checked
                        }}
                      />
                    }
                    label={item.label} />
                ))
              }
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: `${theme.spacing(1)}px 0`
  },
  radioRoot: {
    '&$checked': {
      color: green[500]
    },
    '&:hover': {
      backgroundColor: 'rgba(76, 175, 80, 0.2)'
    }
  },
  checked: {}
}));
