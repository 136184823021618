import axios from 'axios';
import {
  ADMIN_VF_PRODUCT_COUNT_PER_PAGE,
  ADMIN_VISUAL_FILTER_REQUEST_TIMEOUT_MS,
} from '@yesplz/core-web/config/constants';

const prefix = 'visual-filter';

const SET_CATEGORIES = `${prefix}/SET_CATEGORIES`;
const FETCH_PRODUCTS = `${prefix}/FETCH_PRODUCTS`;
const SET_PRODUCTS = `${prefix}/SET_PRODUCTS`;

const initialState = {
  isCategoriesLoading: true,
  isProductsLoading: true,
  categories: [],
  productsCount: null,
  products: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        isProductsLoading: true,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        isProductsLoading: false,
        productsCount: action.count,
        products: [ ...action.products ],
      };
    case SET_CATEGORIES:
      return {
        ...state,
        isCategoriesLoading: false,
        categories: [ ...action.categories ],
      };
    default:
      return state;
  }
};

export function setCategories(categories) {
  return { type: SET_CATEGORIES, categories };
}

export function fetchProductsStart() {
  return { type: FETCH_PRODUCTS };
}

export function setProducts(count, products) {
  return { type: SET_PRODUCTS, count, products };
}

export function fetchCategories() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/vf-categories');
      return dispatch(setCategories(response.data));
    } catch (e) {
      console.log('Error:', e);
    }
  }
}

let fetchProductsTimeout = null;

export function fetchProducts({ page = 1, ...params }) {
  return async (dispatch) => {
    try {
      if (fetchProductsTimeout) clearTimeout(fetchProductsTimeout);

      dispatch(fetchProductsStart());

      fetchProductsTimeout = setTimeout(async () => {
        const offset = (page - 1) * ADMIN_VF_PRODUCT_COUNT_PER_PAGE;
        const response = await axios.get('/vf/', {
          params: {
            limit: ADMIN_VF_PRODUCT_COUNT_PER_PAGE,
            admin: 1, offset, ...params,
          },
        });
        dispatch(setProducts(response.data.count, response.data.results));
      }, ADMIN_VISUAL_FILTER_REQUEST_TIMEOUT_MS);
    } catch (e) {
      console.log('Error:', e);
    }
  }
}
