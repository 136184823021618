import axios from 'axios'

import { CATEGORY_WTOP, CATEGORY_WPANTS, CATEGORY_WSHOES, CATEGORY_MSHIRTS, CATEGORY_MPANTS } from '@yesplz/core-web/config/constants'

// Actions
const SEARCH_ADMIN_PRODUCT_BY_ID = 'admin/SEARCH_ADMIN_PRODUCT_BY_ID'
const GET_ADMIN_RANDOM_PRODUCT = 'admin/GET_ADMIN_RANDOM_PRODUCT'
const GET_ADMIN_PRODUCT_INFO = 'admin/GET_ADMIN_PRODUCT_INFO'
const SET_ADMIN_PRODUCT_INFO = 'admin/SET_ADMIN_PRODUCT_INFO'
const GET_ADMIN_WEIGHTS = 'admin/GET_ADMIN_WEIGHTS'
const UPDATE_ADMIN_WEIGHTS = 'admin/UPDATE_ADMIN_WEIGHTS'
const GET_ADMIN_RECOMMENDED_PRODUCTS = 'admin/GET_ADMIN_RECOMMENDED_PRODUCTS'

const defaultState = {
  activeProduct: {
    [CATEGORY_WTOP]: null,
    [CATEGORY_WPANTS]: null,
    [CATEGORY_WSHOES]: null
  },
  recommendedProducts: [],
  activeProductInfo: {
    [CATEGORY_WTOP]: null,
    [CATEGORY_WPANTS]: null,
    [CATEGORY_WSHOES]: null
  },
  currentProductInfo: null,
  currentWeights: null,
  weights: {
    [CATEGORY_WTOP]: null,
    [CATEGORY_WPANTS]: null,
    [CATEGORY_WSHOES]: null,
    [CATEGORY_MSHIRTS]: null,
    [CATEGORY_MPANTS]: null
  }
}

// Reducer
export default function reducer (state = defaultState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SEARCH_ADMIN_PRODUCT_BY_ID:
      return {
        ...state,
        activeProduct: { ...state.activeProduct, [payload.category]: payload.product }
      }
    case GET_ADMIN_RANDOM_PRODUCT:
      return {
        ...state,
        activeProduct: { ...state.activeProduct, [payload.category]: payload.product }
      }
    case GET_ADMIN_PRODUCT_INFO:
      return {
        ...state,
        currentProductInfo: payload.productInfo,
        activeProductInfo: { ...state.activeProductInfo, [payload.category]: payload.productInfo }
      }
    case SET_ADMIN_PRODUCT_INFO:
      return {
        ...state,
        currentProductInfo: payload.productInfo
      }
    case GET_ADMIN_WEIGHTS:
      return {
        ...state,
        currentWeights: payload.weights,
        weights: { ...state.weights, [payload.category]: payload.weights }
      }
    case UPDATE_ADMIN_WEIGHTS:
      return {
        ...state,
        currentWeights: payload.weights,
        weights: { ...state.weights, [payload.category]: payload.weights }
      }
    case GET_ADMIN_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        recommendedProducts: payload.products || []
      }
    default: return state
  }
}

export const searchProductById = (category, product) => ({
  type: SEARCH_ADMIN_PRODUCT_BY_ID,
  payload: { category, product }
})

export const getProductInfo = (category, productInfo) => ({
  type: GET_ADMIN_PRODUCT_INFO,
  payload: { category, productInfo }
})

export const getRandomProduct = (category, product) => ({
  type: GET_ADMIN_RANDOM_PRODUCT,
  payload: { category, product }
})

export const setCurrentProductInfo = (productInfo) => ({
  type: SET_ADMIN_PRODUCT_INFO,
  payload: { productInfo }
})

export const getCurrentWeights = (category, weights) => ({
  type: GET_ADMIN_WEIGHTS,
  payload: { category, weights }
})

export const updateCurrentWeights = (category, weights) => ({
  type: UPDATE_ADMIN_WEIGHTS,
  payload: { category, weights }
})

export const getRecommendedProducts = (products) => ({
  type: GET_ADMIN_RECOMMENDED_PRODUCTS,
  payload: { products }
})

// Fetch Products data
export const fetchProductById = (category, productId) => {
  return async (dispatch, getState) => {
    try {
      const recommends = await axios.get('/recommends/yml', {
        params: {
          productId: productId,
          admin: 1, // Get extra product information
          offset: 0,
          limit: 50
        }
      })
      dispatch(getProductInfo(recommends.data['category'], recommends.data['product']))
      dispatch(getRecommendedProducts(recommends.data['recommends']))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}

export const fetchRandomProduct = (category, random = 1) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`/products/random?category=${category}`)
      dispatch(getRandomProduct(category, response.data))
      const recommends = await axios.get('/recommends/yml', {
        params: {
          productId: response.data['productId'],
          admin: 1, // Get extra product information
          offset: 0,
          limit: 50
        }
      })
      dispatch(getProductInfo(category, recommends.data['product']))
      dispatch(getRecommendedProducts(recommends.data['recommends']))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}

export const fetchCurrentWeights = (category) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/admin/weights/${category}`)
      dispatch(getCurrentWeights(category, response.data))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}

export const fetchUpdateWeights = (category, options) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/admin/weights/${category}`, options)
      dispatch(updateCurrentWeights(category, response.data))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}

export const fetchAdminRecommendedProducts = (productId, filters, offset = 0, limit = 20) => {
  return async (dispatch) => {
    try {
      const response = await axios.get('/admin/recommends', {
        params: {
          pid: productId,
          offset,
          limit,
          ...filters
        }
      })
      dispatch(getRecommendedProducts(response.data.products))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}
