import { createAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { runSearch } from './searchActions';
import { saveCategoryFilter } from './savedFiltersActions';
import { toggleTooltips } from './configActions';
import { parseUrlState, pushFilterToHistory, removeEmptyValuesFromObject } from '../../modules/history';
import Hooks from '../../modules/Hooks';

const prefix = '@filter/';
const paramsToCompare = ['categoryId', 'category', 'subcategory', 'categorySlice', 'params', 'sort', 'offset', 'limit', 'presetIndex', 'occasion', 'mood', 'discount', 'newArrivals', 'sale'];

const THROTTLING_TIME = 300;

// Send first request immediatly.
// After set it to throttling.
// Once request was done turn off throttling.
let useThrottling = true;

let throttlingTimeout = null;

export const setFilter = createAction(`${prefix}SET_FILTER`);

export const setFilterAndSearch = (filter, categories, forceSearch = false, shouldPushToHistory = true) => (dispatch, getState) => {
  const { filter: filterBefore, config: { useUrlHistory, isTooltipsVisible } } = getState();
  dispatch(setFilter(Hooks.call('filter.set', filter, filterBefore)));
  const { filter: filterAfter } = getState();

  const dispatchSearhing = () => {
    dispatch(saveCategoryFilter(filterAfter));
    dispatch(runSearch(categories));
  }

  if (isTooltipsVisible) {
    dispatch(toggleTooltips());
  }

  if (
    !isEqual(
      pick(filterBefore, paramsToCompare),
      pick(filterAfter, paramsToCompare)
    )
    ||
    forceSearch
  ) {
    if (useThrottling) {
      if (throttlingTimeout) clearTimeout(throttlingTimeout);
      throttlingTimeout = setTimeout(() => {
        dispatchSearhing();
        useThrottling = false;
      }, THROTTLING_TIME);
    }
    else {
      dispatchSearhing();
      useThrottling = true;
      // Turn off throttling if filter was not changed
      // during throttling time.
      throttlingTimeout = setTimeout(() => {
        useThrottling = false;
      }, THROTTLING_TIME);
    }
  }

  if (useUrlHistory && shouldPushToHistory) {
    pushFilterToHistory(filterAfter, (categories[filterAfter.categoryId] || {}).partList);
  }
}

export const hydrateFromUrl = (shouldSearch = true, categories) => (dispatch, getState) => {
  const { filter: currentFilter } = getState();
  const cleanCurrentFilter = removeEmptyValuesFromObject(currentFilter);
  let parsedFilter = removeEmptyValuesFromObject(parseUrlState(categories));

  if (!isEqual(parsedFilter, cleanCurrentFilter)) {
    if (shouldSearch) {
      // console.log('hydrateFromUrl setFilterAndSearch', JSON.stringify(parsedFilter, null, 2));
      dispatch(setFilterAndSearch(parsedFilter, categories, false, true));
    }
    else {
      // console.log('hydrateFromUrl setState', JSON.stringify(parsedFilter, null, 2));
      dispatch(setFilter(parsedFilter));
    }
  }
}
