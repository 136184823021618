import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE } from '@yesplz/core-web/config/constants';
import qs from 'qs';
import Sticky from 'react-sticky-el';
import TooltipSwitch from 'components/TooltipSwitch';
import Loading from 'components/Loading';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import SearchBar from 'modules/review/SearchBar';

import ProductRow from './ProductRow';

export default ({ history, match, location }) => {
  const classes = useStyles();
  const rootContainer = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isTooltipActive, setIsTooltipActive] = useState(true);
  const [products, setProducts] = useState({
    results: [],
  });

  const { shop } = match.params;

  let searchParams = location.search ? qs.parse(location.search.slice(1)) : {};
  const page = searchParams.page ? parseInt(searchParams.page, 10) : 1;
  const { search = null, sort = null } = searchParams;

  async function fetchProducts(offset) {
    const { data } = await axios.get('/products.json', {
      params: {
        shop,
        sort,
        search,
        offset,
        limit: ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE,
        admin: 1,
      },
    });
    setProducts(data);
    setIsLoading(false);
    if (rootContainer) rootContainer.current.scrollTo(0, 0);
  }

  function setSearchParams(searchParams) {
    const searchQuery = qs.stringify(
      searchParams,
      { skipNulls: true }
    );
    history.push(`/${shop}/review?${searchQuery}`);
  }

  useEffect(() => {
    fetchProducts(ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE * (page - 1));
  }, [page, search, sort]);

  return (
    <div id="MainScroll" className={classes.root} ref={rootContainer}>
      {isLoading && <Loading />}
      <Sticky
        scrollElement="#MainScroll"
        stickyClassName={classes.sticky}
      >
        <div className={classes.header}>
          <div className={classes.searchRoot}>
            <SearchBar
              search={search}
              onSearch={search => setSearchParams({ search })}
              onRandomClick={() => {
                if (sort === 'random' && page === 1) {
                  fetchProducts(0);
                }
                else {
                  setSearchParams({ sort: 'random' });
                }
              }}
            />
          </div>
          <div className={classes.titleRoot}>
            <h2>{shop} Products</h2>
          </div>
          <div className={classes.switchRoot}>
            <TooltipSwitch
              checked={isTooltipActive}
              onChange={() => setIsTooltipActive(!isTooltipActive)}
            />
          </div>
        </div>
      </Sticky>
      <div className="Review-Products">
        {products.results.map((product) => (
          <ProductRow
            key={product.productId}
            product={product}
            shop={shop}
            isTooltipActive={isTooltipActive}
            backUrl={`${location.pathname}${location.search}`}
          />
        ))}
        <Pagination
          page={page - 1}
          pageSize={ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE}
          productCount={products.count}
        />
      </div>
      <Footer />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    height: "calc(100vh - 65px)",
    overflow: "hidden",
    overflowY: "scroll",
  },
  sticky: {
    zIndex: 1000,
    boxShadow: '0 10px 8px 0 rgba(0, 0, 0, 0.08)',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '20px 35px 20px 0px',
    backgroundColor: '#ffffff',
    position: 'relative',
    '& h2': {
      textTransform: 'capitalize',
      marginBottom: 0,
      marginLeft: 30,
    },
  },
  titleRoot: {
    display: 'flex',
    flex: 1,
  },
  searchRoot: {
    width: '270px',
    marginLeft: '22px',
  },
  searchInput: {
    marginLeft: 8,
    flex: 1,
  },
  categoryRoot: {
    width: '200px',
    marginLeft: '75px',
    marginRight: '35px',
  },
  categorySelect: {
    '&:before': {
      borderBottom: 0,
    },
  },
  iconButton: {
    padding: 10,
  },
});
