import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import { VisualFilter as VisualFilterSVG } from '@yesplz/core-models';
import { findLabelByLng } from '@yesplz/core';
import Widget from '../modules/Widget';
import helpSvg from '@yesplz/core-models/assets/svg/help-icon.svg';
import closeIcon from '@yesplz/core-web/assets/images/close-icon-gray.png';
import tickerCloseIcon from '@yesplz/core-web/assets/images/ticker-close-icon-gray.png';
import EventEmitter from '../modules/EventEmitter';
import isParamsMatchPreset from '../helpers/isParamsMatchPreset';
import { SVG } from '@svgdotjs/svg.js'

const SVG_CONTAINER_ID = 'visualfilter-svg';

const { document } = window;

const FILTER_NAVIGATION_LAYOUT = `
<div class="VisualFilter-navigation">
  <div
    id="visualfilter-prev"
    class="VisualFilter-navigation-button VisualFilter-navigation-button-left"
  ></div>
  <div
    id="visualfilter-next"
    class="VisualFilter-navigation-button VisualFilter-navigation-button-right"
  ></div>
</div>
`;

const BUTTONS_CONTAINER_TEMPLATE = `<div id="style-buttons-container" class="VisualFilter-buttons"></div>`;

const TOOLTIP_TEMPLATE = `<button id="tooltips-toggle" class="VisualFilter-button VisualFilter-tooltip-button">
  <img src="${helpSvg}" alt="" />
</button>`;

const RESET_BUTTON_TEMPLATE = `<button id="style-filter-reset" class="VisualFilter-button VisualFilter-clear-button">
  Clear
</button>`;

const FILE_INPUT_TEMPLATE = `<input type="file" id="svg-uploader" />`;

class VisualFilter extends Widget {
  defaultParams = {
    presetNavigation: true,
    navigationTemplate: FILTER_NAVIGATION_LAYOUT,
    buttonsContainerTemplate: BUTTONS_CONTAINER_TEMPLATE,
    tooltipTemplate: TOOLTIP_TEMPLATE,
    resetButtonTemplate: RESET_BUTTON_TEMPLATE,
    fileInputTemplate: FILE_INPUT_TEMPLATE,
    onFilterChange: null,
    svgViewBox: [30, -5, 250, 170],
    svgViewBoxMobile: [-5, -10, 330, 250],
    svgContainerId: SVG_CONTAINER_ID,
    svgHideTouchPoints: false,
    showTooltipsToggler: true,
    showResetButton: false,
    rotateValueOnFirstClick: false,
    showParamTags: false,
    tagHighlightColor: "#2F30EB",
    tagDefaultColor: "#333333",
    allLabel: [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }],
    useOnboarding: false,
    onRef: () => {},
  };

  constructor(params) {
    super(params);

    this.select = null;
    this.mainElement = document.createElement('div');
    this.svgContainer = null;
    this.visualFilter = null;
    this.onboardingStep = 0;
    this.onboardingPointer = null;

    this.prevBodyPartValues = {};

    if (typeof params.receiveForceSvgRenderer === 'function') {
      params.receiveForceSvgRenderer(this.forceRenderSvg);
    }
  }

  redrawDynamicElements() {
    this.addTags();
    this.handleOnboarding();
  }

  didMount() {
    const { filter } = this.state;
    if (isEmpty(filter.params)) {
      const defaultCategoryFilter = this.getCategoryDefaults(filter.categoryId);
      this.setFilter(defaultCategoryFilter);
    }
    const category = this.main.categories[filter.categoryId];
    let bodyPartValue = filter.bodyPart;
    const bodyPart = find(category.parts || [], { name: bodyPartValue })
    if (bodyPart && !bodyPart.isPublished) {
      const activeBodyPart = find(category.parts, { isPublished: true });
      bodyPartValue = activeBodyPart?.name;
    }
    this.renderSvg(filter.categoryId, bodyPartValue, filter.params);

    if (this.params.presetNavigation) {
      this.container.querySelector('#visualfilter-prev')
        .addEventListener('click', this.handlePresetClick.bind(this, false));
      this.container.querySelector('#visualfilter-next')
        .addEventListener('click', this.handlePresetClick.bind(this, true));
    }
    this.listenWindowResize();

    if (this.params.showTooltipsToggler) {
      const tooltipsToggleButton = this.container.querySelector('#tooltips-toggle');
      tooltipsToggleButton.addEventListener('click', this.tooltipsToggle);
    }

    if (this.params.showResetButton) {
      const resetButtonElement = this.container.querySelector('#style-filter-reset');
      resetButtonElement.addEventListener('click', this.resetParams);
    }

    let options = {
      root: this.container,
      rootMargin: '0px',
      threshold: 1.0
    }
    
    let observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        // console.log(entry);
        if (entry.intersectionRect.height > 0) {
          this.addTags();
          this.handleOnboarding();
        }
      });
    }, options);

    // console.log('this.mainElement', this.svgContainer);
    observer.observe(this.svgContainer);

    if (typeof this.params.onRef === 'function')
      this.params.onRef(this);

    // document.getElementById('svg-uploader')
    //   .addEventListener('change', (e) => {
    //     const file = e.target.files[0];
    //     const reader = new FileReader();

    //     reader.onload = (re) => {
    //       const filter = this.main.getFilter();
    //       this.main.categories[filter.categoryId].vfSvgUpdate = re.target.result;

    //       this.renderSvg(
    //         filter.categoryId,
    //         filter.bodyPart,
    //         filter.params
    //       );
    //     };
    //     reader.readAsText(file);
    //   });
  }

  didUpdate(prevState) {
    const { filter, config } = this.state;
    if (
      (filter.categoryId !== prevState.filter.categoryId && prevState.filter.categoryId)
      ||
      prevState.config.lng !== config.lng
    ) {
      this.renderSvg(
        this.state.filter.categoryId,
        this.state.filter.bodyPart,
        this.state.filter.params
      );

      if (this.select) {
        this.select.value = this.state.filter.category;
      }
    }
    else if (
      !isEqual(
        prevState.search?.filters?.predictions,
        this.state.search?.filters?.predictions
      )
    ) {
      // Update predictions
      this.updatePredictions();
    }
    else {
      if (this.visualFilter && filter.bodyPart) {
        this.visualFilter.setLastBodyPart(filter.bodyPart);
        this.visualFilter.updateState(filter.params);
      }
    }

    // Check if any of editor's picks are active
    // and show colored navigations.
    const navigationContainer = document.querySelector('.VisualFilter-navigation');
    if (navigationContainer) {
      if (filter.presetIndex) {
        navigationContainer.classList.add('active');
      }
      else {
        navigationContainer.classList.remove('active');
      }
    }

    if (prevState.config.isTooltipsVisible !== config.isTooltipsVisible && this.visualFilter) {
      if (config.isTooltipsVisible)
        this.visualFilter.showTooltip();
      else
        this.visualFilter.hideTooltip();
    }

    setTimeout(() => {
      this.addTags();
    }, 100);
  }

  handleOnboarding(increment = false) {
    if (!this.params.useOnboarding || this.onboardingStep > 3) return;

    if (increment) this.onboardingStep++;

    if (this.onboardingStep === 0) {
      this.renderPointer();
    }
    else {
      this.removePointer();
    }

    if (this.onboardingStep < 3) {
      this.addPulsars();
    }
    else {
      this.removePulsars();
    }
  }

  renderPointer() {
    if (this.onboardingPointer) this.removePointer();

    const touchPoint = this.mainElement.querySelector(`[id$='_point'] [data-type='selected'][style='opacity: 1;']`);

    if (!touchPoint) {
      setTimeout(() => this.renderPointer(), 100);
      return;
    }

    const pointRect = touchPoint.getBoundingClientRect();
    const svgRect = this.svgContainer.getBoundingClientRect();
    const top = pointRect.top - svgRect.top + 15;
    const left = pointRect.left - svgRect.left;

    this.svgContainer.classList.add('not-interacted');

    this.onboardingPointer = document.createElement('div');
    this.onboardingPointer.classList.add('onboarding-pointer');
    this.onboardingPointer.style.top = `${top}px`;
    this.onboardingPointer.style.left = `${left}px`;
    this.mainElement.appendChild(this.onboardingPointer);

    this.onboardingPointer.addEventListener('click', () => {
      const { bodyPart } = this.state.filter;
      this.visualFilter.handleBodyPartClick(bodyPart);
    });
    this.onboardingPointer.addEventListener('mouseover', () => {
      const { bodyPart } = this.state.filter;
      this.visualFilter.handleBodyPartMouseOver(bodyPart);
    });
    this.onboardingPointer.addEventListener('mouseout', () => {
      const { bodyPart } = this.state.filter;
      this.visualFilter.handleBodyPartMouseOut(bodyPart);
    });
  }

  removePointer() {
    if (this.onboardingPointer) {
      this.onboardingPointer.remove();
      this.onboardingPointer = null;
    }
  }

  addPulsars() {
    const { categoryId } = this.state.filter;
    const category = this.main.categories[categoryId];

    if (!category) return;

    const rootSvg = new SVG(`#${this.params.svgContainerId}`);
    category.parts.forEach(part => {
      if (part.isPublished) {
        this.addPulsar(part, 'selected', rootSvg);
      }
    });
  }

  addPulsar(part, group, rootSvg) {
    if (!rootSvg?.findOne) return;
    const selectedPointGroup = rootSvg.findOne(`#${part.name}_point g[data-type="${group}"]`);
    if (!selectedPointGroup) return;

    let pulsar = selectedPointGroup.findOne(`#${part.name}_pulsar`);

    if (pulsar) return;

    pulsar = selectedPointGroup.circle(40);
    pulsar.attr({
      id: `${part.name}_pulsar`,
      class: 'svg-pulsar',
      cx: 8,
      cy: 8,
    });
    pulsar.back();
    pulsar.animate(1000, '<>').loop().scale(1.4, 1.4);
  }

  removePulsars() {
    const { categoryId } = this.state.filter;
    const category = this.main.categories[categoryId];

    if (!category) return;

    const rootSvg = new SVG(`#${this.params.svgContainerId}`);
    category.parts.forEach(part => part.isPublished && this.removePulsar(part, rootSvg));
  }

  removePulsar(part, rootSvg) {
    const pulsar = rootSvg.findOne(`#${part.name}_pulsar`);
    if (pulsar) pulsar.remove();
  }

  addTags() {
    if (!this.params.showParamTags) return;

    const { categoryId } = this.state.filter;
    const category = this.main.categories[categoryId];

    if (!category) return;

    category.parts.forEach(part => part.isPublished && this.drawTag(part));
  }

  drawTag(part) {
    const { name: bodyPart } = part;
    const { lng } = this.state.config;
    const { categoryId, params, bodyPart: activeBodyPart } = this.state.filter;
    const { categories } = this.main;

    const rootSvg = new SVG(`#${this.params.svgContainerId}`);

    if (!rootSvg || (rootSvg && !rootSvg.findOne)) return;

    const oldTag = rootSvg.findOne(`#active-${bodyPart}-tag`);
    if (oldTag) {
      oldTag.remove();
    }

    if (params[bodyPart] === 'all' && activeBodyPart !== bodyPart) return;

    const rootTagsGroup = rootSvg.findOne('#tags');

    if (!rootTagsGroup) return;

    const draw = rootTagsGroup;

    const tag = draw.group();

    const rootTag = draw.findOne(`#tag-text-${bodyPart}`);

    if (!rootTag) { console.log(`#tag-text-${bodyPart} - element is not found.`); return; }

    const rootTagBbox = rootTag.bbox();

    tag.attr({
      id: `active-${bodyPart}-tag`,
      class: 'action-tag',
    });

    const rect = tag.rect(100, 25).fill('#ffffff').stroke(this.params.tagHighlightColor).attr({
      'x': 0,
      'y': 0,
      'rx': '12.5',
    });

    let label = findLabelByLng(categories[categoryId].tn[bodyPart][params[bodyPart]].label, lng);
    const isAbleToReset = label !== 'Any' && categories[categoryId].tn[bodyPart]['all'];
    label = label === 'All' || label === 'Any' ? findLabelByLng(this.params.allLabel, lng) : label;
    const text = tag.text(capitalize(label)).attr({
      'fill': this.params.tagHighlightColor,
      'font-size': '12px',
      'x': isAbleToReset ? 11 : 16,
      'y': 17,
    });

    const textBbox = text.bbox();
    const boxWidth = textBbox.width + (isAbleToReset ? 38 : 32);
    rect.width(boxWidth);

    let xGroup = null;
    if (isAbleToReset) {
      xGroup = tag.group();
      xGroup.rect(23, 23)
        .attr({
          'x': 0,
          'y': 1,
          'fill': '#ffffff',
          'rx': '12.5',
        });

      let x = xGroup.image(closeIcon).size(8.5, 8.5);

      xGroup.on('mouseover mouseout', (e) => {
        x.remove();
        let y;
        if (e.type === 'mouseover') {
          y = xGroup.image(tickerCloseIcon).size(8.5, 8.5);
        }
        else {
          y = xGroup.image(closeIcon).size(8.5, 8.5);
        }
        y.attr('transform', `translate(10 8)`);
      });

      xGroup.on('click', () => this.handleResetClick(bodyPart));
      x.attr('transform', `translate(10 8)`);
      xGroup.attr('transform', `translate(${textBbox.width + 13} 0)`);
    }

    tag.translate(
      (rootTagBbox.x + (rootTagBbox.width / 2) - (boxWidth / 2)),
      rootTagBbox.y + 21
    );

    tag.on('click', (e) => {
      if (xGroup && xGroup.node.contains(e.target)) return;

      this.visualFilter.handleBodyPartClick(bodyPart);
    });

    tag.on('mouseover mouseout', (e) => {
      if (e.type === 'mouseover') {
        this.visualFilter.handleBodyPartMouseOver(bodyPart);
      }
      else {
        this.visualFilter.handleBodyPartMouseOut(bodyPart);
      }
    });
  }

  updateSvgForOptionAll = () => {
    // const category = this.main.categories[this.state.filter.categoryId];
    // if (!category.tnOptionAll) return;

    // const rootSvg = new SVG(`#${this.params.svgContainerId}`);
    // Object.entries(category.tnOptionAll).forEach(([part, newAllGroupName]) => {
    //   console.log(part, newAllGroupName);
    //   const defaultAllGroup = rootSvg.findOne(`#${part}_all`);
    //   if (defaultAllGroup) defaultAllGroup.remove();
    //   // const allGroupRef = rootSvg.findOne(`#${part}_${newAllGroupName}`);
    //   // const newAllGroup = allGroupRef.clone();
    //   // newAllGroup.attr('id', `${part}_all`);
    //   // newAllGroup.attr('class', 'option-all');
    //   // allGroupRef.parent().add(newAllGroup);
    // });
  }

  handleResetClick = (resetBodyPart) => {
    const { params } = this.state.filter;

    const currentPresetIndex = this.state.filter.presetIndex;
    let presetIndex = null;
    if (currentPresetIndex) {
      const preset = this.main.categories[this.state.filter.categoryId].presetsList[currentPresetIndex];
      if (preset && isParamsMatchPreset(params, preset)) {
        presetIndex = currentPresetIndex;
      }
    }

    this.setFilter({
      presetIndex,
      occasion: null,
      bodyPart: resetBodyPart,
      params: {
        ...params,
        [resetBodyPart]: 'all',
      },
    });
  }

  tooltipsToggle = () => {
    this.main.toggleTooltips();
  }

  resetParams = () => {
    const filter = this.main.getFilter();
    this.setFilter({
      presetIndex: null,
      occasion: null,
      params: {
        ...Object.entries(filter.params)
          .reduce(
            (params, [key, value]) => ({
              ...params,
              [key]: typeof value === 'string' ? 'all' : value,
            }),
            {}
          ),
      }
    });
  }

  handlePresetClick = (isNext) => {
    if (isNext) {
      EventEmitter.emit('presetNextClick');
      return this.main.setNextPreset();
    }
    EventEmitter.emit('presetPrevClick');
    return this.main.setPrevPreset();
  }

  handleFilterParamsChange = ({ ...newParams }, isClick) => {
    const params = {
      ...this.state.filter.params,
      ...newParams,
    };
    if (!isEqual(params, this.state.filter.params)) {
      const currentPresetIndex = this.state.filter.presetIndex;
      let presetIndex = null;
      if (currentPresetIndex) {
        const preset = this.main.categories[this.state.filter.categoryId].presetsList[currentPresetIndex];
        if (preset && isParamsMatchPreset(params, preset)) {
          presetIndex = currentPresetIndex;
        }
      }
      this.setFilter({
        presetIndex,
        params,
      });
      if (typeof this.params.onFilterChange === 'function') {
        this.params.onFilterChange();
      }
    }

    if (isClick) {
      EventEmitter.emit('bodyPartTotalEngaged', {
        categoryId: this.state.filter.categoryId,
        bodyPart: this.state.filter.bodyPart,
      });
    }

    this.prevBodyPartValues = {
      ...newParams,
    };
  }

  handleBodyPartChage = (bodyPart) => {
    EventEmitter.emit('bodyPartClick', {
      categoryId: this.state.filter.categoryId,
      bodyPart,
    });
    EventEmitter.emit('bodyPartTotalEngaged', {
      categoryId: this.state.filter.categoryId,
      bodyPart,
    });
    this.setFilter({
      bodyPart,
      params: {
        ...this.state.filter.params,
      },
    });
  }

  getCategoryDefaults(categoryId) {
    if (this.main.noFilterCategories.includes(categoryId)) return {};

    const categories = this.main.categories;
    if (!categories[categoryId]) {
      console.log("Unsupported CategoryId", categoryId, "Supported Categories are", Object.keys(categories))
    }
    const bodyPart = (
      categories[categoryId].parts && categories[categoryId].parts.length
        ? categories[categoryId].parts[0].name
        : null
    );
    const params = {
      ...(
        categories[categoryId].defaultVal
          ? categories[categoryId].defaultVal
          : {}
      ),
      color: [],
    };
    return {
      categoryId,
      category: categories[categoryId].category,
      bodyPart,
      params,
    };
  }

  listenWindowResize() {
    let timeout = null;
    window.addEventListener('resize', (e) => {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        this.updateSvgViewBox(
          this.getResponsiveViewBox()
        );

        this.updateSvgScale(
          this.getResponsiveSvgScale()
        );
      }, 100);
    });
  }

  getResponsiveSvgScale() {
    const { filter } = this.state;
    const category = this.main.categories[filter.categoryId];
    if (window.innerWidth > 768) {
      return category.svgScale ? category.svgScale : this.params.svgScale;
    }
    else {
      return category.svgScaleMobile ? category.svgScaleMobile : category.svgScaleMobile;
    }
  }

  getResponsiveViewBox() {
    const { filter } = this.state;
    const category = this.main.categories[filter.categoryId];
    if (window.innerWidth > 768) {
      return category.svgViewBox ? category.svgViewBox : this.params.svgViewBox;
    }
    else {
      return category.svgViewBoxMobile ? category.svgViewBoxMobile : this.params.svgViewBoxMobile;
    }
  }

  updateSvgScale(scale) {
    if (!this.visualFilter) return;

    this.visualFilter.setScale(scale);
  }

  updateSvgViewBox(viewBox) {
    if (!this.visualFilter) return;

    this.visualFilter.setViewBox(viewBox);
  }

  updatePredictions() {
    let predictions = null;
    if (this.state.search?.filters?.predictions) {
      const { categoryId } = this.state.filter;
      const category = this.main.categories[categoryId];
      const prefixes = category.parts.map(p => `${category.category}.${p.name}`);
      predictions = Object.values(this.state.search?.filters?.predictions || {})
        .filter(p => {
          const values = p.value.split('.');
          const value = values[0] + '.' + values[1];
          return prefixes.includes(value);
        })
        .map(p => {
          const values = p.value.split('.');
          const category = values[0];
          const bodyPart = values[1];
          const bodyPartValue = values[2];
          return {
            ...p,
            category,
            bodyPart,
            bodyPartValue,
          };
        })
        .reduce((all, p) => {
          if (!all[p.bodyPart]) {
            all[p.bodyPart] = {};
          }
          all[p.bodyPart][p.bodyPartValue] = p;
          return all;
        }, {});
    }
    if (this.visualFilter)
      this.visualFilter.setPredictions(predictions);
  }

  forceRenderSvg = () => {
    const { filter: { categoryId, bodyPart, params } } = this.state;
    this.renderSvg(categoryId, bodyPart, params);
    setTimeout(() => {
      this.addTags();
      this.handleOnboarding();
    }, 100);
  }

  renderSvg(categoryId, bodyPart, params) {
    if (!this.svgContainer || !this.main.categories[categoryId]) return;
    else if (this.main.categories[categoryId] && !this.main.categories[categoryId].vfSvgSource) {
      this.mainElement.classList.add('empty');
      if (this.params.onSVGHide) this.params.onSVGHide();
      return;
    }

    this.mainElement.classList.remove('empty');

    this.svgContainer.innerHTML = '';

    const category = this.main.categories[categoryId];

    const visualFilter = new VisualFilterSVG(`#${this.params.svgContainerId}`, {
      categoryId: categoryId,
      category: category.category,
      categoryCfg: category,
      defaultBodyPart: bodyPart,
      defaultState: {
        ...params,
      },
      hideArrow: true,
      customViewBox: this.getResponsiveViewBox(),
      onFilterChange: this.handleFilterParamsChange,
      onPropChange: this.handleBodyPartChage,
      onSVGLoaded: () => {
        this.addTags();
        this.handleOnboarding();
        if (typeof this.params.onSVGLoaded === 'function') {
          this.params.onSVGLoaded();
        }
        EventEmitter.emit('mfSVGLoaded', this.state.filter.categoryId);
      },
      onBodyPartClick: () => {
        if (typeof this.params.onTouchClick === 'function') {
          this.params.onTouchClick();
        }
        this.handleOnboarding(true);
      },
      debugTouchArea: false,
      showHighlightOnBuild: true,
      svgScale: this.getResponsiveSvgScale(),
      badgeMode: this.params.svgHideTouchPoints,
      rotateValueOnFirstClick: this.params.rotateValueOnFirstClick,
      tagHighlightColor: this.params.tagHighlightColor,
      tagDefaultColor: this.params.tagDefaultColor,
      lng: this.state.config.lng,
    });
    visualFilter.setLastBodyPart(bodyPart);

    this.visualFilter = visualFilter;
  }

  render() {
    this.mainElement.classList.add('VisualFilter');

    if (this.params.presetNavigation) {
      this.mainElement.insertAdjacentHTML('beforeend', this.params.navigationTemplate);
    }

    if (this.params.showTooltipsToggler || this.params.showResetButton) {
      this.mainElement.insertAdjacentHTML('beforeend', this.params.buttonsContainerTemplate);
    }

    if (this.params.showTooltipsToggler) {
      this.mainElement.querySelector('#style-buttons-container')
        .insertAdjacentHTML('beforeend', this.params.tooltipTemplate);
    }

    if (this.params.showResetButton) {
      this.mainElement.querySelector('#style-buttons-container')
        .insertAdjacentHTML('beforeend', this.params.resetButtonTemplate);
    }

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    this.svgContainer = svg;
    svg.id = this.params.svgContainerId;
    this.mainElement.appendChild(svg);

    // this.mainElement
    //   .insertAdjacentHTML('beforeend', this.params.fileInputTemplate);

    return this.mainElement;
  }
}

export default (params) => {
  return new VisualFilter(params);
};