import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import { findLabelByLng } from '@yesplz/core';

import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';

class WashFilter extends Widget {
  defaultParams = {
    showReset : false,
    resetImage: resetIconSvg,
    resetLabel: 'Reset',
    smallIcons: false,
    title: 'Wash Type',
  };

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.element.classList.add('ThumbnailPicker');
    if (this.params.smallIcons) {
      this.element.classList.add('ThumbnailPicker--small-icons');
    }
    this.element.classList.add('ThumbnailPicker--with-chekmark');
    this.washElements = {};

    this.washValues = {};
  }

  handleLabelClick = (value) => {
    const { categoryId, params, presetIndex: currentPresetIndex } = this.state.filter;

    EventEmitter.emit('washClick', {
      categoryId,
      wash: value,
    });

    let wash = params.wash ? [...params.wash] : [];
    if (wash.includes(value)) {
      wash.splice(wash.indexOf(value), 1);

      EventEmitter.emit('washRemoved', {
        categoryId,
        wash: value,
      });
    }
    else {
      wash.push(value);

      EventEmitter.emit('washApplied', {
        categoryId,
        wash: value,
      });
    }

    const newParams = {
      ...params,
      wash,
    };

    let presetIndex = null;
    if (currentPresetIndex) {
      const preset = this.main.categories[categoryId].presetsList[currentPresetIndex];
      if (preset && isParamsMatchPreset(newParams, preset)) {
        presetIndex = currentPresetIndex;
      }
    }

    this.setFilter({
      presetIndex,
      params: newParams,
    });
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.renderWashTypes();
    }
    else if (
      !isEqual(prevState.filter.params.wash, this.state.filter.params.wash)
    ) {
      const prevWash = prevState.filter.params.wash;
      const wash = this.state.filter.params.wash;
      const addedItems = difference(wash, prevWash);
      const removedItems = difference(prevWash, wash);
      addedItems.forEach(type => {
        if (this.washElements[type])
          this.washElements[type].classList.add('is-active');
      });
      removedItems.forEach(type => {
        if (this.washElements[type])
          this.washElements[type].classList.remove('is-active');
      });
    }
  }

  renderWashTypes() {
    const { lng } = this.state.config;
    const { categoryId, params } = this.state.filter;
    const category = this.main.categories[categoryId];
    this.element.innerHTML = '';

    const washTypes = category.wash || [];

    let washCount = 0;
    [
      ...washTypes,
      ...(
        this.params.showReset && washTypes.length
          ? [{ type: 'reset', label: findLabelByLng(this.params.resetLabel, lng), image: this.params.resetImage, className: 'reset' }]
          : []
      ),
    ].forEach((wash) => {
      const labelElement = document.createElement('div');
      labelElement.innerHTML = `
        <div class="ThumbnailPickerOption-thumbnail">
          <div class="ThumbnailPickerOption-imageWrapper">
            <img src="${wash.image}" alt="" />
          </div>
        </div>
        <h5>${findLabelByLng(wash.label, lng)}</h5>
      `;
      labelElement.className = 'ThumbnailPickerOption' + (
        params.wash && params.wash.includes(wash.value) ? ' is-active' : ''
      ) + (wash.className ? ` ${wash.className}` : '');
      this.washElements[wash.value] = labelElement;
      this.element.appendChild(labelElement);

      labelElement.addEventListener('click', () => {
        this.handleLabelClick(wash.value);
      });

      washCount = washCount + 1;
    });
    if (washCount === 0) {
      this.container.classList.add('ThumbnailPicker-hidden');
    }
    else {
      this.container.classList.remove('ThumbnailPicker-hidden');
    }

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(washTypes);
    }
  }

  render() {
    const container = document.createElement('div');
    const title = document.createElement('h3');
    title.innerText = findLabelByLng(this.params.title, this.state.config.lng);
    container.appendChild(title);
    this.renderWashTypes();
    container.appendChild(this.element);
    return container;
  }
}

export default (params) => {
return new WashFilter(params);
};
