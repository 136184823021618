import { validateUserInfo } from '@yesplz/core-redux/ducks/admin/auth';
import { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const UserContext = createContext({});

export const useUser = () => {
  return useContext(UserContext);
}

export default function UserProvider({ children }) {
  const { userInfo, isLoading } = useSelector(({ AdminAuth }) => ({
    userInfo: AdminAuth.userInfo,
    isLoading: AdminAuth.isLoadingUserInfo,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(validateUserInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={{
      userInfo, isLoading,
    }}>
      {children}
    </UserContext.Provider>
  );
}
