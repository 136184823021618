import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Base Widget component that has common life cycle
 * for all Widgets.
 */
export default class Widget {
  params = null;
  main = null;

  constructor(params) {
    if (!params.container) {
      throw new Error('`container` is required parameter in Widget.');
    }

    this.container = window.document.querySelector(params.container);
    this.params = params;
  }

  set defaultParams(defaultParams) {
    this.params = merge({}, defaultParams, this.params);
  }

  mount(state) {
    this.state = state;
    if (!this.container) {
      console.error(new Error(`Widget container "${this.params.container}" doesn't exist on the page.`));
      return;
    }

    const element = this.render();
    if (!element) return;
    this.container.appendChild(element);
    this.didMount();
  }

  unmount() {
    if (!this.container) {
      console.error(new Error(`Widget container "${this.params.container}" doesn't exist on the page.`));
      return;
    }

    this.willUnmount();
    this.container.removeChild(this.container.firstChild);
  }

  updateInternalStateValue(newState, prevState) {
    this.state = newState;
    this.update();
    if (this.didUpdate) this.didUpdate(prevState);
  }

  didUpdate() {}

  /**
   * Function that runs immediatly after mounting.
   * Only once in a life cycle.
   */
  didMount() {}

  /**
   * Function that runs before unmounting.
   * Only once in a life cycle.
   */
  willUnmount() {}

  /**
   * Inserts Elements into DOM
   */
  render() {}

  /**
   * Updates Elements or content
   */
  update() {}
}
