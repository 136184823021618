import { SearchBar as BaseSearchBar } from '@yesplz/textsearch/src/widgets/SearchBar';
import logoImage from '../assets/logo.svg';
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/css';
import take from 'lodash/take';
import aiStylistButton from '../assets/ai-stylist-button.svg';
import aiStylistIcon from '../assets/ai-stylist-icon.svg';
import { womenPrompts } from '@yesplz/textsearch/src/modules/Prompts';
import { shuffle } from 'lodash';

const LAYOUT_TEMPLATE = `
<div class="yesplz-text-search-main yesplz-dual-search-bar">
  <div class="yesplz-text-search-container">
    <button class="yesplz-text-search-close-button">
      <svg class="mobile-svg" width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3438 19.6184C8.74206 16.4645 5.2827 13.434 1.65625 10.2596L12.3438 0.380859" stroke="white"/>
      </svg>
    </button>
    <img src="${logoImage}" alt="" class="yesplz-text-search-logo" />
    <div class="yesplz-text-search-header">
      <div class="yesplz-text-search-bar-container">
        <form id="yesplz-text-search-form" class="yesplz-text-search-form">
          <input autocomplete="off" id="text-search-input" type="text" placeholder="Search" value="" max />
          <button class="submit" type="submit" id="yesplz-text-search-button">Search</button>
          <button id="yesplz-text-search-form-clear" class="clear" type="button">Clear</button>
          <button id="yesplz-text-ai-stylist-switch" class="ai-stylist">
            <img src="${aiStylistButton}" alt="AI Stylist" />
          </button>
        </form>
      </div>
      <div class="yesplz-text-search-menu-container">
        <div class="yesplz-menu sale-container">
          <ul>
            <li class="sale" data-menu-value="sale">Sale Only</li>
          </ul>
        </div>
        <div class="yesplz-menu">
          <ul>
            <li data-menu-value="all" class="is-active">All</li>
            <li data-menu-value="WOMEN">Women</li>
            <li data-menu-value="MEN">Men</li>
            ${window.location.origin.includes('poc.') && !window.location.href.includes('/demo') ? '' : `
            <li data-menu-value="K-BEAUTY">K-Beauty</li>
            <li data-menu-value="LIFE">Life</li>
            <li data-menu-value="KIDS">Kids</li>
            `}
            
          </ul>
        </div>
        <div class="yesplz-suggestions" id="yesplz-suggested-queries-container">
          <h5>Top Suggestions</h5>
          <ul id="yesplz-suggested-queries"></ul>
        </div>
        <div class="yesplz-suggestions" id="yesplz-prompts-list-container">
          <h5>AI Stylist Prompt Ideas</h5>
          <ul id="yesplz-prompts-list"></ul>
        </div>
      </div>
    </div>
    <div class="yesplz-text-search-popular-queries">
      <h4>Popular Search</h4>
      <ul id="yesplz-popular-queries"></ul>
    </div>
    <div class="yesplz-text-search-prompts-list">
      <h4 id="yesplz-popular-queries-title">AI Stylist Prompt Ideas</h4>
      <ul id="yesplz-prompts-list"></ul>
    </div>
    <div class="yesplz-text-search-footer">
      <button id="see-results">See All Results</button>
    </div>
    <div class="yesplz-text-search-empty-results">
      <h3>
        Unfortunately,<br />
        there are no results matching your request.<br /><br />
        <a id="ai-stylist-link" href="#">Why not ask the AI Stylist?</a>
      </h3>
    </div>
    <div class="yesplz-text-search-products">
      <h3>Recommended products</h3>
      <div id="yesplz-text-search-products"></div>
    </div>
    <div class="yesplz-ai-search-products">
      <div class="yesplz-ai-search-message-container" style="display: none;">
        <img src="${aiStylistIcon}" alt="AI Stylist Icon" />
        <div>
          <div id="yesplz-ai-search-message"></div>
          <div class="ai-stylist-loading" style="display: none;">
            <span class="ai-stylist-loading-text">One sec...</span>
            <div class="skeleton-container">
              <div class="skeleton"></div>
              <div class="skeleton"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="yesplz-ai-search-products"></div>
    </div>
  </div>
</div>
`;

const SLIDER_TEMPLATE = `
<div class="swiper-container product-list">
  <ul class="swiper-wrapper">
  </ul>
</div>
`;

export class DualSearchBar extends BaseSearchBar {
  constructor(params) {
    params.templates = params.templates || {};

    if (!params.templates.layout) {
      params.templates.layout = LAYOUT_TEMPLATE;
    }
    params.templates.list = SLIDER_TEMPLATE;

    params.overflowContainer = document.querySelector('#wrap') || document.body;

    if (!params.messageContainer) {
      params.messageContainer = "#yesplz-ai-search-message";
    }

    // params for wconcept stg
    if(!window.origin.includes('poc.')) {
      params.templates.layout = `<div class="yesplz-text-search-backdrop"></div>` + LAYOUT_TEMPLATE;
    }
    // params for wconcept stg end

    super(params);

    this.swiper = null;
    this.message = '';

    this.messageContainer = null;
    this.messageContainerSelector = params.messageContainer;
    
    this.updateMenuVisibility();
  }

  didUpdate(prevState) {
    if (this.searchMode !== "ai") return;

    if (this.state.previewSearch.isLoading !== prevState.previewSearch.isLoading) {
      if (this.state.previewSearch.isLoading) {
        this.mainElement.querySelector('.yesplz-ai-search-message-container').style.display = 'flex';
        this.mainElement.querySelector('.ai-stylist-loading').style.display = 'block';
        this.mainElement.classList.remove('no-results');
      } else if (this.state.previewSearch.results) {
        this.renderAiSearchProducts(this.state.previewSearch.results.slice(0, 5));
        this.mainElement.querySelector('.ai-stylist-loading').style.display = 'none';

        const seeAllResultsButton = this.mainElement.querySelector('#see-results');
        seeAllResultsButton.style.display = 'block';
      } else {
        this.mainElement.querySelector('.ai-stylist-loading').style.display = 'none';
      }
    }
    if (this.state.previewSearch.failed !== prevState.previewSearch.failed && this.state.previewSearch.failed) {
      this.setMessage("Oops! We couldn't complete your search - please check your internet connection and try again.")
    } else if (this.state.previewSearch.message !== prevState.previewSearch.message) {
      this.setMessage(this.state.previewSearch.message);
    }
  }

  setMessage(message) {
    this.message = message;
    this.renderMessage();
  }

  renderMessage() {
    if (!this.messageContainer) {
      this.messageContainer = this.mainElement.querySelector(this.messageContainerSelector);
    }

    if (this.messageContainer) {
      if (this.message !== undefined) 
        this.messageContainer.innerHTML = this.message;
    } else {
      console.error('messageContainer not found');
    }
  }

  updateMenuVisibility() {
    const menuItems = this.mainElement.querySelectorAll('.yesplz-menu ul li');
    const isAiMode = this.searchMode === 'ai';
    let womenItem;

    menuItems.forEach(item => {
      const value = item.getAttribute('data-menu-value');
      const isVisibleInAiMode = value === 'WOMEN' || value === 'MEN';
      
      item.style.display = isAiMode && !isVisibleInAiMode ? 'none' : '';
      
      if (value === 'WOMEN') womenItem = item;
    });

    if (isAiMode && !['WOMEN', 'MEN'].includes(this.topCategory)) {
      this.topCategory = 'WOMEN';
      if (womenItem) {
        menuItems.forEach(item => item.classList.toggle('is-active', item === womenItem));
      }
      this.renderPromptIdeas(shuffle(womenPrompts).slice(0, 5));
    }
  }

  renderAiSearchProducts(products) { // Not used in the current implementation.
    this.aiProductsContainer = this.mainElement.querySelector('#yesplz-ai-search-products');
    this.aiProductsContainer.innerHTML = this.listTemplate();
    const listContainer = this.aiProductsContainer.querySelector('ul');

    products.forEach(({ product }) => {
      this.renderProduct(product, listContainer);
    });

    if (products.length > 0) {
      this.mainElement.classList.remove('empty-results');
      this.aiProductsContainer.style.display = 'block';

      if (window.innerWidth < 768) {
        setTimeout(() => {
          this.swiper = new Swiper('#yesplz-ai-search-products .swiper-container', {
            slidesPerView: 2.3,
            loop: true,
            initialSlide: 0,
            freeMode: true,
            spaceBetween: 10,
          });
        }, 100);
      }
    } else {
      this.mainElement.classList.add('empty-results');
      this.aiProductsContainer.style.display = 'none';
    }

    if (typeof this.params.onProductsRendered === 'function') {
      this.params.onProductsRendered(products);
    }
  }

  loadResults() {
    const query = this.mainInput.value;
    
    const searchMode = this.searchMode ?? this.state.filters.searchMode;

    if (searchMode === 'ai') {
      this.main.setState({
        search: {
          ...this.main.state.search,
          query: query
        }
      })
    }

    if (query.length > 0) {
      this.clearButton.classList.add('is-active');
      this.mainElement.classList.add('has-query');

      if (searchMode !== 'ai') { // ai search does not have preview
        this.mainElement.classList.remove('no-results');
      }
      
      this.updateMenuVisibility();

      const seeAllResultsButton = this.mainElement.querySelector('#see-results');
      if (searchMode === 'ai') {
        if (this.state.previewSearch.results) {
          seeAllResultsButton.style.display = 'block';
        } else {
          seeAllResultsButton.style.display = 'none';
        }
      } else {

        seeAllResultsButton.style.display = 'block';

        const suggestionPromise = Promise.all([
          (
            this.params.useBrandSuggestions
              ? this.main.fetchBrandsSuggestions(query)
              : Promise.resolve([])
          ),
          this.main.fetchTextAutocomplete({
            query,
            [this.params.categoryParamName]: this.topCategory,
            sale: this.sale,
          }),
        ]);
        this.suggestionPromise = suggestionPromise;
        suggestionPromise
          .then(([brands, texts]) => {
            const suggestionsMax = window.innerWidth <= 768 ? 4 : 8;
            const brandSuggestions = take(brands, texts.length > (suggestionsMax / 2) ? (suggestionsMax / 2) : suggestionsMax - texts.length).map(brand => ({ suggestion: brand.brandName, url: brand.brandUrl, type: 'brand' }));
            return [
              ...brandSuggestions,
              ...(brandSuggestions.length > 0 ? [{ suggestion: ' ', type: 'separator' }] : []),
              ...take(texts, brandSuggestions.length > (suggestionsMax / 2) ? (suggestionsMax / 2) : suggestionsMax - brandSuggestions.length)
            ];
          })
          .then(suggested => {
            if (this.suggestionPromise === suggestionPromise) {
              this.renderSuggestedQueries(suggested, query);
            }
          })
          .catch(error => {
            console.error('text suggestion error', error);
          });

      const searchPromise = this.main.fetchTextSearch({
        query,
        sort: 'popular',
        [this.params.categoryParamName]: this.topCategory,
        sale: this.sale,
        limit: 5,
        preview: true,
      })
      this.searchPromise = searchPromise;
      searchPromise
        .then(data => {
          if (this.searchPromise === searchPromise) {
            this.productsContainer.style.display = 'none';

            window.requestAnimationFrame(() => {
              this.productsContainer.innerHTML = this.listTemplate();
              const listContainer = this.productsContainer.querySelector('ul');
              data?.results?.forEach(({ product }) => {
                this.renderProduct(product, listContainer);
              });

              if (typeof this.params.onProductsRendered === 'function') {
                this.params.onProductsRendered(data.results);
              }

              if (data?.results && data?.results?.length > 0) {
                this.mainElement.classList.remove('empty-results');
                if (window.innerWidth < 768) {
                  setTimeout(() => {
                    this.productsContainer.style.display = 'block';
                    this.swiper = new Swiper('.swiper-container', {
                      slidesPerView: window.innerWidth >= 768 ? 5 : 2.3,
                      loop: true, // 루프 모드 사용 여부
                      initialSlide: 0,
                      freeMode: true,
                      spaceBetween: 10,
                    });
                  }, 100);
                }
                else {
                  this.productsContainer.style.display = 'block';
                }
              }
              else {
                this.mainElement.classList.add('empty-results');
              }
            });
          }
        })
        .catch(error => {
          console.error('text search error', error);
        });
      }
    }
    else {
      this.reset();
    }
  }

  renderProduct(product, listContainer) {
    if (!listContainer) {
      listContainer = document.querySelector(".swiper-wrapper");
    }
    let productHtml = this.productTemplate({
      ...product,
      srcUrl: this.getProductUrl(product),
      frontImg: product.frontImgSrc,
      name: product.productName,
      brand: product.brand,
      isSale: !!(product.originalPrice && product.originalPrice !== product.salePrice),
      originalPrice: this.formatCurrency(product.originalPrice),
      price: this.formatCurrency(product.salePrice),
    });

    if (typeof this.params.onBeforeProductRendered === 'function') {
      const isAiSearchPreview = true;
      productHtml = this.params.onBeforeProductRendered(productHtml, product, isAiSearchPreview);
    }

    const html = `<li class="swiper-slide product-item" data-testid="recommended-product-item">${productHtml}</li>`;
    listContainer.insertAdjacentHTML("beforeend", html);
    listContainer.lastElementChild.querySelectorAll('a')?.forEach(a => {
      a.addEventListener('click', () => {
        EventEmitter.emit('txtSearchBarProductClick', product.productId);
      });
    });

    listContainer.closest(".swiper-container").style.visibility = "visible";
  }
}

export default (params) => {
  return new DualSearchBar(params);
};
