import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import capitalize from 'lodash/capitalize';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

export class ListFilter extends Widget {
  defaultParams = {
    title: 'List',
    filterParamName: null,
    listParamName: null,
    listMiddleware: null,
    multiple: true,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.ul = document.createElement('ul');

    this.mainElement.appendChild(this.ul);
  }

  didUpdate(prevState) {
    const { listParamName, filterParamName } = this.params;

    if (!isEqual(
      prevState.search?.filter?.[listParamName],
      this.state.search?.filter?.[listParamName]
    )) {
      this.renderItems();
    }
    else if (
      !this.params.multiple &&
      prevState.filter[filterParamName] !== this.state.filter[filterParamName]
    ) {
      const prevValue = prevState.filter[filterParamName];
      const nextValue = this.state.filter[filterParamName];

      const nextButton = this.mainElement.querySelector(`[data-item-value="${nextValue}"]`);
      if (nextButton) nextButton.classList.add('is-selected');

      const prevButton = this.mainElement.querySelector(`[data-item-value="${prevValue}"]`)
      if (prevButton) prevButton.classList.remove('is-selected');
    }
    else if (
      this.params.multiple &&
      !isEqual(prevState.filter[filterParamName], this.state.filter[filterParamName])
    ) {
      const selectedItems = this.state.filter[filterParamName];
      const prevSelectedItems = prevState.filter[filterParamName];

      const selected = difference(selectedItems, prevSelectedItems);
      const deselected = difference(prevSelectedItems, selectedItems);

      selected.forEach(value => {
        const button = this.mainElement.querySelector(`[data-item-value="${value}"]`);
        if (button) button.classList.add('is-selected');
      });
      deselected.forEach(value => {
        const button = this.mainElement.querySelector(`[data-item-value="${value}"]`)
        if (button) button.classList.remove('is-selected');
      });
    }
  }

  get items() {
    const { listParamName } = this.params;
    return (
      this.params.items
        ? this.params.items
        : typeof this.params.listMiddleware === 'function'
          ? this.params.listMiddleware(this.state.search?.filter?.[listParamName])
          : this.convertList(this.state.search?.filter?.[listParamName])
    );
  }

  convertList(list = {}) {
    return Object.values(list)
      .map(({ value, isActive }) => ({ label: capitalize(value), value, isActive }))
      .filter(({ value }) => value)
      // Alphabetically sort items
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
  }

  handleItemClick = (e) => {
    const button = e.target;
    if (!button.className.includes('is-disabled')) {
      const newValue = button.getAttribute('data-item-value');

      if (this.params.multiple) {
        this.main.toggleList(this.params.filterParamName, newValue);
      }
      else {
        this.main.toggleValue(this.params.filterParamName, newValue);
      }

      EventEmitter.emit(
        `txtSidebar${
          capitalize(this.params.filterParamName)
        }${(
          (this.state.filter[this.params.filterParamName] || []).includes(newValue)
            ? 'Applied'
            : 'Removed'
        )}`,
        newValue
      );
    }
  }

  renderLabel(item) {
    return item.label;
  }

  renderItems() {
    const { filterParamName  } = this.params;
    const items = this.items;
    this.ul.innerHTML = '';
    items.forEach((item) => {
      const li = document.createElement('li');
      li.innerHTML = `${this.renderLabel(item)}`;
      li.setAttribute('data-item-value', item.value);
      this.ul.appendChild(li);

      if (
        this.state.filter[filterParamName] &&
        (this.params.multiple ? this.state.filter[filterParamName] : [this.state.filter[filterParamName]]).includes(item.value)
      ) {
        li.classList.add('is-selected');
      }

      if (!item.isActive) {
        li.classList.add('is-disabled');
      }

      li.addEventListener('click', this.handleItemClick);
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(items);
    }
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    this.renderItems();
    return this.mainElement;
  }
}

export default (params) => {
  return new ListFilter(params);
};
