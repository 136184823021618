import find from 'lodash/find';

export default function findLabelByLng(messages, lng = 'en') {
  if (typeof messages === 'string') return messages;

  const message = find(messages, { lng });

  if (!message && lng !== 'en') return findLabelByLng(messages, 'en');

  return message && message.label ? message.label : '';
}
