/**
 * Storage implementation for retailers categories
 * available for importing inside or redux reducers and actions.
 * With simple setters and getters.
 */

let _categories = null;

function getCategories() {
  return _categories;
}

function setCategories(categories) {
  _categories = categories;
}

export {
  getCategories,
  setCategories,
}
