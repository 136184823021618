import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import flatten from 'lodash/flatten';
import { ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE } from '@yesplz/core-web/config/constants'
const prefix = 'review';

const SET_LOADING = `${prefix}/SET_LOADING`;
const SET_PRODUCTS = `${prefix}/SET_PRODUCTS`;
const SET_VIEWED_PRODUCT = `${prefix}/SET_VIEWED_PRODUCT`;
const ADD_PRODUCT_TO_CACHE = `${prefix}/ADD_PRODUCT_TO_CACHE`;

const initialState = {
  loading: false,
  products: [],
  count: 0,
  productsCache: {},
  viewedProduct: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: [...action.products],
        count: action.count,
      };
    case SET_VIEWED_PRODUCT:
      return {
        ...state,
        viewedProduct: action.product,
      };
    case ADD_PRODUCT_TO_CACHE:
      const existing = state.productsCache[action.product.product.productId];
      return {
        ...state,
        productsCache: {
          ...state.productsCache,
          [action.product.product.productId]: {
            ...(existing || {}),
            ...action.product,
          },
        }
      };
    default:
      return state;
  }
}

const setLoading = (loading) => {
  return { type: SET_LOADING, loading };
}

const setProducts = (products, count) => {
  return { type: SET_PRODUCTS, products, count };
}

const setViewedProduct = (product) => {
  return { type: SET_VIEWED_PRODUCT, product };
}

const addProductToCache = (product) => {
  return { type: ADD_PRODUCT_TO_CACHE, product };
}

export const fetchProducts = (category, search, sort, duration, page = 1) => async (dispatch, getState) => {
  const { userInfo, serverType } = getState().AdminAuth;
  const offset = (page - 1) * ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE;
  dispatch(setLoading(true));
  try {
    const { data } = await axios.get(`/retailers/${userInfo.retailer}/${serverType}/products/`, {
      params: {
        category, search, sort, duration, offset,
        limit: ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE,
        admin: 1, // Get extra product information
      }
    })
    dispatch(setProducts(camelcaseKeys(data.results, {deep: true}), data.count));
  } catch (e) {
    console.log('Error:', e)
  }
  dispatch(setLoading(false));
}

export const fetchRecommendedProducts = (productId, isViewed = false) => async (dispatch, getState) => {
  dispatch(setViewedProduct(null));
  const { userInfo, serverType } = getState().AdminAuth;
  try {
    const { data } = await axios.get(`/retailers/${userInfo.retailer}/${serverType}/recommends/yml`, {
      params: {
        productId: productId,
        admin: 1, // Get extra product information
        offset: 0,
        limit: 50
      }
    });

    if (isViewed) {
      return dispatch(setViewedProduct(camelcaseKeys(data, {deep: true})));
    }

    return dispatch(addProductToCache(camelcaseKeys(data, {deep: true})));
  } catch (e) {
    console.log('Error:', e)
  }
}

export const fetchStyleSuggestions = (productId, isViewed = false) => async (dispatch, getState) => {
  const { userInfo, serverType } = getState().AdminAuth;
  dispatch(setViewedProduct(null));
  try {
    const { data } = await axios.get(`/retailers/${userInfo.retailer}/${serverType}/stylewith`, {
      params: {
        productId: productId,
        set_count: 5,
        format: 'json',
        admin: 1, // Get extra product information
      }
    });

    return dispatch(addProductToCache(camelcaseKeys({
      product: data.product,
      stylewith: flatten(data.stylewith.map(s => s.products)),
    }, {deep: true})));
  } catch (e) {
    console.log('Error:', e)
  }
}

export const fetchRandomRecommendedProducts = (category) => async (dispatch, getState) => {
  const { userInfo, serverType } = getState().AdminAuth;
  try {
    const { data } = await axios.get(`/retailers/${userInfo.retailer}/${serverType}/products/`, {
      params: {
        category,
        sort: 'random',
        offset: 0,
        limit: 1,
        admin: 1,
      }
    });
    dispatch(fetchRecommendedProducts(camelcaseKeys(data.results[0].productId, {deep: true}), true));
  } catch (e) {
    console.log('Error:', e)
  }
}
