import { createClient } from '@yesplz/client';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import qs from 'qs';
import { textSearchWidgets, aiSearchWidgets, dualSearchWidgets } from './widgets';
import EventEmitter from './modules/EventEmitter';
import { Hooks } from '@yesplz/visualfilter/src/modules/Hooks';
import './modules/analytics';

const searchStateParams = ['query', 'category', 'categories', 'topCategory', 'sort', 'sale', 'new', 'bestsellers', 'limit', 'offset', 'price', 'brands', 'colors', 'materials', 'sizes', 'discount', 'shipping', 'newArrivals', 'cfo', 'search_id', 'searchMode']; // params that should be stored in the search query
const listParams = ['categories', 'brands', 'colors', 'materials', 'sizes', 'discount', 'shipping', 'cfo']; // params that should be stored as lists

function parseBoolean(val) {
  if (val === 'false' || val === 'true') {
    return val === 'true'
  }
  return  val;
}

function parseObject(obj) {
  var result = {};
  var key, val;
  for (key in obj) {
    val = parseBoolean(obj[key]);
    if (val !== null) result[key] = val; // ignore null values
  }
  return result;
}

export default class TextSearch {
  widgetsContainer = [];

  constructor(params) {
    this.$hooks = new Hooks();

    const { initialFilter, useSearchQuery = true, makePreviewRequest = true, isAISearch = false, isDualSearch = false } = params;

    this.makePreviewRequest = makePreviewRequest;

    this.widgets = isDualSearch ? dualSearchWidgets : (isAISearch ? aiSearchWidgets : textSearchWidgets);

    this.state = {
      search: {},
      previewSearch: {},
      config: { lng: 'en' },
    };
    this.state.filter = {
      offset: 0,
      ...initialFilter,
      ...(useSearchQuery ? this.searchQuery : {}),
    };

    if (useSearchQuery) {
      window.addEventListener('popstate', () => {
        this.updateSearchQuery({
          ...(
            searchStateParams.reduce((updates, param) => {
              updates[param] = null;
              return updates;
            }, {})
          ),
          ...this.searchQuery,
        });
      });
    }

    this.useSearchQuery = useSearchQuery;

    this.searchPromise = null;
    this.previewPromise = null;

    this.currentSearch = null;

    this.isAISearch = !!isAISearch || this.state.filter.searchMode === "ai";
    this.isDualSearch = !!isDualSearch;

    this.client = createClient();

    if (params.clientBaseURL) {
      this.client.setBaseURL(params.clientBaseURL);
    }
    if (params.clientBaseURLToken) {
      this.client.setToken(params.clientBaseURLToken);
    }
    if(params.clientDefaultToken) {
      this.client.setDefaultToken(params.clientDefaultToken);
    }
    if (params.clientTextSearchPath) {
      this.client.setTextSearchPath(params.clientTextSearchPath);
    }
    if (params.clientAliases) {
      this.client.setAliases(params.clientAliases);
    }
    if (params.clientAiBaseURL) {
      this.client.setAiBaseURL(params.clientAiBaseURL);
    }

    this.searchAdditionalParams = {};
    if (params.searchAdditionalParams) {
      this.searchAdditionalParams = params.searchAdditionalParams;
    }

    this.ignoredParams = ["searchMode"]; // params that should be ignored when sending to the backend

    if (this.isAISearch) {
      // For AI search, we should send only the search_id to the backend without the query
      this.addIgnoredParams(["query"]);

      EventEmitter.setState({
        searchMode: "ai",
      });
    } else {
      EventEmitter.setState({
        searchMode: "text",
      });
    }

    window.requestAnimationFrame(() => {
      if (!isEmpty(this.state.filter) && this.state.filter.query) {
        this.searchWithCurrentFilter();
      }
    });
  }

  addIgnoredParams(params) {
    this.ignoredParams = this.ignoredParams.concat(params);
  }

  get searchQuery() {
    const searchQuery = pick(
      qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true }),
      searchStateParams
    );
    if (searchQuery['price'] && /^[0-9.]*-[0-9.]*$/.test(searchQuery['price'])) {
      let priceText = searchQuery['price'];
      if (priceText.indexOf('-') === 0) {
        priceText = priceText.slice(1);
      }
      const prices = priceText.split('-');
      const from = parseInt(prices[0], 10);
      const to = parseInt(prices[1], 10);
      
      searchQuery['price'] = `${!isNaN(from) && from && from > 0 ? from : ''}-${!isNaN(to) && to && to > 0 ? to : ''}`;
    }
    else {
      delete searchQuery['price'];
    }
    listParams.forEach(name => {
      if (searchQuery[name] && typeof searchQuery[name] === 'string') {
        searchQuery[name] = searchQuery[name].split(',');
      }
      else if (!isEmpty(searchQuery) && !searchQuery[name]) {
        searchQuery[name] = [];
      }
    });

    return parseObject(searchQuery);
  }

  addWidget(widget) {
    return this.mountWidget(widget);
  }

  mountWidget = widget => {
    this.widgetsContainer.push(widget);
    widget.main = this;
    widget.mount(this.state);

    return widget;
  }

  notifyWidgets(prevState) {
    this.widgetsContainer.forEach(widget => {
      widget.updateInternalStateValue(this.state, prevState);
    });
  }

  updateSearchQuery(updates) {
    if (updates?.price === '-') {
      updates.price = null;
    }
    const filter = this.$hooks.call('updateSearchQuery', {
      ...this.state.filter,
      offset: 0,
      ...updates,
    });
    const search = qs.stringify(filter, { skipNulls: true, arrayFormat: 'comma' });

    if (this.useSearchQuery) {
      const url = window.location.origin + window.location.pathname + `?${search}`;
      window.history.replaceState({}, "", url);
    }

    this.setState({ filter });
  }

  setState(stateUpdates) {
    const prevState = cloneDeep(this.state);
    const newState = {
      ...cloneDeep(this.state),
      ...cloneDeep(stateUpdates),
    };

    this.state = newState;
    this.notifyWidgets(prevState);

    if (
      !isEmpty(this.state.filter)
      && !isEqual(
        omit(this.state.filter, this.ignoredParams),
        omit(prevState.filter, this.ignoredParams)
      )
      && this.state.filter.query
    ) {
      this.searchWithCurrentFilter();
    }
  }

  setSort(sort) {
    this.updateSearchQuery({ sort });
  }

  setLimit(limit) {
    this.updateSearchQuery({ limit });
  }

  setOffset(offset) {
    this.updateSearchQuery({ offset });
  }

  setPage(page) {
    this.updateSearchQuery({ page });
  }

  setPrice(price) {
    this.updateSearchQuery({ price });
  }

  setSearchId(search_id) {
    this.updateSearchQuery({ search_id });
  }

  toggleBrand(brand) {
    this.toggleList('brands', brand);
  }

  toggleValue(name, value) {
    const newValue = this.state.filter[name] === value ? null : value;
    this.updateSearchQuery({ [name]: newValue });
  }

  toggleList(name, value) {
    let values = this.state.filter[name] || [];
    const isInclude = values.includes(value);
    values = isInclude
      ? values.filter(v => v !== value)
      : [...values, value];

    this.updateSearchQuery({ [name]: values });
    return !isInclude;
  }

  resetList(name) {
    this.updateSearchQuery({ [name]: [] });
  }

  fetchBrandsSuggestions(query, count) {
    return this.client.textBrands({ query, count }, this.searchAdditionalParams);
  }

  fetchPopularQueries(params) {
    return this.client.textPopularQueries(params, this.searchAdditionalParams);
  }

  fetchTextAutocomplete(params) {
    return this.client.textAutocomplete(params, this.searchAdditionalParams);
  }

  fetchTextSearch({ limit = 72, offset = 0, sale, ...params }) {
    return this.client.textSearch(
      {
        limit,
        offset,
        sale: sale ? "saleonly" : "all",
        ...params,
      },
      this.searchAdditionalParams
    );
  }

  combineRecommendations({ outfits, pairwith, ...rest }) {
    const pairwithCombined = {
      title: pairwith.map(({ pairwith }) => pairwith),
      categories: [],
      pairwith: [],
      search_by: [],
      products: [],
    };
    for (let j = 0; j < 24; j++) {
      for (let i = 0; i < pairwith.length; i++) {
        if (pairwith[i].products[j]) {
          pairwithCombined.products.push(pairwith[i].products[j]);
        }
      }
    }
    pairwith.forEach(productPairs => {
      pairwithCombined.categories = [
        ...pairwithCombined.categories,
        ...productPairs.categories,
      ];
      pairwithCombined.pairwith.push(productPairs.pairwith);
    });
    pairwithCombined.products = pairwith.length > 24 ? pairwithCombined.products.slice(0, pairwith.length) : pairwithCombined.products.slice(0, 24);
    return {
      outfits,
      pairwith,
      pairwithCombined,
      ...rest,
    };
  }

  searchWithCurrentFilter() {
    if (!this.state.filter.query) return;
    this.isAISearch = this.state.filter.searchMode === 'ai';

    let params = {
      ...cloneDeep(this.state.filter),
      isAISearch: this.isAISearch,
    };
    if (typeof params.categories === 'string') {
      params.categories = params.categories.split(',');
    }
    if (params.gender) {
      if (params.gender === 'women') {
        params.categories.push('Women');
      }
      else if (params.gender === 'men') {
        params.categories.push('Men');
      }
      delete params.gender;
    }
    if (params.sale) {
      params.sale = true;
    }
    else {
      params.sale = false;
    }
    if (params.new) {
      params.categories.push('New');
    }
    if (params.bestsellers) {
      params.categories.push('Top Sellers');
    }
    delete params.new;
    delete params.bestsellers;

    if (!isEmpty(this.ignoredParams)) {
      params = omit(params, this.ignoredParams);
    }

    if (isEqual(params, this.currentSearch)) return;

    params = this.$hooks.call('beforeSearchParamsSend', params);
    EventEmitter.emit('searchStarted', params);

    if (this.state.search.streaming)
      return;

    if (this.isAISearch && !this.state.search.message && !this.state.filter.search_id) {
      this.fetchAiSearch({  gender: this.state.filter.topCategory });
      return;
    }

    this.setState({ search: {
      ...this.state.search,
      isLoading: true,
      error: null,
    } });

    let eventEmitted = false;
    const notifyOnlyOnceOnFinished = (data) => {
      if (eventEmitted) return;
      EventEmitter.emit('searchFinished', data);
      eventEmitted = true;
    }

    let isSearchPromiseResolved = false;
    if (this.makePreviewRequest) {
      const previewPromise = this.fetchTextSearch({
        ...params,
        preview: true,
      });
      this.previewPromise = previewPromise;
      previewPromise
        .then(data => {
          if (this.previewPromise === previewPromise && !isSearchPromiseResolved) {
            notifyOnlyOnceOnFinished(data);
            this.setState({search: {
              ...this.state.search,
              ...data,
              mode: this.isAISearch ? 'ai' : 'text',
              filter: this.state.search.filter,
              isLoading: false,
              error: null,
            }});
          }
        })
        .catch(() => {});
    }
    const searchPromise = this.fetchTextSearch(params);
    this.searchPromise = searchPromise;
    this.currentSearch = params;
    searchPromise
      .then(data => {
        if (this.searchPromise === searchPromise) {
          notifyOnlyOnceOnFinished(data);
          this.setState({search: {
            ...this.state.search,
            ...data,
            mode: this.isAISearch ? 'ai' : 'text',
            filter: data.filter || data.filters || {},
            isLoading: false,
            error: null,
          }});
          isSearchPromiseResolved = true;
        }
      })
      .catch(error => {
        this.setState({search: {
          ...this.state.search,
          isLoading: false,
          error: 'Failed search request!',
        }});
        console.error('text search error', error);
      });

    return searchPromise;
  }

  fetchAiSearch({ isPreview = false, ...params }) {
    this.client.fetchAiSearch({
      query: this.state.search.query || this.state.filter.query,
      gender: this.state.filter.topCategory,
      ...params,
    }, (data) => {
      if(data.filter) {
        data = {
          ...data,
          filters: data.filter,
        }

        delete data.filter;
      }
      let results = data.results;

      if (isPreview) {
        this.setState({
          previewSearch: {
            mode: "ai",
            ...this.state.previewSearch,
            ...data,
            ...(data.message
              ? { message: (this.state.previewSearch.message || "") + data.message }
              : {}),
            ...(results ? { results } : {}),
            isLoading: data.streaming,
          },
        });
      } else {
        this.setState({
          search: {
            mode: "ai",
            ...this.state.search,
            ...data,
            ...(data.message
              ? { message: (this.state.search.message || "") + data.message }
              : {}),
            ...(results ? { results } : {}),
            isLoading: data.streaming,
          },
        });
      }

      if (!isPreview && data.searchId) {
        this.setSearchId(data.searchId);
      }
     });
  }

  on(event, subscriber) {
    EventEmitter.on(event, subscriber);
  }

  off(event, subscriber) {
    EventEmitter.off(event, subscriber);
  } 
}
