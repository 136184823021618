import Handlebars from 'handlebars';
import { find } from 'lodash';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const { document } = window;

const DEFAULT_LAYOUT = `
  {{#each items}}
    <a href="#" data-sort="{{this.value}}">{{this.label}}</a>
  {{/each}}
`;

class SortBy extends Widget {
  defaultParams = {
    containerClassName: '',
    activeClass: 'is-active',
    items: [
      { label: 'Best Sellers', value: 'popular' },
      { label: 'Price : Low to High', value: 'price:asc' },
      { label: 'Price : High to Low', value: 'price:desc' },
      { label: "What's New", value: 'newest' },
      { label: 'Discount : High to Low', value: 'sales_percent' },
    ],
    templates: {
      layout: DEFAULT_LAYOUT,
    },
    onRendered: () => {},
  };

  constructor(params) {
    super(params);

    this.layoutTemplate = Handlebars.compile(this.params.templates.layout);

    const element = document.createElement('span');
    element.className = this.params.containerClassName;
    this.mainElement = element;
  }

  didMount() {
    this.mainElement.querySelectorAll('[data-sort]')
      .forEach(element => {
        const value = element.getAttribute('data-sort');
        element.addEventListener('click', (e) => {
          e.preventDefault();
          this.handleChange(value);
        });
      });

    if (this.state.filter.sort) this.toggleSelected(this.state.filter.sort);
  }

  didUpdate(prevState) {
    const { sort: prevSort } = prevState.filter;
    const { sort } = this.state.filter;
    if (sort !== prevSort) {
      if (prevSort) this.toggleSelected(prevSort);
      if (sort) this.toggleSelected(sort);
    }
  }

  handleChange = (value) => {
    this.main.setSort(value);

    if (typeof this.params.onChange === 'function') {
      const item = find(this.params.items, { value });
      this.params.onChange(item);
    }

    EventEmitter.emit('txtSortChanged', value);
  }

  toggleSelected(sort) {
    const sortElement = this.mainElement.querySelector(
      `[data-sort="${sort}"]`
    );
    if (sortElement) {
      sortElement.classList.toggle(this.params.activeClass);
    }
  }

  render() {
    if (this.state.filter.searchMode === 'ai') {
      return null;
    }

    const activeItem = find(this.params.items, { value: this.state.filter.sort || 'popular' })
    this.mainElement.innerHTML = this.layoutTemplate({
      items: this.params.items,
      activeItem,
    });

    if (typeof this.params.onRendered === 'function') {
      setTimeout(() => {
        this.params.onRendered(this.params.items, activeItem);
      }, 0);
    }

    return this.mainElement;
  }
}

export default (params) => {
  return new SortBy(params);
};
