import merge from 'lodash/merge';
import Hooks from './Hooks';

/**
 * Base Widget component that has common life cycle
 * for all Widgets.
 */
export default class Widget {
  params = null;
  main = null;

  constructor(params) {
    if (!params.hasOwnProperty('container')) {
      throw new Error('`container` is required parameter in Widget.');
    }

    this.container = window.document.querySelector(params.container);
    this.params = Hooks.call('widget.settingParams', params, this);
  }

  set defaultParams(defaultParams) {
    this.params = merge({}, defaultParams, this.params);
  }

  setFilter(filterPartial) {
    this.main.setFilterAndSearch(filterPartial);
  }

  mount() {
    if (!this.container) {
      console.error(new Error(`Widget container "${this.params.container}" doesn't exist on the page.`));
      return;
    }

    this.state = this.main.store.getState();
    this.main.store.subscribe(() => {
      this.updateInternalStateValue(this.main.store.getState());
    });

    this.container.innerHTML = '';

    const element = this.render();
    this.container.appendChild(element);
    this.didMount();
  }

  unmount() {
    this.willUnmount();
    this.container.removeChild(this.container.firstChild);
  }

  updateInternalStateValue(newState) {
    const prevState = this.state;
    this.state = newState;
    this.update();
    if (this.didUpdate) this.didUpdate(prevState);
  }

  didUpdate() {}

  /**
   * Function that runs immediatly after mounting.
   * Only once in a life cycle.
   */
  didMount() {}

  /**
   * Function that runs before unmounting.
   * Only once in a life cycle.
   */
  willUnmount() {}

  /**
   * Inserts Elements into DOM
   */
  render() {}

  /**
   * Updates Elements or content
   */
  update() {}
}
