import React from 'react';
import classnames from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { changeAdminRetailer, setServerType } from '@yesplz/core-redux/ducks/admin/auth';
import find from 'lodash/find';
import styles from './RetailerSelector.module.scss';

function ArrowDropDownIcon2() {
  return (
    <i>
      <ArrowDropDownIcon />
    </i>
  );
}

export default function RetailerSelector({ className }) {
  const { retailers, userInfo, serverType } = useSelector(({ AdminAuth }) => AdminAuth);
  const dispatch = useDispatch();
  const selectedRetailer = find(retailers, { sid: userInfo.retailer });

  return (
    <div
      className={classnames(styles.root, className)}
    >
      <Select
        className={styles.select}
        value={userInfo.retailer}
        onChange={(event) => {
          dispatch(changeAdminRetailer(event.target.value));
        }}
        IconComponent={ArrowDropDownIcon2}
      >
        {retailers && retailers.map(({ sid, name }) => (
          <MenuItem
            key={`retailer-${sid}`}
            value={sid}
            className='category-menu-item'
          >
            {name}
          </MenuItem>
        ))}
      </Select>
      {selectedRetailer && selectedRetailer.urls.length > 1 && 
      <Select
        className={styles.select}
        value={serverType}
        onChange={(event) => {
          dispatch(setServerType(event.target.value));
        }}
        IconComponent={ArrowDropDownIcon2}
      >
        {selectedRetailer.urls.map((url) => (
          <MenuItem
            key={`retailer-${url.id}`}
            value={url.sid}
            className='category-menu-item'
          >
            {url.name}
          </MenuItem>
        ))}
      </Select>}
    </div>
  );
};
