import Handlebars from 'handlebars';
import { find } from 'lodash';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const { document } = window;

const DEFAULT_LAYOUT = `
  {{#each items}}
    <a href="#" data-limit="{{this.value}}">{{this.label}}</a>
  {{/each}}
`;

class PageSize extends Widget {
  defaultParams = {
    containerClassName: '',
    activeClass: 'is-active',
    items: [
      { label: '36 PER PAGE', value: '36' },
      { label: '72 PER PAGE', value: '72' },
      { label: '108 PER PAGE', value: '108' },
    ],
    templates: {
      layout: DEFAULT_LAYOUT,
    },
    onRendered: () => {},
  };

  constructor(params) {
    super(params);

    this.layoutTemplate = Handlebars.compile(this.params.templates.layout);

    const element = document.createElement('span');
    element.className = this.params.containerClassName;
    this.mainElement = element;
  }

  didMount() {
    this.mainElement.querySelectorAll('a[data-limit]')
      .forEach(element => {
        element.addEventListener('click', this.handleChange);
      });
  }

  didUpdate() {}

  handleChange = (e) => {
    e.preventDefault();

    const element = e.target;
    let value = null;
    if (!element.classList.contains(this.params.activeClass)) {
      const activeElement = this.mainElement.querySelector(
        `a.${this.params.activeClass}`
      );
      if (activeElement) {
        activeElement.classList.remove(this.params.activeClass);
      }

      element.classList.add(this.params.activeClass);
      value = element.getAttribute('data-limit');
      this.main.setLimit(value);
    }
    else {
      element.classList.remove(this.params.activeClass);
      this.main.setLimit(null);
    }

    if (typeof this.params.onChange === 'function') {
      const item = find(this.params.items, { value });
      this.params.onChange(item);
    }

    EventEmitter.emit('txtPageSizeChanged', value);
  }

  render() {
    this.mainElement.innerHTML = this.layoutTemplate({
      items: this.params.items,
      activeItem: find(this.params.items, { value: this.state.filter.limit || '72' }),
    });

    if (typeof this.params.onRendered === 'function') {
      setTimeout(() => {
        this.params.onRendered(this.params.items);
      }, 0);
    }

    return this.mainElement;
  }
}

export default (params) => {
  return new PageSize(params);
};
