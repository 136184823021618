import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import get from 'lodash/get';
import client from '@yesplz/client';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

class SizesFilter extends Widget {
  defaultParams = {
    title: 'Size',
    filterBy: 'resultFilters',
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.ul = document.createElement('ul');

    this.mainElement.appendChild(this.ul);

    this.allSizes = {};

    this.params.filterByOriginal = this.params.filterBy;
    this.params.filterBy =  ['brands', 'eventId'].includes(this.params.filterBy) ? `params.${this.params.filterBy}` : this.params.filterBy;
  }

  didMount() {
    if (this.params.filterBy === 'resultFilters') {
      this.renderSizes();
    }
    else {
      this.fetchAndRender();
    }
  }

  didUpdate(prevState) {
    if (
      this.params.filterBy === 'resultFilters'
      &&
      !isEqual(
        prevState.search?.filters?.sizes,
        this.state.search?.filters?.sizes
      )
    ) {
      this.renderSizes();
    }
    else if (this.params.filterBy !== 'resultFilters' && !isEqual(
      get(prevState.filter, this.params.filterBy),
      get(this.state.filter, this.params.filterBy)
    )) {
      this.fetchAndRender();
    }
    else if (
      !isEqual(prevState.filter.params.size, this.state.filter.params.size)
    ) {
      const prevSize = prevState.filter.params.size;
      const size = this.state.filter.params.size;
      const addedItems = difference(size, prevSize);
      const removedItems = difference(prevSize, size);

      addedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-size-id="${val}"]`);
        if (!el) return;

        el.classList.add('active');
      });
      removedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-size-id="${val}"]`);
        if (!el) return;

        el.classList.remove('active');
      });
    }
  }

  get indexId() {
    const { filterBy } = this.params;
    const value = get(this.state.filter, filterBy);
    return Array.isArray(value) ? value.join(',') : value;
  }

  get sizes() {
    if (this.params.filterBy === 'resultFilters') {
      return Object.values(this.state.search?.filters?.sizes || {})
        .map(s => ({ ...s, label: `${s.value}` }));
    }

    return (this.allSizes[this.indexId] || []).map(s => ({ ...s, isActive: true }));
  }

  set sizes(sizes) {
    this.allSizes[this.indexId] = sizes;
  }

  async fetchAndRender() {
    const { filterBy } = this.params;
    const { searchAdditionalParams } = this.state.config;

    if (isEmpty(this.sizes)) {
      const data = await (() => {
        if (filterBy === 'categoryId') {
          return client.sizesByCategory(this.indexId, searchAdditionalParams);
        }
        else {
          console.log('client.sizeFilters');
          return client.sizeFilters({
            [this.params.filterByOriginal]: this.indexId,
          }, searchAdditionalParams);
        }
      })();
      this.sizes = data.map(s => ({ ...s, value: `${s.filter}` }));
    }

    this.renderSizes();
  }

  handleItemClick = (e, value) => {
    e.stopPropagation();
    const { categoryId, params } = this.state.filter;
    const newParams = { ...params };

    EventEmitter.emit('sizeClick', {
      categoryId,
      size: value,
    });

    if (!Array.isArray(newParams.size) || !newParams.size) newParams.size = [];

    if (newParams.size.includes(value)) {
      newParams.size = newParams.size.filter(d => d !== value);

      EventEmitter.emit('sizeRemoved', {
        categoryId,
        size: value,
      });
    }
    else {
      newParams.size = [
        ...newParams.size,
        value,
      ];

      EventEmitter.emit('sizeApplied', {
        categoryId,
        size: value,
      });
    }

    if (newParams.size.length === 0) delete newParams.size;

    this.setFilter({
      params: newParams,
    });
  }

  renderSizes() {
    const { params } = this.state.filter;
    this.ul.innerHTML = '';
    const sizes = this.sizes;

    if (!sizes) return;

    const numberSizes = sizes.filter(size => !isNaN(size.value));
    const stringSizes = sizes.filter(size => isNaN(size.value));

    numberSizes.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
    stringSizes.sort((a, b) => this.getOrder(a.value) - this.getOrder(b.value));

    const sortedSizes = [...numberSizes, ...stringSizes];

    sortedSizes.forEach((size, refIndex) => {
      const li = document.createElement('li');
      if (!size.isActive) li.classList.add('is-disabled');
      li.innerHTML = `${size.label}`;
      li.setAttribute('data-size-id', size.value);
      li.setAttribute('data-size-ref-index', refIndex);
      this.ul.appendChild(li);

      if (params.size && params.size.includes(size.value)) {
        li.classList.add('active');
      }

      li.addEventListener('click', (e) => size.isActive ? this.handleItemClick(e, size.value) : null);
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(sizes);
    }
  }

  getOrder(size) {
    const baseOrder = { 'xs': -1, 's': 1, 'm': 2, 'l': 3, 'xl': 4 };
    const match = size.toLowerCase().match(/x/g) || [];
    const xCount = match.length;

    const lowerSize = size.toLowerCase();

    if (lowerSize.includes('xs')) {
      return baseOrder['xs'] - xCount;
    } else if (lowerSize.includes('xl')) {
      return baseOrder['xl'] + xCount;
    } else {
      return baseOrder[lowerSize] || 0;
    }
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new SizesFilter(params);
};
