import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const discounts = [
  { label: 'up to 20% off', value: '-20' },
  { label: '20%-30% off', value: '20-30' },
  { label: '30%-50% off', value: '30-50' },
  { label: '50%-75%+ off', value: '50-' },
];

class DiscountsFilter extends Widget {
  defaultParams = {
    title: 'Discount',
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.ul = document.createElement('ul');

    this.mainElement.appendChild(this.ul);
  }

  didMount() {
    this.renderDiscounts();
  }

  didUpdate(prevState) {
    if (
      !isEqual(
        prevState.search?.filters?.discountRate,
        this.state.search?.filters?.discountRate
      )
    ) {
      this.renderDiscounts();
    }
    else if (
      !isEqual(prevState.filter.params.discount, this.state.filter.params.discount)
    ) {
      const prevDiscount = prevState.filter.params.discount;
      const discount = this.state.filter.params.discount;
      const addedItems = difference(discount, prevDiscount);
      const removedItems = difference(prevDiscount, discount);

      addedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-discount-id="${val}"]`);
        if (!el) return;

        el.classList.add('active');
      });
      removedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-discount-id="${val}"]`);
        if (!el) return;

        el.classList.remove('active');
      });
    }
  }

  handleItemClick = (e, value) => {
    e.stopPropagation();
    const { categoryId, params } = this.state.filter;
    const newParams = { ...params };

    EventEmitter.emit('discountClick', {
      categoryId,
      discount: value,
    });

    if (!Array.isArray(newParams.discount) || !newParams.discount) newParams.discount = [];

    if (newParams.discount.includes(value)) {
      newParams.discount = newParams.discount.filter(d => d !== value);

      EventEmitter.emit('discountRemoved', {
        categoryId,
        discount: value,
      });
    }
    else {
      newParams.discount = [
        ...newParams.discount,
        value,
      ];

      EventEmitter.emit('discountApplied', {
        categoryId,
        discount: value,
      });
    }

    if (newParams.discount.length === 0) delete newParams.discount;

    this.setFilter({
      params: newParams,
    });
  }

  isOptionAvailable = (discount) => {
    if (!this.state.search?.filters?.discountRate) return true;

    const rates = this.state.search?.filters?.discountRate;

    if (rates.max === 0) return false;

    let [ min, max ] = discount.value.split('-');
    [ min, max ] = [ parseInt(min, 10) || 0, parseInt(max, 10) || 100 ];
    
    return min <= Math.floor(rates.max);
  }

  renderDiscounts() {
    const { params } = this.state.filter;
    this.ul.innerHTML = '';

    const renderedItems = [];
    discounts.forEach((discount, refIndex) => {
      if (!this.isOptionAvailable(discount)) return;
      renderedItems.push(discount);
      const li = document.createElement('li');
      li.innerHTML = `${discount.label}`;
      li.setAttribute('data-discount-id', discount.value);
      li.setAttribute('data-discount-ref-index', refIndex);
      this.ul.appendChild(li);

      if (params.discount && params.discount.includes(discount.value)) {
        li.classList.add('active');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, discount.value));
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(renderedItems);
    }
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new DiscountsFilter(params);
};
