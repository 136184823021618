import Handlebars from 'handlebars';
import Widget from '../modules/Widget';
// import { findLabelByLng } from '@yesplz/core';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';
import { formatCurrency, findLabelByLng } from '@yesplz/core';

const { document } = window;

const TEMPLATE = `
<div>
  <ul>
    {{#each items}}
      <li data-type="{{this.type}}" data-value="{{this.value}}" class="is-active{{#if this.nonRemovable}} non-removable{{/if}}">
        {{this.label}}
      </li>
    {{/each}}
  </ul>
</div>
`

const shippingOptions = [
  { label: 'Overseas - Standard', value: 'OVERSEAS_STANDARD' },
  { label: 'Priority', value: 'PRIORITY' },
  { label: 'Overseas - Pre-order', value: 'OVERSEAS_PRE_ORDER' },
  { label: 'Overseas - Made to order', value: 'OVERSEAS_MADETOORDER' },
];

const discounts = [
  { label: 'up to 20% off', value: '-20' },
  { label: '20%-30% off', value: '20-30' },
  { label: '30%-50% off', value: '30-50' },
  { label: '50%-75%+ off', value: '50-' },
];

const newArrivals = [
  { label: 'New today', value: '1' },
  { label: 'New for 7 days', value: '7' },
  { label: 'New for 30 days', value: '30' },
];

export class Chips extends Widget {
  defaultParams = {
    containerClassName: 'simple-presets-occasions-tags',
    ignoreParams: [],
    nonRemovables: [],
    currency: {},
    templates: {
      layout: TEMPLATE,
    },
    onBeforeRender: null,
    onRedered: null,
    bodyParts: {
      parts: [],
      values: {},
    },
  };

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(this.params.templates.layout);

    const element = document.createElement('div');
    element.className = this.params.containerClassName;
    this.mainElement = element;

    this.items = [];
  }

  assignEventHandlers() {
    const childElements = this.mainElement.querySelectorAll('li');
    childElements.forEach(childElement => {
      childElement.addEventListener('click', () => {
        const type = childElement.getAttribute('data-type');
        const value = childElement.getAttribute(`data-value`);

        this.handleValueRemoval(type, value);
      });
    });
  }

  didMount() {
    this.renderItems();
  }

  didUpdate() {
    this.renderItems();
  }

  handleValueRemoval(type, value) {
    if (this.params.nonRemovables.includes(type)) return;

    if (!type || !value) return;

    if (Array.isArray(this.state.filter[type])) {
      const values = this.state.filter[type];
      this.main.updateSearchQuery({ [type]: values.filter(v => v !== value) });
    }
    else {
      this.main.updateSearchQuery({ [type]: null });
    }
  }

  buildItems() {
    const { ignoreParams } = this.params;
    const params = this.state.filter;
    this.items = [];

    const push = (type, value, label) => {
      this.items.push({ type, value, label });
    }

    function makeLabel(label) {
      if (!label) return '';
      return label
        .replace(/\//g, ' ')
        .replace(/-/g, ' ')
        .split(' ')
        .map(capitalize)
        .join(' ');
    }

    const parts = this.params.bodyParts?.parts?.map(part =>part.name) || [];

    Object.entries(params).forEach(([key, value]) => {
      // ignore some params if there are set
      if (ignoreParams.includes(key)) return;

      if (parts.includes(key)) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const valueSettings = this.params.bodyParts.values?.[key]?.[value];
          if (valueSettings) {
            push(key, value, findLabelByLng(valueSettings.label));
          }
        });
      }

      if (key === 'gender' && value) {
        if (value) push(key, value, capitalize(value));
      }
      if (key === 'categories' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, makeLabel(value));
        });
      }
      if (key === 'sale' && value) {
        if (value) push(key, value, 'Sale');
      }
      if (key === 'new' && value) {
        if (value) push(key, value, 'New');
      }
      if (key === 'bestsellers' && value) {
        if (value) push(key, value, 'Top Sellers');
      }
      if (key === 'colors' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, capitalize(value));
        });
      }
      if (key === 'patterns' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, capitalize(value));
        });
      }
      if (key === 'occasion' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, capitalize(value));
        });
      }
      if (key === 'vibes' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, capitalize(value));
        });
      }
      if (key === 'materials' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, capitalize(value));
        });
      }
      if (key === 'sizes' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, `Size ${value}`);
        });
      }
      if (key === 'shipping' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const option = find(shippingOptions, { value });
          push(key, value, option.label);
        });
      }
      if (key === 'discount' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const discount = find(discounts, { value });
          push(key, value, discount.label);
        });
      }
      if (key === 'price' && value && value !== '0-' && value !== '-') {
        let prices = value.split('-');
        prices = prices.filter(p => p).map(p => formatCurrency(parseInt(p, 10) || 1, this.params.currency));
        let label = prices.join(" — ");
        if (prices.length === 1) {
          label = label + ' or more';
        }
        push(key, value, label);
      }
      if (key === 'brands' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, value);
        });
      }
      if (key === 'newArrivals' && value) {
        const item = find(newArrivals, { value });
        if (item) {
          [...(Array.isArray(value) ? value : [value])].forEach(value => {
            push(key, value, findLabelByLng(item.label));
          });
        }
      }
    });
  }

  getItems() {
    return this.items;
  }

  renderItems() {
    this.buildItems();

    let items = this.getItems().map(item => ({
      ...item,
      nonRemovable: this.params.nonRemovables.includes(item.type),
    }));

    if (typeof this.params.onBeforeRender === 'function') {
      items = this.params.onBeforeRender(items) || items;
    }

    this.mainElement.innerHTML = this.template({
      items,
    });

    if (this.items.length)
      this.mainElement.classList.remove('empty');
    else
      this.mainElement.classList.add('empty');

    this.assignEventHandlers();

    if (typeof this.params.onRedered === 'function') {
      this.params.onRedered(items);
    }
  }

  render() {
    this.renderItems();
    return this.mainElement;
  }
}

export default (params) => {
  return new Chips(params);
};
