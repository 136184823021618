import React from 'react';
import classnames from 'classnames';
import { BASE_IMG_PATH } from '@yesplz/core-web/config/constants'
import BaseTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import './ProductGrid.scss';

const Tooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 254,
    minHeight: 362,
    maxWidth: 'none',
    width: 'auto',
    height: 'auto',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(BaseTooltip);

export default ({ className, product, shop, isTooltipActive }) => {
  let tooltip = product.score ? 'score: ' + product.score.toFixed(1) + '\n' : '';
  tooltip += product.score_info ? product.score_info + '\n' : '';
  tooltip += product.product.text_predictions ? product.product.text_predictions + '\n' : '';
  tooltip += product.product.raw_predictions ? product.product.raw_predictions : '';

  let imageUrl = (
    product.product.front_img_src
      ? product.product.front_img_src
      : product.product.front_img
        ? product.product.front_img
        : (
          product.product.variants[0]
          &&
          product.product.variants[0].images[0]
          &&
          product.product.variants[0].images[0].srcURI
        )
          ? product.product.variants[0].images[0].srcURI
          : null
  );

  if (imageUrl && !imageUrl.startsWith('http')) {
    imageUrl = BASE_IMG_PATH + '/' + imageUrl;
  }

  return (
    <div className={classnames('Shopify-product-container', className)}>
      <Tooltip disableHoverListener={!isTooltipActive} title={<pre>{tooltip}</pre>} placement="left-start">
      <div className='product-image'>
        <a
          href={`${process.env.PUBLIC_URL}/${shop}/review/product/${product.product.productId || product.product.product_id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={imageUrl} className='front-image' alt='' />
        </a>
      </div>
      </Tooltip>
      <div className='product-detail'>
        <p className='product-id'>{product.product.productId || product.product.product_id}</p>
        <p className='product-name'>{product.product.productName || product.product.name}</p>
        <p className='product-brand'>{product.product.brandName || product.product.brand}</p>
      </div>
    </div>
  )
}
