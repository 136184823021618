import { createTransform } from 'redux-persist';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { getCategories } from './categories';

function decodeParams(fullStateJsonParams) {
  return Object.keys(fullStateJsonParams).reduce((state, paramName) => {
    state[paramName] = JSON.parse(fullStateJsonParams[paramName]);
    return state;
  }, {});
}


/**
 * This transform is handling categories that are not supose
 * to have persistent filter.
 *
 * if `ignorePersistence` parameter from category is set to `true`
 * previous valid to persistence filter would be remembered instead.
 */
let previousPersistentFilter = null;
const IgnorePersistenceTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key, fullState) => {
    const categories = getCategories();
    const category = categories[fullState.categoryId];

    // If category should not be persistent save previous instead.
    if (category && category.ignorePersistence && previousPersistentFilter) {
      // console.log(previousPersistentFilter[key]);
      return previousPersistentFilter[key];
    }

    // If category allowed to be persistend
    // save and save it as new previousFilter.
    if (!isEqual(previousPersistentFilter, fullState)) {
      previousPersistentFilter = cloneDeep(fullState);
    }

    return inboundState;
  },
  // transform state being rehydrated
  (outboundState, _key, fullStateJsonParams) => {
    // Filter here comming as params with not decoded JSON
    // That's why we need to decode each param separately.
    const fullState = decodeParams(fullStateJsonParams);

    // Saving rehydrated filter as prev filter to return
    // instead of ignored.
    if (!isEqual(previousPersistentFilter, fullState)) {
      previousPersistentFilter = cloneDeep(fullState);
    }
    return outboundState;
  },
);

const filterTransforms = [
  IgnorePersistenceTransform,
];

export {
  filterTransforms,
};
