import React from 'react'
import ReactDOM from 'react-dom/client';
import axios from 'axios'
import App from './App'
import reportWebVitals from './reportWebVitals';
// import { loadSvgSource } from '@yesplz/core-web/utils/svgHelpers'
import initializeAxios from '@yesplz/core-web/utils/initializeAxios'

const authToken = localStorage.getItem('accessToken');

initializeAxios({
  authToken,
})

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_PATH
if (authToken) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
