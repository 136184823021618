import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE } from '@yesplz/core-web/config/constants';
import qs from 'qs';
import Sticky from 'react-sticky-el';
import camelcaseKeys from 'camelcase-keys';
import Loading from 'components/Loading';
import CategoryDropdown from 'components/CategoryDropdown';
import SortDropdown from 'components/SortDropdown';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import TooltipSwitch from 'components/TooltipSwitch';
import { USE_TOOLTIP, REVIEW_CATEGORIES } from 'config/consts';
import SearchBar from './SearchBar';
import find from 'lodash/find';

// core-redux
import { fetchCategories } from '@yesplz/core-redux/ducks/admin/auth';
import { fetchProducts, fetchRecommendedProducts } from '@yesplz/core-redux/ducks/admin/review';

import ProductRow from './ProductRow';

export default function ReviewList({ match, history, location }) {
  const classes = useStyles();
  const rootContainer = useRef();
  const categoryId = match.params.categoryId ? decodeURIComponent(match.params.categoryId) : match.params.categoryId;
  const dispatch = useDispatch();
  const { categories, serverType, userInfo: { retailer } } = useSelector(({ AdminAuth }) => AdminAuth);
  const { loading, products, count } = useSelector(({ AdminReview }) => AdminReview);
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  let searchParams = location.search ? qs.parse(location.search.slice(1)) : {};
  const page = searchParams.page ? parseInt(searchParams.page, 10) : 1;
  const search = searchParams.search;
  const sort = searchParams.sort;
  const duration = searchParams.duration ? parseInt(searchParams.duration, 10) : null;

  useEffect(() => {
    dispatch(fetchCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isExists = !!find(REVIEW_CATEGORIES, { category: categoryId });
    if (!isExists) {
      history.push(`/review/${encodeURIComponent(REVIEW_CATEGORIES[0].category)}`);
    }
  }, [categories, categoryId]);

  useEffect(() => {
    fetch();
    rootContainer.current.scrollTo(0, 0);
  }, [categoryId, categories, search, sort, duration, page, retailer, serverType]);

  const fetch = () => {
    if (categoryId && categories)
      dispatch(fetchProducts(categoryId, search, sort, duration, page));
  }

  function setSearchParams(searchParams) {
    const searchQuery = qs.stringify(
      searchParams,
      { skipNulls: true }
    );
    history.push(`/review/${encodeURIComponent(categoryId)}?${searchQuery}`);
  }

  const handleSearch = (search) => {
    setSearchParams({
      search,
    });
  }

  const handleCategoryChange = (categoryId) => {
    history.push(`/review/${encodeURIComponent(categoryId || '')}`);
  }

  const handleSortChange = (sort) => {
    setSearchParams({ sort });
  }

  return (
    <div id="MainScroll" className={classes.root} ref={rootContainer}>
      {loading && <Loading />}
      <Sticky
        scrollElement="#MainScroll"
        stickyClassName={classes.sticky}
      >
        <div className={classes.header}>
          <div className={classes.searchRoot}>
            <SearchBar
              search={search}
              onSearch={handleSearch}
              onRandomClick={() => {
                if (sort === 'random') {
                  fetch();
                }
                else {
                  handleSortChange('random');
                }
              }}
            />
          </div>

          <CategoryDropdown
            selected={categoryId}
            categories={REVIEW_CATEGORIES}
            onChange={handleCategoryChange}
          />

          <div className={classes.sortRoot}>
            <SortDropdown
              sort={sort}
              duration={duration}
              onChange={handleSortChange}
            />
          </div>

          {USE_TOOLTIP && <div className={classes.switchRoot}>
            <TooltipSwitch
              checked={isTooltipActive}
              onChange={() => setIsTooltipActive(!isTooltipActive)}
            />
          </div>}
        </div>
      </Sticky>
      <div className="Review-Products">
        {products.map((product) => (
          <ProductRow
            key={product.productId}
            product={camelcaseKeys(product, {deep: true})}
            isTooltipActive={isTooltipActive}
            searchParams={{ category: categoryId, page, search, sort }}
            backUrl={`${location.pathname}${location.search}`}
            onVisible={() => dispatch(fetchRecommendedProducts(product.productId))}
          />
        ))}
        <Pagination
          page={page - 1}
          pageSize={ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE}
          productCount={count}
        />
      </div>
      <Footer />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    height: "calc(100vh - 65px)",
    overflow: "hidden",
    overflowY: "scroll",
  },
  sticky: {
    zIndex: 1000,
    boxShadow: '0 10px 8px 0 rgba(0, 0, 0, 0.08)',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px 35px 20px 0',
    backgroundColor: '#ffffff',
    position: 'relative',
  },
  searchRoot: {
    width: '270px',
    marginLeft: '22px',
  },
  searchInput: {
    marginLeft: 8,
    flex: 1,
  },
  categoryRoot: {
    width: '200px',
    marginLeft: '75px',
    marginRight: '35px',
  },
  switchRoot: {},
  categorySelect: {
    '&:before': {
      borderBottom: 0,
    },
  },
  sortRoot: {
    width: '285px',
    display: 'flex',
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
});
