import EventEmitter from './EventEmitter';
import { handleEvent } from '@yesplz/visualfilter/src/modules/analytics';

const _ANALYTICS_EVENTS_TO_SUBSCRIBE = [
  "txtSearchBarOpened",
  "txtSearchBarClosed",
  "txtSearchBarGenderChanged",
  "txtSearchBarPopularSearchesClick",
  "txtSearchBarSuggestionClick",
  "txtSearchBarBrandClick",
  "txtSearchBarSaleOnlyOn",
  "txtSearchBarSaleOnlyOff",
  "txtSearchBarFormSubmit",
  "txtSearchBarSeeAllClick",
  { event: "txtSearchBarQuery", labelProperty: (query) => query },
  "txtSearchBarProductClick",
  "txtSidebarCategoryChanged",
  "txtSidebarSaleApplied",
  "txtSidebarSaleRemoved",
  "txtSidebarClearAllClick",
  "txtSidebarBrandApplied",
  "txtSidebarBrandRemoved",
  "txtSidebarColorApplied",
  "txtSidebarColorRemoved",
  "txtSidebarMaterialsApplied",
  "txtSidebarMaterialsRemoved",
  "txtSidebarSizesApplied",
  "txtSidebarSizesRemoved",
  "txtSidebarDiscountApplied",
  "txtSidebarDiscountRemoved",
  "txtSidebarShippingApplied",
  "txtSidebarShippingRemoved",
  "txtProductListItemClicked",
  "txtPaginationPageChanged",
  "txtSortChanged",
  "txtPageSizeChanged",
  "txtSearchBarAIStylistClick",
  "txtSearchBarModeChange",
  "txtSidebarPatternsApplied",
  "txtSidebarPatternsRemoved",
  { event: "txtSidebarBodyPartApplied", labelProperty: (res) => `${res.bodyPart}: ${res.style}` },
  { event: "txtSidebarBodyPartRemoved", labelProperty: (res) => `${res.bodyPart}: ${res.style}` },
  "txtSidebarOccasionApplied",
  "txtSidebarOccasionRemoved",
  "txtSidebarVibesApplied",
  "txtSidebarVibesRemoved"
];

export const ANALYTICS_EVENTS_TO_SUBSCRIBE =
  _ANALYTICS_EVENTS_TO_SUBSCRIBE.concat(
    _ANALYTICS_EVENTS_TO_SUBSCRIBE
      .filter((action) => (action?.event || action).startsWith("txtSidebar"))
      .map((action) => {
        if (action.event) {
          return {
            ...action,
            event: action.event.replace("txtSidebar", "AISearchSidebar"),
          };
        }
        return action.replace("txtSidebar", "AISearchSidebar");
      })
  );

ANALYTICS_EVENTS_TO_SUBSCRIBE.map((action) => {
  EventEmitter.on(typeof action === "string" ? action : action.event, (label) =>
    handleEvent(action, label)
  );
});
