import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import store from './config/store'
import history from '@yesplz/core-web/config/history'
import routes from './config/routes'
import ScrollToTop from './ScrollToTop'

// import global styles
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@yesplz/core-web/assets/css/animations.css'
import '@yesplz/core-web/assets/css/reset.css'
import 'assets/css/overrides.css'
import './app.scss'
import UserProvider from './components/UserProvider'

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BASE_API_PATH}/api/graphql`,
  cache: new InMemoryCache(),
});

const App = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <UserProvider>
        <ConnectedRouter
          history={history}
          basename={process.env.PUBLIC_URL}
        >
          <ScrollToTop>{routes}</ScrollToTop>
        </ConnectedRouter>
      </UserProvider>
    </Provider>
  </ApolloProvider>
)

export default App
