import { categories as baseCategories } from '@yesplz/visualfilter/src/extended-configuration';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import buildTree from './buildTree';

export function fillCategoriesByParent(configuration) {
  const updatedConfiguration = cloneDeep(configuration);
  const tree = buildTree(Object.values(updatedConfiguration));
  function fillChildren(items, parentId) {
    items.forEach((item) => {
      const category = cloneDeep(updatedConfiguration[item.categoryId]);

      if (!category.category && category.baseCategory) {
        category.category = baseCategories[category.baseCategory].category;
      }

      updatedConfiguration[item.categoryId] = {
        ...(parentId && updatedConfiguration[parentId] ? cloneDeep(updatedConfiguration[parentId]) : {}),
        ...category,
      };

      if (item.children) {
        fillChildren(item.children, item.categoryId);
      }
    });
  }
  fillChildren(tree);

  return updatedConfiguration;
}

const alwaysOwnParams = ['categoryId', 'label', 'parentId', 'sorting', 'isPublished'];
export function cleanChildrenByParents(configuration) {
  const updatedConfiguration = cloneDeep(configuration);
  const tree = buildTree(Object.values(updatedConfiguration));

  function cleanChildren(items, generatedParentConfiguration = {}) {
    items.forEach((item) => {
      const category = updatedConfiguration[item.categoryId];

      Object.keys(category).forEach((key) => {
        if (alwaysOwnParams.includes(key)) {
          return;
        }

        if (isEqual(generatedParentConfiguration[key], category[key]) || !category[key]) {
          delete category[key];
        }
      });

      updatedConfiguration[item.categoryId] = category;

      if (item.children) {
        cleanChildren(item.children, {
          ...generatedParentConfiguration,
          ...category,
        });
      }
    });
  }
  cleanChildren(tree);

  return updatedConfiguration;
}