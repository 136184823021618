import { createAction } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import client from '@yesplz/client';
import EventEmitter from '../../modules/EventEmitter';
import Hooks from '../../modules/Hooks';

const prefix = '@search/';

export const searchResultsPending = createAction(`${prefix}SEARCH_RESULTS_PENDING`);
export const searchResultsSuccess = createAction(`${prefix}SEARCH_RESULTS_SUCCESS`);
export const searchResultsError = createAction(`${prefix}SEARCH_RESULTS_ERROR`);

function applyTnValues(category, params) {
  const updatedParams = { ...params };

  if (!category.tn) return updatedParams;

  for (const tnName in params) {
    if (Object.hasOwnProperty.call(category.tn, tnName)) {
      const tn = category.tn[tnName];
      if (
        Object.hasOwnProperty.call(tn, params[tnName])
        &&
        Object.hasOwnProperty.call(tn[params[tnName]], 'value')
      ) {
        updatedParams[tnName] = tn[params[tnName]].value;
      }
    }
  }

  return updatedParams;
}

export const runSearch = (categories) => async (dispatch, getState) => {
  let { filter, config } = getState();

  if (!filter.categoryId) return;

  dispatch(searchResultsPending());
  const category = categories[filter.categoryId];

  try {
    if (!category) return;

    const params = {
      ...applyTnValues(category, filter.params),
      ...(category.additionalParams ? category.additionalParams : {}),
      editorspick: filter.presetIndex,
    };

    EventEmitter.emit('searchStarted', Hooks.call('events.searchStarted', {
      category: filter.category,
      categoryId: filter.categoryId,
      categorySlice: filter.categorySlice,
      sort: filter.sort,
      limit: filter.limit,
      offset: filter.offset,
      presetIndex: filter.presetIndex,
      occasion: filter.occasion,
      mood: filter.mood,
      params,
      additionalParams: config.searchAdditionalParams,
      bodyPart: filter.bodyPart,
    }));

    if (!config.useSearch) return; // TODO: Revise position. Changed because Favorite page on playground. Error When there are more then 1 saved

    // Check for Grouped values
    Object.keys(category.tn || {}).forEach(bodyPart => {
      const value = params[bodyPart];
      if (category.tn[bodyPart]?.[value]?.groupedWith && category.tn[bodyPart]?.[value]?.groupedWith?.length) {
        params[bodyPart] = [...category.tn[bodyPart][value].groupedWith, value];
      }
    });

    const filterBeforeClientSearch = filter;

    const searchArguments = Hooks.call('beforeClientSearch', [
      filter.category,
      category.omitCategoryIdOnSearch ? null : filter.categoryId,
      {
        ...params,
        lang: config.lng,
      },
      filter.sort,
      filter.offset,
      filter.limit,
      config.searchAdditionalParams,
      config.requestConfig,
      filter.occasion,
      filter.mood,
      filter.categorySlice,
    ]);

    let eventEmitted = false;

    function emitSearchFinishedOnce(data) {
      if (eventEmitted) return;
      eventEmitted = true;

      EventEmitter.emit(
        'searchFinished',
        {
          count: data.counts && data.counts.total,
          counts: data.counts,
          offset: parseInt(filter.offset, 10),
          limit: parseInt(filter.limit, 10),
          results: (
            config.searchFinishedReturnFields
            ? data.results.map(p => ({
                ...(pick(p.product, config.searchFinishedReturnFields)),
                ...(
                  config.searchFinishedReturnFields.includes('score')
                    ? { score: p.score }
                    : {}
                ),
              }))
            : data.results
          ),
        }
      );
    }

    function updateState(data) {
      const state = getState();

      const filterAfterClientSearch = state.filter;

      // If filter changed, do not update the results
      if (!isEqual(filterBeforeClientSearch, filterAfterClientSearch)) {
        return;
      }

      emitSearchFinishedOnce(data);

      dispatch(searchResultsSuccess({
        ...data,
        prevResults: state.search.results,
        config: state.config,
      }));
    }

    client.search(...searchArguments)
      .then(data => {
        updateState(data);
      });

    client.search(...searchArguments, true)
      .then(data => {
        updateState(data);
      });
  }
  catch(error) {
    console.log('Search failed');
    console.log(error);
    dispatch(searchResultsError({ error: error.message }));
  }
}
