import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p>
        Feeling stuck or any trouble? Call us at 1-408-499-5924<br />
        or email us at hello@yesplz.us
      </p>
      <p>Happy to assist you!</p>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    marginBottom: 30,

    '& p': {
      marginBottom: 15,
    }
  },
});
