import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isMatchWith from 'lodash/isMatchWith';
import cloneDeep from 'lodash/cloneDeep';

export default function isParamsMatchPreset(baseParams, preset) {
  const params = cloneDeep(baseParams);

  // delete params.subtype;

  const strippedPreset = Object.entries(preset).reduce((strippedPreset, [param, value]) => {
    if (value && value !== 'all' && !isEmpty(value)) {
      strippedPreset[param] = value;
    }
    return strippedPreset;
  }, {});

  const matched = isMatchWith(params, strippedPreset, (o, s) => {
    if (Array.isArray(o) && Array.isArray(s) && !isEqual(o, s)) {
      return false;
    }
  });

  console.log('isMatch', matched, params, strippedPreset);

  return matched;
}
