import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import RandomIcon from '@material-ui/icons/ThreeSixty';
import { Formik, Form } from 'formik';

export default ({ search: baseSearch = '', onSearch, onRandomClick }) => {
  const classes = useStyles();
  const [search, setSearch] = useState(baseSearch);

  useEffect(() => {
    setSearch(baseSearch);
  }, [baseSearch]);

  const handleSubmit = ({ search }, { setSubmitting }) => {
    onSearch(search.trim());
    setSubmitting(false);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{ search }}
      onSubmit={handleSubmit}
    >
      {({ values, submitForm, setFieldValue }) => {
        return (
          <Form>
            <div className={classes.root}>
              <div>ID:</div>
              <InputBase
                className={classes.searchInput}
                placeholder='Search ID'
                value={values.search || ''}
                onInput={(e) => setFieldValue('search', e.target.value)}
              />
              <IconButton
                className={classes.iconButton}
                aria-label='Search'
                onClick={submitForm}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                className={classes.iconButton}
                aria-label='Random'
                onClick={onRandomClick}
              >
                <RandomIcon />
              </IconButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
});
