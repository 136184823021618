const subscribers = {};

export default {
  emit(event, ...args) {
    if (subscribers.hasOwnProperty(event)) {
      subscribers[event].forEach(subscriber => {
        subscriber(...args);
      });
    }
  },
  on(event, subscriber) {
    if (!subscribers.hasOwnProperty(event)) {
      subscribers[event] = [];
    }
    subscribers[event].push(subscriber);
  },
  off(event, subscriber) {
    if (!subscribers.hasOwnProperty(event)) {
      subscribers[event] = [];
    }
    subscribers[event] = subscribers[event].filter(attached => attached !== subscriber);
  },
};
