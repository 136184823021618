import React from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import qs from 'qs';
import Icon from '@material-ui/core/SvgIcon';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

export default function Pagination({ page, productCount, pageSize = 20, visiblePagesLimit = 5 }) {
  const classes = useStyles();
  const match = useRouteMatch();
  const location = useLocation();

  if (!productCount) return null;

  const pageCount = Math.ceil(productCount / pageSize);

  const pagination = [];
  let startPage = page - Math.floor(visiblePagesLimit / 2);
  let endPage = page + Math.floor(visiblePagesLimit / 2);

  if (pageCount < visiblePagesLimit) {
    startPage = 0;
    endPage = pageCount - 1;
  }
  else if (endPage >= pageCount) {
    endPage = pageCount - 1;
    startPage = endPage - visiblePagesLimit + 1;
  }
  else if (startPage < 0) {
    startPage = 0
    endPage = visiblePagesLimit - 1;
  }

  for (let p = startPage; p <= endPage; p++) {
    pagination.push(p);
  }

  let searchParams = location.search ? qs.parse(location.search.slice(1)) : {};
  searchParams.page = '{{page}}';
  let urlTemplateString = match.url + '?' + qs.stringify(searchParams, { encode: false });

  function getFullUrl(page) {
    return urlTemplateString.replace('{{page}}', page);
  }

  return (
    <div className={classes.root}>
      {page === pageCount - 1 && <h3>END OF SEARCH RESULTS</h3>}
      {pageCount > 1 && <ul>
        {
          page > 0
          &&
          <>
            <li>
              <Link to={getFullUrl(0 + 1)}>
                First
              </Link>
            </li>
            <li>
              <Link to={getFullUrl(page)}>
                <Icon><ArrowLeft /></Icon>
              </Link>
            </li>
          </>
        }
        {pagination.map((p) => (
          <li key={p}>
            <Link className={classnames('number', { 'current-page': p === page, })} to={getFullUrl(p + 1)}>{p + 1}</Link>
          </li>
        ))}
        {
          page !== pageCount - 1
          &&
          <>
            <li>
              <Link to={getFullUrl(page + 2)}>
                <Icon><ArrowRight /></Icon>
              </Link>
            </li>
            <li>
              <Link to={getFullUrl(pageCount)}>
                Last
              </Link>
            </li>
          </>
        }
      </ul>}
    </div>
  )
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  productCount: PropTypes.number,
  visiblePagesLimit: PropTypes.number,
};

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    padding: '25px 0 45px',

    '& ul li': {
      position: 'relative',
      display: 'inline-block',

      '& a': {
        display: 'inline-block',
        textDecoration: 'none',
        color: '#009688',
        padding: '7px 0',
        margin: '0 2px',
        width: '35px',
        height: '35px',
        textAlign: 'center',
        verticalAlign: 'middle',
        fontSize: '14px',

        '&.number': {
          color: grey[700],
        },

        '&.current-page': {
          color: 'white !important',
          backgroundColor: '#009688',
          borderRadius: '50%',
          '&:hover': {}
        },

        '&:hover': {
          textDecoration: 'underline',
          color: '#009688',
        },

        '& svg': {
          display: 'inline',
        },
      },
    }
  },
});
