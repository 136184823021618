import React, { useRef, useState } from 'react';
import axios from 'axios';
import VisibilitySensor from 'react-visibility-sensor';
import Slider from "react-slick";
import { DotLoader } from '@yesplz/core-web/ui-kits/loaders';
import ProductGrid from './ProductGrid';

import AngleLeft from '@yesplz/core-web/assets/svg/angle-left.svg'
import AngleRight from '@yesplz/core-web/assets/svg/angle-right.svg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ProductRow.scss';

const ITEM_WIDTH = 274;

const NextArrow = ({ className, style, onClick }) => (
  <button type="button" data-role="none" className={className} style={style} onClick={onClick}>
    <img src={AngleRight} alt='Next' />
  </button>
);

const PrevArrow = ({ className, style, onClick }) => (
  <button type="button" data-role="none" className={className} style={style} onClick={onClick}>
    <img src={AngleLeft} alt='Previous' />
  </button>
);

export default ({ product, shop, isTooltipActive }) => {
  const [productFull, setProductFull] = useState(null);
  const [recommends, setRecommends] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const recommendedContainerRef = useRef();

  async function fetchRecommendedProducts(product_id) {
    setErrorMessage(null);
    setLoading(true);
    try {
      const { data } = await axios.get('/yml.json', {
        params: {
          shop,
          product_id,
          variant_id: null,
          admin: 1,
        },
      });
      setProductFull(data.product);
      setRecommends(data.recommends)
    }
    catch (error) {
      setErrorMessage(error.response.data.error || null);
    }
    setLoading(false);
  }

  const handleVisibilityChange = (isVisible) => {
    if (isVisible && !productFull) {
      fetchRecommendedProducts(product.productId);
    }
  }

  const slidesToShow = (
    recommendedContainerRef.current
      ? Math.floor(recommendedContainerRef.current.offsetWidth / ITEM_WIDTH)
      : null
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    centerPadding: '200px',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const width = slidesToShow * ITEM_WIDTH;

  return (
    <VisibilitySensor
      partialVisibility
      onChange={handleVisibilityChange}
    >
      <div className="ProductRow">
        <div className="ProductRow-product">
          <ProductGrid
            product={{ product: productFull ? productFull : product }}
            shop={shop}
          />
        </div>
        <div className="ProductRow-recommended" ref={recommendedContainerRef}>
          {errorMessage && <p style={styles.error}>Error: {errorMessage}</p>}
          {loading && <DotLoader visible style={styles.loader} />}
          {
            recommends
            &&
            <div
              className="ProductRow-recommended-container"
              style={{ width: `${width}px` }}
            >
              <Slider {...settings}>
                {recommends.map((product, index) => (
                  <ProductGrid
                    key={product.product.productId + index}
                    shop={shop}
                    // searchParams={searchParams}
                    isTooltipActive={isTooltipActive}
                    // backUrl={backUrl}
                    product={product}
                  />
                ))}
              </Slider>
            </div>
          }
        </div>
      </div>
    </VisibilitySensor>
  );
}

const styles = {
  error: {
    color: 'red',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    width: '100%'
  },
};
