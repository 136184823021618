/**
 * VisualFilter
 * contains functions to handle visual filter svg interactions
 */
import { SVG } from '@svgdotjs/svg.js';
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import find from 'lodash/find';
import difference from 'lodash/difference';
import { findLabelByLng } from '@yesplz/core';

import Tracker from './Tracker'
import { getCatData } from './VFCatViewData'

const LAST_BODY_PART = 'last_body_part'
const FILTERS = 'filters'
const PRD_CATEGORY = 'wtop'

const { localStorage } = window

export default class VisualFilter {
  selectedBodyPart = null
  svgLoaded = false
  lastBodyPart = null
  catdata = null

  constructor (selector = '#svg', options = {}) {
    this.settings = {
      ...defaultOptions,
      ...options
    }

    // console.log(this.settings.categoryCfg);
    this.catdata = getCatData(this.settings.categoryCfg) // VfCat*ViewData

    if (typeof SVG === 'undefined') // Skip init to avoid error on test env.
      return false;

    this.svg = SVG(selector)

    if (options.defaultState) {
      this.catdata.setDefaultState(options.defaultState)
    }

    this.disabledParts = [];

    this.initialize()

    this.predictions = null;
  }

  trackBodypart (event, prop) {
    Tracker.track(event, { 'category': this.settings.category, 'bodypart': prop })
  }

  track (event) {
    Tracker.track(event, { 'category': this.settings.category })
  }

  setLastBodyPart (lastBodyPart) {
    if (!isEmpty(lastBodyPart) && this.lastBodyPart !== lastBodyPart) {
      this.lastBodyPart = lastBodyPart
    }
  }

  setPointerHovering () {
    let propList = this.catdata.propList()
    propList.forEach(bodyPart => {
      const handleMouseOver = () => this.handleBodyPartMouseOver(bodyPart);
      const handleMouseOut  = () => this.handleBodyPartMouseOut(bodyPart);
      const hitArea = this.findGroupById(this.catdata.touchGroupName(bodyPart));
      hitArea.mouseover(handleMouseOver);
      hitArea.mouseout(handleMouseOut);
      const rootTagText = this.findGroupById(`tag-text-${bodyPart}`);
      rootTagText.mouseover(handleMouseOver);
      rootTagText.mouseout(handleMouseOut);
      const rootTagLine = this.findGroupById(`tag-line-${bodyPart}`);
      rootTagLine.mouseover(handleMouseOver);
      rootTagLine.mouseout(handleMouseOut);
    });
  }

  handleBodyPartMouseOver(bodyPart) {
    if (this.isBodyPartDisabled(bodyPart)) return;

    this.highlightGroup(bodyPart, false, '.5', 'hover')
    this.highlightPropTag(bodyPart)
  }

  handleBodyPartMouseOut(bodyPart) {
    if (this.isBodyPartDisabled(bodyPart)) return;

    const { showHighlightOnBuild } = this.settings;

    this.removeHighlight(this.lastBodyPart, 'hover')
    this.unhighlightPropTag(bodyPart)
    this.resetPoint(bodyPart, 'hover')
    if (showHighlightOnBuild) {
      this.highlightGroup(this.lastBodyPart, false, '.5', 'unhover')
    }
  }

  setViewBox(viewBox) {
    if (!this.svg) return;

    this.viewBox = viewBox
    this.catdata.setViewBox(this.viewBox)
    this.svg.attr({ viewBox: this.viewBox })
  }

  setScale(scale) {
    if (this.svgGroup)
      this.svgGroup.attr({ transform: `scale(${scale})` });
  }

  setPredictions(predictions) {
    this.predictions = predictions;
  }

  loadSVG(url, callback) {
    fetch(url)
      .then(response => response.text())
      .then(svgText => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
        const svgElement = svgDoc.documentElement;
        const fragment = document.createDocumentFragment();
        fragment.appendChild(svgElement.cloneNode(true));
        callback(SVG(fragment));
      })
      .catch(error => console.error('Error:', error));
  }

  initialize () {
    const {
      onSVGLoaded, defaultBodyPart, badgeMode, showHighlightOnBuild, customViewBox,
      hideArrow
    } = this.settings
    const partList = this.catdata.propList();
    if (!partList.includes(defaultBodyPart)) {
      console.log('Unrecognized last bodypart', this.lastBodyPart, 'switching to', partList[0]);
      this.lastBodyPart = partList[0];
    } else {
      this.lastBodyPart = defaultBodyPart;
    }

    let svgSource = this.catdata.svgSource;

    this.setViewBox(customViewBox || this.catdata.viewBox());

    this.loadSVG(svgSource.default || svgSource, (loadedFrag) => {
      if (!this.svg) return;

      const frag = this.catdata.catcfg.svgUpdate
        ? SVG(this.catdata.catcfg.svgUpdate)
        : loadedFrag;
      this.svgLoaded = true
      this.svgGroup = this.svg.group()
      frag.node.querySelectorAll('svg > *')
        .forEach(el => this.svgGroup.add(el))
      // Hide all object and show what we want only later
      this.svgGroup.attr({ visibility: 'hidden' })

      this.svgGroup.find('mask').forEach(mask => mask.attr({ visibility: 'visible' }));

      if (this.settings.svgScale) {
        this.svgGroup.scale(this.settings.svgScale)
      }

      for (const grp of this.catdata.fullbodyGroupNames()) {
        this.showGroup(grp)
      }

      for (const part of this.catdata.parts) {
        const { groupName, extraProps } = this.catdata.getBodyPartGroupName(part.name);
        this.showGroup(groupName, extraProps);

        if (part.isPublished) {
          this.updatePropTags(part.name, this.catdata.currentPropState);
          this.unhighlightPropTag(part.name);

          if (!badgeMode) {
            const group = this.showGroup(`${part.name}_point`);
            if (part.className) {
              group.addClass(part.className);
            }
          }
        }
      }
      this.handleOnboardingFinished();

      if (!badgeMode) {
        this.track('VF Opened')

        if (!hideArrow) {
          this.initializeArrowNavigation()
        }

        this.initializeClickHitMap()
        this.setPointerHovering()
        this.evaluateDisabledParts()
      }

      if (showHighlightOnBuild) {
        this.highlightGroup(this.lastBodyPart,false, '.5', 'select')
      }

      if (this.settings.onFilterChange) { // Trigger initial update
        this.settings.onFilterChange(this.catdata.currentPropState)
      }

      // callback
      onSVGLoaded();
    })
  }

  initializeClickHitMap () {
    let group = null
    var i
    // This will be touch hit-area
    for (const part of this.catdata.parts) {
      if (!part.isPublished) continue;

      const prop = part.name;
      group = this.findGroupById(this.catdata.touchGroupName(prop))

      if (group === null) {
        console.log('Touch area for', prop, 'not found')
        continue
      }

      // Just make it not-visible. We still need it for hit-map
      group.attr({ visibility: 'visible' })
      group.attr({ opacity: this.settings.debugTouchArea ? 0.5 : 0.0 })

      if (!this.settings.badgeMode) {
        const handleClick = () => this.handleBodyPartClick(prop);
        group.click(handleClick);
        const rootTagText = this.findGroupById(`tag-text-${prop}`);
        rootTagText.click(handleClick);
        const rootTagLine = this.findGroupById(`tag-line-${prop}`);
        rootTagLine.click(handleClick);
      }
    }
  }

  moveToNextPreset (backward = false) {
    this.updateState(this.catdata.nextPreset(backward))
    this.settings.onFilterChange(this.catdata.currentPropState)
  }

  initializeArrowNavigation () {
    // Initialize preset arrow
    const presetBack = this.findGroupById('preset_back')
    const presetForward = this.findGroupById('preset_forward')
    this.showGroup('preset_back')
    this.showGroup('preset_forward')
    presetBack.click(() => {
      this.moveToNextPreset(true)
    })
    presetForward.click(() => {
      this.moveToNextPreset()
    })
  }

  updateState (filters) {
    if (!this.svgLoaded) {
      return
    }

    const newPropState = this.catdata.sanitizeFilters(filters)
    // only update when svg is loaded and has changes on filters
    if (!isEqual(this.catdata.currentPropState, newPropState)) {
      // body part visibility handler
      for (var i in this.catdata.propList()) {
        const prop = this.catdata.propList()[i]

        // hide previous bodypart
        const { groupName: hideGroupName, extraProps: hideExtraProps } = this.catdata.getBodyPartGroupName(prop);
        this.hideGroup(hideGroupName, hideExtraProps)
        // show next bodypart
        const { groupName: showGroupName, extraProps: showExtraProps } = this.catdata.getBodyPartGroupName(prop, newPropState);
        this.showGroup(showGroupName, showExtraProps)

        this.updatePropTags(prop, newPropState);
      }
      // update current prop state after body part visibility handler done
      this.catdata.currentPropState = newPropState

      this.disabledParts.forEach(bodyPart => this.resetPoint(bodyPart))
      this.evaluateDisabledParts()
    }
  }

  handleOnboardingFinished (isupdate = false) {
    const { onFinishedOnboarding, onboarding } = this.settings
    if (isupdate || !onboarding) {
      if (onFinishedOnboarding) {
        onFinishedOnboarding()
      }
      this.track('MiniOnboarding Completed')
    }
    if (isupdate) {
      VisualFilter.saveConfig('onboarding_completed', 1)
    }
  }

  isBodyPartDisabled(bodyPart) {
    return !isEmpty(this.disabledParts) && this.disabledParts.includes(bodyPart);
  }

  handleBodyPartClick (bodyPart) {
    if (this.isBodyPartDisabled(bodyPart)) return;

    if (typeof this.settings.onBodyPartClick === 'function') {
      this.settings.onBodyPartClick(bodyPart);
    }

    const { showHighlightOnBuild, rotateValueOnFirstClick } = this.settings;

    if (rotateValueOnFirstClick || this.lastBodyPart === bodyPart) {
      this.cyclePropSelection(bodyPart)
    }

    this.trackBodypart('VF Touch BodyPart', bodyPart)

    this.disabledParts.forEach(bodyPart => this.resetPoint(bodyPart))
    // set last body part when its changed
    this.removeHighlight(this.lastBodyPart, 'unselect')
    this.evaluateDisabledParts()
    this.settings.onPropChange(bodyPart)

    if (showHighlightOnBuild) {
      this.highlightGroup(bodyPart,false, '.5', 'select')
    }

    this.lastBodyPart = bodyPart
  }

  checkPredictionAvailability (bodyPart, bodyPartValue) {
    if (!this.predictions?.[bodyPart]) return true;

    return !!(this.predictions?.[bodyPart]?.[bodyPartValue]?.isActive);
  }
  
  getNextValue(prop, currentValue) {
    const disabledValues = this.catdata.evaluateDisabledValues(prop, this.catdata.currentPropState);
    const bodyPartProps = this.catdata.catcfg.tn[prop];
    const bodyPartPropsKeys = difference(Object.keys(bodyPartProps), disabledValues);

    let lastValue = currentValue;
    if (Array.isArray(lastValue)) {
      bodyPartPropsKeys.forEach(value => {
        if (this.catdata.currentPropState[prop].includes(value)) {
          lastValue = value;
        }
      })
    }

    let nextKeyIndex = bodyPartPropsKeys.indexOf(lastValue) + 1;
    if (nextKeyIndex >= bodyPartPropsKeys.length) {
      nextKeyIndex = 0;
    }

    const newValue = bodyPartPropsKeys[nextKeyIndex];
    if (newValue !== 'all' && !this.checkPredictionAvailability(prop, newValue)) {
      return this.getNextValue(prop, newValue);
    }

    return newValue;
  }

  cyclePropSelection (prop) {
    this.changePropSelection(prop, this.getNextValue(prop, this.catdata.currentPropState[prop]));
  }

  updatePropSelectionViewState (prevPropState, newPropState) {
    for (var i in this.catdata.propList()) {
      const prop = this.catdata.propList()[i]
      var { groupName: hideGroupName, extraProps: hideExtraProps } = this.catdata.getBodyPartGroupName(prop, prevPropState);
      var { groupName: showGroupName, extraProps: showExtraProps } = this.catdata.getBodyPartGroupName(prop, newPropState);
      this.hideGroup(hideGroupName, hideExtraProps)
      this.showGroup(showGroupName, showExtraProps)

      this.updatePropTags(prop, newPropState);
    }
  }

  highlightPropTag(prop) {
    if (this.catdata.disabledParts.includes(prop)) return;

    let tag_line_grp = 'tag-line-' + prop;
    let tag_text_grp = 'tag-text-' + prop;

    this.showGroup(tag_text_grp, {"fill": this.settings.tagHighlightColor}).addClass("vf-highlighted");
    this.showGroup(tag_line_grp, {"stroke": this.settings.tagHighlightColor}).addClass("vf-highlighted");
  }

  unhighlightPropTag(prop) {
    if (this.catdata.disabledParts.includes(prop)) return;

    let tag_line_grp = 'tag-line-' + prop;
    let tag_text_grp = 'tag-text-' + prop;

    this.showGroup(tag_text_grp, {"fill": this.settings.tagDefaultColor})?.removeClass("vf-highlighted");
    this.showGroup(tag_line_grp, {"stroke": this.settings.tagDefaultColor})?.removeClass("vf-highlighted");
  }

  updatePropTags(prop, propState) {
    // Handle Selected-choice Tag
    let tag_line_grp = 'tag-line-' + prop
    let tag_text_grp = 'tag-text-' + prop
    let tag_text = document.getElementById(tag_text_grp)
    if (!tag_text) {
      return // This SVG file doesn't have tag. Skip this function
    }

    const part = find(this.catdata.parts, { name: prop });

    if (!part.isPublished) return;

    let tspan = tag_text.firstElementChild;
    tspan.innerHTML = findLabelByLng(part.label, this.settings.lng).toUpperCase();

    // if (propState[prop] === 'all') {
    //   let tspan = tag_text.firstElementChild
    //   tspan.innerHTML = this.catdata.catcfg.partListLabels[this.catdata.catcfg.partList.indexOf(prop)].toUpperCase()
    // } else {
    //   let tn = this.catdata.catcfg.tn[prop]
    //   if (tn && tn[propState[prop]]) { // Check matching thumbnail exist
    //     let tspan = tag_text.firstElementChild
    //     tspan.innerHTML = tn[propState[prop]]['label']
    //   }
    // }

    const textGroup = this.showGroup(tag_text_grp);
    this.showGroup(tag_line_grp);

    if (part.className) {
      textGroup.addClass(part.className);
    }
  }

  changePropSelection (prop, sel, requestChange = true) {
    let curState = this.catdata.currentPropState
    var prevPropState = Object.assign({}, curState)
    this.catdata.changePropSelection(prop, sel)
    this.updatePropSelectionViewState(prevPropState, curState)
    if (requestChange) {
      this.settings.onFilterChange(curState, true)
    }
  }

    /**
   * save last body part to localStorage
   * @param {string} prop
   */
  static saveLastBodyPart (prop) {
    localStorage.setItem(LAST_BODY_PART, prop)
  }

  /**
   * get last body part to localStorage
   * @returns {string} last body part (prop)
   */
  static getLastBodyPart () {
    let prop = localStorage.getItem(LAST_BODY_PART)
    if (prop === 'collar') { // collar is deprecated
      return 'neckline'
    } else {
      return prop
    }
  }

  /**
   * remove last body part from localStorage
   */
  static removeLastBodyPart () {
    localStorage.removeItem(LAST_BODY_PART)
  }

  evaluateDisabledParts() {
    this.disabledParts = this.catdata.evaluateDisabledParts();
    this.disabledParts.forEach(bodyPart => {
      this.disablePoint(bodyPart)
    })
  }

  /**
   * Set svg group to visible
   * @param {string} id
   * @param {Object} extraProps
   */
  showGroup (id, extraProps = {}) {
    const group = this.findGroupById(id);
    if (!group) return;

    group.attr({ visibility: 'visible', ...extraProps })

    if (extraProps.className) group.addClass(extraProps.className);

    return group
  }

  /**
   * Set svg group to invisible
   * @param {string} id
   * @param {Object} extraProps
   */
  hideGroup (id, extraProps) {
    const group = this.findGroupById(id)
    if (!group) return;

    if (extraProps.className) group.removeClass(extraProps.className);

    group.attr({ visibility: 'hidden', ...extraProps })
  }

  disablePoint (bodyPart) {
    const points = this.findTouchPoints(bodyPart)
    const disabled = points.node.parentNode.querySelector('[data-type="disabled"]');
    disabled.style.opacity = 1;
    const base = points.node.parentNode.querySelector('[data-type="base"]');
    base.style.opacity = 0;
    const hoverGlow = points.node.parentNode.querySelector('[data-type="hover"]');
    hoverGlow.style.opacity = 0;
    const selectedGlow = points.node.parentNode.querySelector('[data-type="selected"]');
    selectedGlow.style.opacity = 0;
  }

  resetPoint (lastBodyPart, type) {
    const points = this.findTouchPoints(lastBodyPart)
    const disabled = points.node.parentNode.querySelector('[data-type="disabled"]');
    disabled.style.opacity = 0;
    const base = points.node.parentNode.querySelector('[data-type="base"]');
    base.style.opacity = 1;
    const hoverGlow = points.node.parentNode.querySelector('[data-type="hover"]');
    hoverGlow.style.opacity = 0;
    if (type === 'unselect') {
      const selectedGlow = points.node.parentNode.querySelector('[data-type="selected"]');
      points.node.parentNode.classList.remove('touch-point-selected');
      selectedGlow.style.opacity = 0;
    }
  }

  /**
   * Highlight svg group
   * @param {string} id
   */
  highlightGroup (bodyPart, fadeout = true, opacity = '1', type) {
    const points = this.findTouchPoints(bodyPart)

    const base = points.node.parentNode.querySelector('[data-type="base"]');
    const hoverGlow = points.node.parentNode.querySelector('[data-type="hover"]');
    points.node.parentNode.classList.add('touch-point-selected');
    const selectedGlow = points.node.parentNode.querySelector('[data-type="selected"]');
    if (type === 'hover') {
      hoverGlow.style.opacity = 1;
      base.style.opacity = 0;
    } else if (type === 'unhover') {
      hoverGlow.style.opacity = 0;
      base.style.opacity = 1;
    } else if (type === 'select') {
      base.style.opacity = 0;
      selectedGlow.style.opacity = 1;
    } else {
      console.log('Unexpected type', type)
    }

    // points.attr({
    //   fill: '#582ADF'
    // })
  }

  removeHighlight (prop, type) {
    const { showHighlightOnBuild } = this.settings
    if (showHighlightOnBuild) {
      this.resetPoint(prop, type)
    }
  }

  /**
   * Find svg group based on specific id
   * @param {*} id
   * @return {Object} svg group
   */
  findGroupById (id) {
    const group = this.svg.findOne('#' + id.replace(',', '\\,'))
    if (group === null) {
      if (!id.includes('_HL')) { // Currently most SVG are missing HL(highlight) images. Don't show this warning for them
        console.log('Missing group', id, 'in', this.settings.category)
      }
      // Avoid script crashing
      return this.svg.findOne('#no_display_group')
    }
    return group
  }

  findTouchPoints (lastBodyPart) {
    const grpName = this.catdata.touchpointGroupName(lastBodyPart)
    if (grpName) {
      const group = this.svg.findOne('#' + grpName + ' [data-type="base"]')
      if (group) {
        return group
      }
    }
    console.debug('Missing touch-point group', lastBodyPart, grpName)
    // Avoid script crashing
    return this.svg.findOne('#no_display_group')
  }

  /**
   * Check wheter onboarding screen needs to be loaded
   */
  static shouldShowOnboarding () {
    return !VisualFilter.loadConfig('onboarding_completed')
  }

  /**
   * Save config value from localstorage
   * @param {string} name
   * @param {string} val
   */
  static saveConfig (name, val) {
    try {
      localStorage.setItem(name, val)
    } catch (err) {
      console.debug('error saving config', err)
    }
  }

  /**
   * Load config value from localstorage
   * @param {string} name
   * @returns {(string|null)} config value
   */
  static loadConfig (name) {
    try {
      return localStorage.getItem(name)
    } catch (err) {
      console.debug('error loading config', err)
    }
    return null
  }

  /**
   * get current filters from local storage
   */
  static getFilters () {
    const filters = JSON.parse(localStorage.getItem(FILTERS))
    return filters || {}
  }
}

const defaultOptions = {
  category: PRD_CATEGORY, // Should be changeable runtime
  badgeMode: false, // Disable interaction and do not show thumbnails/bottom menus
  defaultState: {},
  debugTouchArea: false,
  customViewBox: null,
  hideArrow: false,
  hideTouchPoints: false,
  selectOnFirstClick: true,
  rotateValueOnFirstClick: false,
  onFilterChange: (filters, userClick = false) => { console.debug('filter change', filters) },
  onPropChange: (prop) => { console.debug('prop change', prop) },
  onSVGLoaded: () => { },
  onFinishedOnboarding: () => { },
  tagHighlightColor: "#2F30EB",
  tagDefaultColor: "#333333",
}
