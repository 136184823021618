import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import Widget from '../modules/Widget';
import noUiSlider from 'nouislider';
import { findLabelByLng } from '@yesplz/core';

const { document } = window;

const TEMPLATE = `
<div class="yesplz-price-range-container">
  <div data-id="yesplz-dynamic-range-title" class="yesplz-price-range-title hidden"></div>
  <div data-id="yesplz-dynamic-range-slider" class="yesplz-price-range-slider"></div>
  <div class="yesplz-price-range-values">
    <span data-id="yesplz-dynamic-range-value-low"></span>
    <span data-id="yesplz-dynamic-range-value-high"></span>
  </div>
</div>
`;

export class DynamicRangeFilter extends Widget {
  defaultParams = {
    title: 'Price',
    step: null,
    filterName: 'price',
    overMessage: [{ lng: 'en', label: 'Over {value}' }, { lng: 'ko', label: '{value} 이상' }],
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-price-range-filter';
    this.mainElement = element;

    this.allPrices = {};

    this.mainElement.innerHTML = TEMPLATE;

    this.slider = null;

    this.filterSettings = { min: 0, max: 1000 };
  }

  didMount() {
    this.renderRange();
  }

  didUpdate(prevState) {
    const filterName = this.params.filterName;
    if (
      !isEqual(
        prevState.search?.filters?.[filterName],
        this.state.search?.filters?.[filterName]
      )
    ) {
      if (this.state.search?.filters?.[filterName]) {
        this.filterSettings = this.state.search?.filters?.[filterName];
      }
      this.renderRange();
    }
    else if (
      !isEqual(prevState.filter.params?.[filterName], this.state.filter.params?.[filterName])
    ) {
      this.renderLabels(...this.values);
      if (this.slider.noUiSlider) {
        const [low, high] = this.values;
        this.slider.noUiSlider.updateOptions({
          start: [low || this.range.min, high || this.range.max],
        });
      }
    }
  }

  formatValue(number) {
    // let currency = this.params.currency;
    // if (this.prices?.currency) {
    //   currency = {
    //     code: this.prices.currency,
    //   };
    // }
    // return formatCurrency(number, currency);
    return number;
  }

  get filterValues() {
    return this.state.search?.filters?.[this.params.filterName] || null;
  }

  get range() {
    const step = this.step;
    return {
      min: Math.floor(this.filterSettings.min / step) * step,
      max: Math.ceil(this.filterSettings.max / step) * step,
    };
  }

  get step() {
    return 100;
    // if (this.params.step) {
    //   return this.params.step;
    // }

    // if (this.prices.step) {
    //   return this.prices.step;
    // }

    // const prices = Object.values(this.prices);
    // if (prices[0].includes('-'))
    //   return parseInt(prices[0].split('-')[1]);
    // else
    //   return prices[1] - prices[0];
  }

  get values() {
    const value = this.state.filter.params?.[this.params.filterName];

    if (!value) return [];

    const [low, high] = value.split('-');
    const range = this.range;

    return [low || range.min, high || range.max];
  }

  handleValueChange = (low, high) => {
    const filterName = this.params.filterName;
    const { params } = this.state.filter;
    this.setFilter({
      params: {
        ...params,
        [filterName]: `${low}-${high < this.range.max ? high : ''}`,
      },
    });
  }

  handleValueUpdate = (low, high) => {
    this.renderLabels(low, high);
  }

  get indexId() {
    const { filterBy } = this.params;
    const value = get(this.state.filter, filterBy);
    return Array.isArray(value) ? value.join(',') : value;
  }

  renderLabels(low, high) {
    const { lng } = this.state.config;
    const lowElement = this.mainElement.querySelector('[data-id="yesplz-dynamic-range-value-low"]');
    const highElement = this.mainElement.querySelector('[data-id="yesplz-dynamic-range-value-high"]');
    const lowText = `${this.formatValue(low || this.range.min)}`;
    const highText = (
      high && high !== this.range.max
        ? `${this.formatValue(high)}`
        : findLabelByLng(this.params.overMessage, lng)
            .replace('{value}', this.formatValue(this.range.max || 100))
    );

    lowElement.innerHTML = lowText;
    highElement.innerHTML = highText;

    const title = this.mainElement.querySelector('[data-id="yesplz-dynamic-range-title"]');
    title.classList.remove('hidden');
    title.innerHTML = `(${lowText} ~ ${highText})`;
  }

  renderRange() {
    if (!this.filterValues) return;

    const slider = this.mainElement.querySelector('[data-id="yesplz-dynamic-range-slider"]');
    
    if (!slider) return;
    
    const [low, high] = this.values;

    if (slider.noUiSlider) {
      slider.noUiSlider.destroy();
    }

    const range = this.range;
    const step = this.step;

    noUiSlider.create(slider, {
      start: [low || range.min, high || range.max || step],
      step: step,
      connect: true,
      margin: step,
      range: {
        ...range,
        max: range.max === range.min ? range.max + step : range.max,
      },
    });

    // slider.setAttribute('disabled', true);

    slider.noUiSlider.on('change', ([lowStr, highStr]) => {
      this.handleValueChange(parseInt(lowStr), parseInt(highStr));
    });

    slider.noUiSlider.on('update', ([lowStr, highStr]) => {
      this.handleValueUpdate(parseInt(lowStr), parseInt(highStr));
    });

    this.renderLabels(low, high);

    this.slider = slider;

    if (typeof this.params.onRendered === 'function')
      this.params.onRendered(this.range);
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new DynamicRangeFilter(params);
};
