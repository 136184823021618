import uniq from 'lodash/uniq';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import { shouldBeRefetched } from './favorites';

const TOGGLE_LIKE_PRODUCT = 'favoritesStorage/TOGGLE_LIKE_PRODUCT'
const TOGGLE_LIKE_STYLE = 'favoritesStorage/TOGGLE_LIKE_STYLE'

const initialState = {
  products: {},
  styles: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_LIKE_STYLE:
      const newCategoryStyles = {
        ...(state.styles[action.categoryId] || {}),
      };

      if (newCategoryStyles[action.filterKey]) {
        delete newCategoryStyles[action.filterKey];
      }
      else {
        newCategoryStyles[action.filterKey] = {
          ...action.filter,
        };
      }

      const styles = {
        ...state.styles,
        [action.categoryId]: newCategoryStyles,
      };

      if (isEmpty(styles[action.categoryId])) {
        delete styles[action.categoryId];
      }

      return {
        ...state,
        styles,
      };
    case TOGGLE_LIKE_PRODUCT:
      let catProducts;
      const { categoryId, productId } = action;
      if (
        state.products[categoryId]
        &&
        state.products[categoryId].includes(productId)
      ) {
        catProducts = without(state.products[categoryId] || [], productId);
      }
      else {
        catProducts = uniq([ ...(state.products[categoryId] || []), productId ]);
      }

      const products = {
        ...state.products,
        [categoryId]: catProducts,
      };
      if (isEmpty(products[categoryId])) {
        delete products[categoryId];
      }

      return {
        ...state,
        products,
      };
    default:
      return state;
  }
}

export function toggleLikeProduct(categoryId, productId) {
  return (dispatch) => {
    dispatch({ type: TOGGLE_LIKE_PRODUCT, categoryId, productId });
    dispatch(shouldBeRefetched());
  };
}

export function toggleLikeStyle(categoryId, filterKey, filter) {
  return { type: TOGGLE_LIKE_STYLE, categoryId, filterKey, filter };
}
