import React from 'react';
import qs from 'qs';
import classnames from 'classnames';
import has from 'lodash/has';
import { withStyles } from '@material-ui/core/styles';
import BaseTooltip from '@material-ui/core/Tooltip';
import { formatCurrency } from '@yesplz/core';
import { BASE_IMG_PATH } from '@yesplz/core-web/config/constants'

import './Product.scss';

const Tooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 254,
    minHeight: 362,
    maxWidth: 'none',
    width: 'auto',
    height: 'auto',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(BaseTooltip);

export default function Product({ className, product, backUrl, searchParams, isTooltipActive = true, retailer }) {
  let tooltip = product.score ? 'score: ' + product.score.toFixed(1) + '\n' : '';
  tooltip += product.scoreInfo ? product.scoreInfo + '\n' : '';
  tooltip += product.product.textPredictions ? product.product.textPredictions + '\n' : '';
  tooltip += product.product.rawPredictions ? product.product.rawPredictions + '\n' : '';
  tooltip += product.product.extraInfo ? product.product.extraInfo : '';

  let imageUrl = (product.product.frontImgSrc
    ? product.product.frontImgSrc
    : product.product.frontImg
      ? product.product.frontImg
      : has(product.product, 'variants.0.images.0.srcUri')
        ? product.product.variants[0].images[0].srcUri
        : product.product.imgUri || null);

  // Workaround for product image url, sometimes it's an array
  // Issue on the backend
  if (Array.isArray(imageUrl)) {
    imageUrl = imageUrl[0];
  }

  if (imageUrl && !imageUrl.startsWith('http')) {
    imageUrl = BASE_IMG_PATH + '/' + imageUrl;
  }

  if (imageUrl && imageUrl.includes('handsome'))
    imageUrl = 'https://kr-images.yesplz.ai/handsome/media/' + product.product.frontImg;

  const searchQuery = qs.stringify(searchParams);
  const currencySettings = retailer === 'handsome' ? {
    locale: 'ko-KR',
    code: 'KRW',
  } : {};

  return (
    <div className={classnames('ReviewProduct-product-container', className)}>
      <Tooltip disableHoverListener={!isTooltipActive} title={<pre>{tooltip}</pre>} placement="left-start">
      <div className='product-image'>
        <a
          href={`/review/product/${product.product.productId}?${searchQuery}&backUrl=${encodeURIComponent(backUrl)}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={imageUrl} className='front-image' alt='' />
        </a>
      </div>
      </Tooltip>
      <div className='product-detail'>
        <p className='product-id'>{product.product.productId}</p>
        <p className='product-name'>{product.product.productName}</p>
        <p className='product-brand'>{product.product.brandName}</p>
        <p className='product-price'>
          {formatCurrency(product.product.salePrice, currencySettings)}
          <span className='original'>{formatCurrency(product.product.originalPrice, currencySettings)}</span>
        </p>
      </div>
    </div>
  )
}
