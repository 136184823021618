import { Suspense, lazy } from 'react'
import { Route, Redirect, Switch } from 'react-router'

// import ComingSoon from '@yesplz/core-web/ui-kits/coming-soon/ComingSoon'
// pages
import { Base, NotFound } from 'modules/base'
import Register from 'modules/register/Register'
import Home from 'modules/home/Home'
// import PresetsSettings from 'modules/presets/presetsSettings'
// import PresetsDashboard from 'modules/presets/presetsDashboard'
// import ProductPage from 'modules/products/ProductPage'
// import YMALSettings from 'modules/ymal/ymalSettings'
import { ReviewList, ReviewProduct } from 'modules/review'
// import { StyleSuggestionsList } from 'modules/style-suggestions'
import {
  Home as ShopifyHome,
  ReviewList as ShopifyReviewList,
  ReviewProduct as ShopifyReviewProduct,
  VisualFilter as ShopifyVisualFilter,
} from 'modules/shopify';
import { CategoriesTree } from 'modules/menu-management'
import { useUser } from '../components/UserProvider'

function PrivateRoute({ component: Component, ...rest }) {
  const { userInfo, isLoading } = useUser();

  if (isLoading) return null;

  return (
    <Route
      {...rest}
      component={userInfo ? Component : ({ location }) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const createRoutes = () => (
  <Switch>
    <Route
      path='/'
      component={
        JSON.parse(process.env.REACT_APP_SHOPIFY_MODE || 'false') ? ShopifyPlatform : BasePlatform
      } />
  </Switch>
)

const VisualFilter = lazy(() => import('modules/visual-filter-page'));
const VisualFilterPreview = lazy(() => import('modules/visual-filter-page/Preview'));
const VisualFilterEditor = lazy(() => import('modules/visual-filter-editor'));
const ConfigurationReview = lazy(() => import('modules/configuration-review'));
const LooksList = lazy(() => import('modules/complete-look'));
const WconceptReview = lazy(() => import('modules/wconcept'));
const Prompts = lazy(() => import('modules/prompts'));

function Loading() {
  return <div>Loading...</div>;
}

// nested routes components
const BasePlatform = (props) => (
  <Base {...props}>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path='/login' component={Register} />
        <PrivateRoute exact path='/' component={Home} />
        {/* <PrivateRoute exact path='/comingsoon' render={() => <ComingSoon functionName='This feature' />} />
        <PrivateRoute exact path='/presets/settings/:category' component={PresetsSettings} />
        <PrivateRoute exact path='/presets/dashboard' component={PresetsDashboard} />
        <PrivateRoute exact path='/ymal/settings/:category' component={YMALSettings} /> */}
        <PrivateRoute exact path='/menu-editor' component={CategoriesTree} />
        <PrivateRoute exact path='/complete-look' component={LooksList} />
        <PrivateRoute exact path='/complete-look/:categoryId?' component={LooksList} />
        <PrivateRoute exact path='/review-default-configuration' component={ConfigurationReview} />
        <PrivateRoute exact path='/review/:categoryId?' component={ReviewList} />
        <PrivateRoute exact path='/review/product/:productId' component={ReviewProduct} />
        <PrivateRoute exact path='/visual-filter-editor/:categoryId?' component={VisualFilterEditor} />
        <PrivateRoute exact path='/visual-filter/:categoryId?' component={VisualFilter} />
        <PrivateRoute exact path='/visual-filter-preview/:categoryId?' component={VisualFilterPreview} />
        <PrivateRoute path='/wconcept' component={WconceptReview} />
        <PrivateRoute path='/stylist/prompts' component={Prompts} />
        {/* <PrivateRoute exact path='/product/:id' component={ProductPage} /> */}
        {/* <PrivateRoute exact path='/style-suggestions/:category?' component={StyleSuggestionsList} /> */}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </Base>
)

// Enabled when REACT_APP_RETAILER_DEMO is set.
// Show visual filter page for each category
const ShopifyPlatform = (props) => (
  <Base {...props}>
    <Switch>
      <Route exact path='/login' component={Register} />
      <PrivateRoute exact path='/' component={ShopifyHome} />
      <PrivateRoute exact path='/:shop/review' component={ShopifyReviewList} />
      <PrivateRoute exact path='/:shop/review/product/:productId' component={ShopifyReviewProduct} />
      <PrivateRoute exact path='/:shop/visual-filter/:category?' component={ShopifyVisualFilter} />
      <Route component={NotFound} />
    </Switch>
  </Base>
)

export default createRoutes()
