import isEqual from 'lodash/isEqual';
import client from '@yesplz/client';
import Widget from '../modules/Widget';

const { document } = window;

class PriceFilter extends Widget {
  defaultParams = {
    title: 'Brands',
    searchButtonText: 'Search',
    showSearchButton: true,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-price-filter';
    this.mainElement = element;

    this.pricesByCategories = {};
  }

  didMount() {
    this.fetchAndRenderPrices();
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.fetchAndRenderPrices();
    }
    else if (
      !isEqual(prevState.filter.params.price, this.state.filter.params.price)
    ) {
      const prevPrice = prevState.filter.params.price;
      const price = this.state.filter.params.price;
      const prevPriceElement = this.mainElement.querySelector(`[data-price-id="${prevPrice}"]`);
      const curPriceElement = this.mainElement.querySelector(`[data-price-id="${price}"]`);
      if (prevPriceElement) {
        prevPriceElement.classList.remove('active')
      }
      if (curPriceElement) {
        curPriceElement.classList.add('active')
      }

      this.priceFromInput.value = price ? price.split('-')[0] : '';
      this.priceToInput.value = price ? price.split('-')[1] : '';
    }
  }

  handleItemClick = (e, price) => {
    e.stopPropagation();
    const { params } = this.state.filter;
    this.setFilter({
      params: {
        ...params,
        price: (
          price.priceFilter !== params.price
            ? price.priceFilter
            : null
        ),
      },
    });
  }

  priceInputChange = (e) => {
    e.stopPropagation();
    const priceFrom = parseInt(this.priceFromInput.value);
    const priceTo = parseInt(this.priceToInput.value);
    const { params } = this.state.filter;
    this.setFilter({
      params: {
        ...params,
        price: `${priceFrom  || ''}-${priceTo && priceTo > priceFrom ? priceTo : ''}`,
      },
    });
  }

  handleInputKeyUp = (e) => {
    e.target.value = e.target.value.replace(/\D/, '');
  }

  async fetchAndRenderPrices() {
    const { categoryId } = this.state.filter;
    const { searchAdditionalParams } = this.state.config;
    this.clearPrices();
    if (!this.pricesByCategories[categoryId]) {
      this.pricesByCategories[categoryId] = await client.pricesByCategories(categoryId, searchAdditionalParams);
    }
    this.renderPrices();
  }

  clearPrices() {
    this.mainElement.innerHTML = '';
  }

  renderPrices() {
    if (!this.pricesByCategories) return;

    const { categoryId, params } = this.state.filter;
    const prices = this.pricesByCategories[categoryId];

    if (!prices) return;

    const ul = document.createElement('ul');

    prices.forEach(price => {
      const li = document.createElement('li');
      li.innerHTML = `<span>${price.label}</span>`;
      li.setAttribute('data-price-id', price.priceFilter);
      ul.appendChild(li);

      if (price.priceFilter === params.price) {
        li.classList.add('active');
      }

      li.querySelector('span')
        .addEventListener('click', (e) => this.handleItemClick(e, price));
    });

    const priceFrom = params.price ? params.price.split('-')[0] : '';
    const priceTo = params.price ? params.price.split('-')[1] : '';

    const li = document.createElement('li');
    li.className = 'price-inputs';
    let priceInputHtml = `
      <span class="price-input">
        <input type="text" id="priceFrom" value="${priceFrom || ''}" />
      </span>
      <span class="price-input">
        <input type="text" id="priceTo" value="${priceTo || ''}" />
      </span>
    `;

    if (this.params.showSearchButton) {
      priceInputHtml += `<button>${this.params.searchButtonText}</button>`;
    }

    li.innerHTML = priceInputHtml;

    this.priceFromInput = li.querySelector('#priceFrom');
    this.priceToInput   = li.querySelector('#priceTo');

    ul.appendChild(li);

    if (this.params.showSearchButton) {
      li.querySelector('button')
        .addEventListener('click', this.priceInputChange);
    }

    this.priceFromInput.addEventListener('keyup', this.handleInputKeyUp);
    this.priceToInput.addEventListener('keyup', this.handleInputKeyUp);

    this.mainElement.appendChild(ul);
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new PriceFilter(params);
};
