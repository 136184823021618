import React, { useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function SortDropdown({ sort, onChange, className }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const sortingItems = [
    { label: !isOpen ? 'Sort' : 'No Sorting', value: 'default' },
    { label: 'Sort by Most Popular Today', value: 'viewCount:1' },
    { label: 'Sort by Most Popular This Week', value: 'viewCount:7' },
    { label: 'Sort by Most Popular This Month', value: 'viewCount:30' },
    { label: 'Sort by New to Old Date', value: 'releaseDate' },
  ];

  return (
    <div className={classnames(classes.sortRoot, className)}>
      <Select
        className={classes.sortSelect}
        value={sort && sort !== 'random' ? sort : 'default'}
        onChange={(e) => {
          const value = e.target.value;
          onChange(
            value && value !== 'default'
              ? value
              : null
          );
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        {sortingItems.map((sort) => (
          <MenuItem key={sort.value} value={sort.value}>
            {sort.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

const useStyles = makeStyles({
  sortSelect: {
    '&:before': {
      borderBottom: 0,
    },
  },
  sortRoot: {
    '& span': {
      marginTop: 2,
      marginRight: 10,
      fontSize: '17px',
    },
    '& .YesplzCheckbox': {
      marginBottom: '6px',
      '& .YesplzCheckbox-mask.is-checked:after': {
        width: '7px',
        height: '7px',
      },
      '&:last-child': {
        marginLeft: '40px',
      }
    }
  },
});
