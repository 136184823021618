import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import Fuse from 'fuse.js';
import sortBy from 'lodash/sortBy';
import Conveyer from "@egjs/conveyer";
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

class DynamicList extends Widget {
  defaultParams = {
    title: 'Dynamic',
    filterName: 'gender',
    items: null,
    shouldRenderDisabled: true,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.fuse = new Fuse([], {
      includeScore: true,
      includeMatches: true,
      threshold: 0.4,
      keys: ['value', 'label'],
    });

    this.ul = document.createElement('ul');
    this.searchInput = null;
    this.indexDiv = null;
    this.selected = null;

    this.indexConveyer = null;

    this.isIndexDragging = false;
    this.isDragging = false;

    this.query = null;

    this.useSearch = false;
    this.useIndex = false;
  }

  get filterName() {
    return this.params.filterName;
  }

  get currentValue() {
    let currentValue = this.state.filter.params[this.filterName];
    if (currentValue && !Array.isArray(currentValue)) currentValue = [currentValue];
    return currentValue || [];
  }

  get allItems() {
    return (
      this.params.items
      ||
      sortBy(
        sortBy(
          Object.values(this.state?.search?.filters?.[this.filterName] || {}),
          (b) => (b.label || b.value)
        ),
        (a) => (a.isActive ? 0 : 1)
      )
    );
  }

  get items() {
    if (this.query) {
      const found = this.fuse.search(this.query);
      const items = found.map(f => f.item);
      return items;
    }

    return this.allItems;
  }

  didMount() {
    this.updateSettings();
    this.renderItems();

    if (this.selected) {
      this.conveyer = new Conveyer(this.selected);
      this.conveyer.on('beginScroll', () => {
        this.isDragging = true;
      });
      this.selected.addEventListener('mouseup', () => {
        setTimeout(() => {
          this.isDragging = false;
        }, 100);
      });
    }
    if (this.indexDiv) {
      this.indexConveyer = new Conveyer(this.indexDiv);
      this.indexConveyer.on('beginScroll', () => {
        this.isIndexDragging = true;
      });
      this.indexConveyer.on('finishScroll', (e) => {
        this.isIndexDragging = false;
      });
      const handleScroll = () => {
        const maxScroll = this.indexDiv.scrollWidth - this.indexDiv.clientWidth - 5;
        if (this.indexDiv.scrollLeft >= maxScroll) {
          this.indexNext.classList.add('disabled');
        }
        else if (this.indexDiv.scrollLeft === 0) {
          this.indexPrev.classList.add('disabled');
        }
        else {
          this.indexNext.classList.remove('disabled');
          this.indexPrev.classList.remove('disabled');
        }
      }
      this.indexDiv.addEventListener('scroll', handleScroll);
      this.indexPrev.classList.add('disabled');
      this.indexPrev.addEventListener('click', () => {
        this.indexDiv.scrollTo(this.indexDiv.scrollLeft - 60, 0);
      });
      this.indexNext.addEventListener('click', () => {
        this.indexDiv.scrollTo(this.indexDiv.scrollLeft + 60, 0);
      });
    }
  }

  didUpdate(prevState) {
    if (
      (!this.params.items
      && !isEqual(
        prevState.search?.filters?.[this.filterName],
        this.state.search?.filters?.[this.filterName]
      ))
      || !isEqual(prevState.filter.params[this.filterName], this.state.filter.params[this.filterName])
    ) {
      this.updateSettings();
      this.renderItems();
    }
  }

  updateSettings() {
    const items = this.allItems;
    if (items.length > 20) {
      this.useSearch = true;
      this.useIndex = true;
      this.mainElement.classList.remove('no-search');
      this.mainElement.classList.remove('no-index');
    }
    else {
      this.useSearch = false;
      this.useIndex = false;
      this.mainElement.classList.add('no-search');
      this.mainElement.classList.add('no-index');
    }
    this.fuse.setCollection(items);
  }

  handleItemClick = (e, value) => {
    e.stopPropagation();
    const { categoryId, params } = this.state.filter;
    const filterName = this.filterName;

    EventEmitter.emit(`${filterName}OptionClick`, {
      categoryId,
      [filterName]: value,
    });

    let newValue = cloneDeep(this.currentValue);
    if (Array.isArray(newValue) === false) newValue = [newValue];

    if (newValue.includes(value)) {
      newValue.splice(newValue.indexOf(value), 1);
      EventEmitter.emit(`${filterName}OptionRemoved`, {
        categoryId,
        [filterName]: value,
      });
    }
    else {
      newValue.push(value);

      EventEmitter.emit(`${filterName}OptionApplied`, {
        categoryId,
        [filterName]: value,
      });
    }

    this.setFilter({
      params: {
        ...params,
        [filterName]: newValue,
      },
    });
  }

  handleIndexClick = (e) => {
    if (this.isIndexDragging) return;

    const letter = e.target.innerText;
    const li = this.mainElement.querySelector(`[data-dl-index="${letter}"]`);
    // scroll to the position
    if (window.innerWidth < 768) {
      // get element scroll position based on the container
      const scrollPosition = li.offsetTop - this.mainElement.offsetTop - 100;
      this.ul.scrollTo(0, scrollPosition);
    }
    else {
      const scrollPosition = li.offsetTop - 180;
      this.ul.scrollTo(0, scrollPosition);
    }
  }

  search = (e) => {
    this.isTyping = true;

    const query = e.target.value;
    if (!query) {
      this.query = null;
    }
    // e.target.parentElement.classList.add('is-not-empty');
    this.query = query;

    this.renderItems();
  }

  renderSearchBar() {
    const form = document.createElement('form');
    const input = document.createElement('input');
    const resetButton = document.createElement('button');
    resetButton.classList.add('reset-button');
    resetButton.type = 'button';
    input.placeholder = `Search`;
    form.appendChild(input);
    form.appendChild(resetButton);
    this.mainElement.appendChild(form);

    this.searchInput = input;

    input.addEventListener('keyup', this.search);
    // resetButton.addEventListener('click', this.resetSearch);
  }

  renderItems() {
    this.ul.innerHTML = '';

    if (!this.items?.length) return;

    const renderedItems = [];
    const indexLetters = [];
    let currentLetter = null;
    this.items.forEach((item, refIndex) => {
      if (!item.value || (
        !this.params.shouldRenderDisabled
        &&
        !item.isActive
      )) return;

      const value = item.value;
      const label = `${item.label || item.value}`;

      if (this.useIndex) {
        const nextLetter = (
          !isNaN(parseInt(label[0], 10)) || label[0].match(/^[^a-zA-Z0-9]+$/)
            ? '#'
            : label[0]
        );

        if (currentLetter !== nextLetter.toUpperCase()) {
          currentLetter = nextLetter.toUpperCase();
          const li = document.createElement('li');
          li.setAttribute('data-dl-index', currentLetter);
          li.classList.add('header');
          li.innerHTML = `<span>${currentLetter}</span>`;
          this.ul.appendChild(li);
          indexLetters.push(currentLetter);
        }
      }

      const li = document.createElement('li');
      li.innerHTML = label;
      li.setAttribute('data-dl-option-id', value);
      li.setAttribute('data-dl-option-ref-index', refIndex);
      this.ul.appendChild(li);

      if (this.currentValue.includes(value)) {
        li.classList.add('active');
      }
      else if (!item.isActive) {
        li.classList.add('is-disabled');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, value));

      renderedItems.push(item);
    });

    if (typeof this.params.onRendered === 'function') this.params.onRendered(renderedItems);

    this.indexDiv.innerHTML = '';
    if (this.useIndex && indexLetters.length > 0) {
      this.indexContainer.classList.remove('hidden');
      indexLetters.forEach(letter => {
        const span = document.createElement('span');
        span.innerText = letter;
        this.indexDiv.appendChild(span);

        span.addEventListener('click', this.handleIndexClick);
      });
    }
    else {
      this.indexContainer.classList.add('hidden');
    }
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);

    this.renderSearchBar();

    this.indexContainer = document.createElement('div');
    this.indexContainer.classList.add('brands-index-container');
    this.indexPrev = document.createElement('span');
    this.indexPrev.classList.add('prev');
    this.indexNext = document.createElement('span');
    this.indexNext.classList.add('next');

    this.indexDiv = document.createElement('div');
    this.indexDiv.classList.add('brands-index');
    
    this.indexContainer.appendChild(this.indexPrev);
    this.indexContainer.appendChild(this.indexDiv);
    this.indexContainer.appendChild(this.indexNext);
    this.mainElement.appendChild(this.indexContainer);

    this.mainElement.appendChild(this.ul);

    return this.mainElement;
  }
}

export default (params) => {
  return new DynamicList(params);
};
