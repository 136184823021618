/**
 * Available options
 * {
 *   locale: 'en-US',
 *   code: 'USD',
 *   position: null,
 *   symbol: null,
 *   rate: 1,
 * }
 * 
 * Position - Available values
 * null - position determined by locale
 * 'start'
 * 'end'
 * 
 * @param {*} number number
 * @param {*} options object
 * @returns string
 */
export default function formatCurrency(number, { rate = 1, locale = 'en-US', code = 'USD', ...options }) {
  let formatted = (number * rate).toLocaleString(locale, {
    style: 'currency',
    currency: code,
  });
  const localeCurrencySymbol = (0).toLocaleString(locale, {
    style: 'currency',
    currency: code,
  }).replaceAll('0', '').replaceAll(' ', '').replaceAll(',', '').replaceAll('.', '');
  const symbol = options.symbol || localeCurrencySymbol;

  if (options.position === 'start') {
    formatted = formatted.replace(localeCurrencySymbol, '');
    formatted = `${symbol}${formatted}`;
  }
  else if (options.position === 'end') {
    formatted = formatted.replace(localeCurrencySymbol, '');
    formatted = `${formatted}${symbol}`;
  }
  else if (symbol !== localeCurrencySymbol) {
    formatted = formatted.replace(localeCurrencySymbol, symbol);
  }

  return formatted;
}
