import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DotLoader } from '@yesplz/core-web/ui-kits/loaders';

export default function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DotLoader visible className={classes.loader} />
    </div>
  )
};

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 16,
    right: 28,
    zIndex: 1000,
    backgroundColor: 'rgba(255,255,255,0.6)',
  },
  loader: {},
});
