const SET_BUG_REPORT_SETTING = 'admin/SET_BUG_REPORT_SETTING'

const defaultState = {
  openBugReport: false
}

// Reducer
export default function reducer (state = defaultState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SET_BUG_REPORT_SETTING:
      return {
        ...state,
        openBugReport: payload.isOpened
      }
    default: return state
  }
}

// Actions creator
export function setBugReportSetting (isOpened) {
  return { type: SET_BUG_REPORT_SETTING, payload: { isOpened } }
}
