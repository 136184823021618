import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { useForm } from "react-hook-form";
import classNames from 'classnames';
import { findLabelByLng } from '@yesplz/core';
// import styles from './CategoryEditForm.module.scss';

function Form({ onClose, onChange, parentPath, itemPath, defaultValues = {}, categories }) {
  const { formState: { errors }, register, handleSubmit, watch, reset, setValue, getValues } = useForm({
    defaultValues: {
      ...defaultValues,
      isPublished: defaultValues.isPublished || true,
    },
  });
  const values = watch();

  return (
    <form onSubmit={handleSubmit((data) => {
      console.log(data);
      onChange({
        ...data,
        parentPath,
        itemPath,
      });
    })}>
      <DialogTitle id="form-dialog-title">Menu Item</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Label"
          fullWidth
          error={!!errors.label}
          {...register("label", { required: true })}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="categoryId-label">Category</InputLabel>
          <Select
            autoFocus
            labelId="categoryId-label"
            id="categoryId"
            value={values.categoryId}
            {...register("categoryId")}
          >
            <MenuItem value="url">Insert URL</MenuItem>
            {categories.map(({ label, categoryId }) => (
              <MenuItem key={categoryId} value={categoryId}>{findLabelByLng(label)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {
          values.categoryId === 'url'
            ? <TextField
                autoFocus
                margin="dense"
                label="URL"
                fullWidth
                {...register("url")}
              />
            : null
        }
        <FormControlLabel
          control={<Switch
            color='primary'
            checked={values.isPublished}
            onChange={(_, checked) => {
              setValue(`isPublished`, checked);
            }}
          />}
          label={values.isPublished ? 'Active' : 'Disabled'}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button type='submit' color="primary">
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default function MenuItemForm({ open, onClose, defaultValues = {}, ...props }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
      <Form defaultValues={defaultValues} onClose={onClose} {...props} />
    </Dialog>
  );
}
