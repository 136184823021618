import { useState } from 'react'
import { Link } from 'react-router-dom'
import { findLabelByLng } from '@yesplz/core';
import IconButton from '@material-ui/core/IconButton';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import styles from './Category.module.scss';

function Alert({ onClose, open, title, message }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please, delete children first.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Confirm({ onCancel, onConfirm, open }) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete category"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="default">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="secondary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Category({
  item, path, hasChildren, isOpen, onOpenCloseClick, onCreateClick, onEditClick, onPublishClick, onDeleteClick,
  onLevelUpClick, onLevelDownClick, onUpwardClick, onDownwardClick, onItemClick,
}) {
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const { label, categoryId, isPublished } = item;
  return (
    <div className={styles['root']}>
      {
        hasChildren
          ? <Alert
            open={isDeleteConfirmOpen}
            onClose={() => {
              setIsDeleteConfirmOpen(false);
            }}
          />
          : <Confirm
            open={isDeleteConfirmOpen}
            onCancel={() => setIsDeleteConfirmOpen(false)}
            onConfirm={() => {
              onDeleteClick(path);
              setIsDeleteConfirmOpen(false);
            }}
          />
      }
      <div className={styles['drag-handler']}>
        <IconButton
          size='small'
          onClick={() => onOpenCloseClick && onOpenCloseClick(path)}
        >
          {
            hasChildren
              ? isOpen
                ? <KeyboardArrowDownIcon />
                : <KeyboardArrowRightIcon />
              : null
          }
        </IconButton>
      </div>
      <div className={styles['label']}>
        <span
          className={onItemClick ? styles['clickable'] : ''}
          onClick={() => onItemClick(item)}
        >
          {findLabelByLng(label)}
        </span>
        {!onItemClick && categoryId ? <Link to={`/visual-filter-editor/${encodeURIComponent(categoryId)}`}>Review</Link> : null}
      </div>
      <div
        className={styles['buttons']}
      >
        <IconButton
          size='small'
          onClick={() => onLevelUpClick && onLevelUpClick(path)}
        >
          <SubdirectoryArrowRightIcon className='test-class' style={{
            transform: 'rotate(180deg) scale(0.7)',
          }} />
        </IconButton>
        <IconButton
          size='small'
          onClick={() => onUpwardClick && onUpwardClick(path)}
        >
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton
          size='small'
          onClick={() => onDownwardClick && onDownwardClick(path)}
        >
          <ArrowDownwardIcon />
        </IconButton>
        <IconButton
          size='small'
          onClick={() => onLevelDownClick && onLevelDownClick(path)}
        >
          <SubdirectoryArrowRightIcon />
        </IconButton>
      </div>
      <div
        className={styles['buttons']}
      >
        <IconButton
          size='small'
          onClick={() => onCreateClick && onCreateClick(path)}
        >
          <AddIcon />
        </IconButton>
        <IconButton
          size='small'
          onClick={() => onEditClick && onEditClick(path)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          size='small'
          color={isPublished ? "primary" : undefined}
          onClick={() => onPublishClick && onPublishClick(path)}
        >
          {isPublished ? <CloudDoneIcon /> : <CloudIcon />}
        </IconButton>
        <IconButton
          size='small'
          onClick={() => setIsDeleteConfirmOpen(true)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}
