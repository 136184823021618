import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function TooltipSwitch({ label='Tooltip', checked, onChange }) {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          color="default"
        />
      }
      className={classes.root}
      label={label}
      labelPlacement="start"
    />
  );
}

const useStyles = makeStyles({
  root: {
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#009688',
    },
    '& .Mui-checked': {
      color: '#009688',
    }
  },
});
