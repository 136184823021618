import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Category from './Category';
import styles from './TreeMenu.module.scss';

export default function TreeMenu({
  id, items = [], path = [], configuration, openCategories, onOpenCloseClick,
  onEditClick, onPublishClick, onDeleteClick, onCreateClick, onItemClick,
  onLevelUpClick, onLevelDownClick, onUpwardClick, onDownwardClick,
}) {
  return (
    <ul className={styles.list} id={id}>
      {items?.map((item, index) => {
        const pathIndex = [ ...path, index ];
        const isOpen = openCategories.includes(pathIndex.join('.children.'));
        return (
          <li
            key={index}
            id={item.categoryId}
            className={isOpen ? styles.open : ''}
          >
            <Category
              hasChildren={item.children && item.children.length > 0}
              isOpen={isOpen}
              item={item}
              categories={configuration}
              path={pathIndex}
              {...{
                onOpenCloseClick, onCreateClick, onEditClick, onPublishClick, onDeleteClick,
                onLevelUpClick, onLevelDownClick, onUpwardClick, onDownwardClick, onItemClick,
              }}
            />
            {
              item.children
                ? <TreeMenu
                    id={item.categoryId}
                    path={pathIndex}
                    {...{
                      items: item.children, openCategories, onOpenCloseClick, configuration,
                      onCreateClick, onEditClick, onPublishClick, onDeleteClick, onItemClick,
                      onLevelUpClick, onLevelDownClick, onUpwardClick, onDownwardClick,
                    }}
                  />
                : null
            }
          </li>
        );
      })}
      {path.length === 0 ? <li className={styles.creator}>
        <IconButton
          size='small'
          onClick={() => onCreateClick && onCreateClick()}
        >
          <AddIcon />
        </IconButton>
      </li> : null}
    </ul>
  );
}
