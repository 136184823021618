import { createReducer } from '@reduxjs/toolkit';
import { setSearchFinishedReturnFields, setUseUrlHistory, setConfigValue, toggleTooltips } from '../actions/configActions';

const initialFilter = {
  useUrlHistory: false,
  searchFinishedReturnFields: ['productId', 'score'],
  useSearch: true,
  isTooltipsVisible: false,
  searchAdditionalParams: null,
  requestConfig: null,
  infiniteScroll: false,
};

const config = createReducer(initialFilter, {
  [setSearchFinishedReturnFields.type]: (state, { payload }) => ({
    ...state,
    searchFinishedReturnFields: payload,
  }),
  [setUseUrlHistory.type]: (state, { payload }) => ({
    ...state,
    useUrlHistory: payload,
  }),
  [setConfigValue.type]: (state, { payload }) => ({
    ...state,
    [payload.key]: payload.value,
  }),
  [toggleTooltips.type]: (state) => ({
    ...state,
    isTooltipsVisible: !state.isTooltipsVisible,
  }),
});

export default config;
