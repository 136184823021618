import { combineReducers } from 'redux';
import { configureStore as reduxConfigureStore } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import {
  filter,
  savedFilters,
  search,
  config,
} from './reducers';
import { filterTransforms } from './persistTransforms';

const storage = createWebStorage(process.env.STORAGE_TYPE || 'local');

const persistConfig = {
  key: 'yesplz.root',
  storage,
  blacklist: ['search', 'filter', 'config'],
};

const filterPersistConfig = {
  key: 'yesplz.filter',
  storage,
  blacklist: ['offset', 'categorySlice'],
  transforms: filterTransforms,
};

function createRootReducer() {
  return combineReducers({
    filter: persistReducer(filterPersistConfig, filter),
    savedFilters,
    search,
    config,
  });
}

const configureStore = (usePersistentFilter = true) => {
  const rootReducer = createRootReducer();
  const store = reduxConfigureStore({
    reducer: (
      usePersistentFilter
        ? persistReducer(persistConfig, rootReducer)
        : rootReducer
    ),
    middleware: [thunk],
  });

  const persistor = (
    usePersistentFilter
      ? persistStore(store)
      : null
  );

  return { store, persistor };
}

export {
  configureStore,
};
