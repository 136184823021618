import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';
import Fuse from 'fuse.js';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import Conveyer from "@egjs/conveyer";

const { document } = window;

function convertBrands(brands = {}) {
  return sortBy(
    Object.values(brands).map(({ value, isActive }) => ({ label: value, brandFilter: value, isActive })),
    'brandFilter'
  );
}

class BrandFilter extends Widget {
  defaultParams = {
    title: 'Brands',
    displaySearchBar: true,
    displayHeaders: true,
    displaySelectedBrands: true,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.fuse = null;
    this.ul = document.createElement('ul');
    this.selected = null;
    this.searchInput = null;

    this.indexConveyer = null;

    this.isIndexDragging = false;
    this.isDragging = false;
  }

  didMount() {
    this.updateBrands();
  }

  didUpdate(prevState) {
    if (!isEqual(
      convertBrands(prevState.search?.filter?.brandName),
      convertBrands(this.state.search?.filter?.brandName)
    )) {
      this.updateBrands();
    }
    else if (
      !isEqual(prevState.search.brand, this.state.filter.brands)
    ) {
      const prevBrands = prevState.filter.brands;
      const brands = this.state.filter.brands;
      this.updateSelectedBrands(prevBrands, brands);
    }
  }

  updateSelectedBrands(prevBrands, brands) {
    const addedItems = difference(brands, prevBrands);
    const removedItems = difference(prevBrands, brands);
    addedItems.forEach(val => {
      const el = this.mainElement.querySelector(`[data-brand-id="${val}"]`);
      if (!el) return;

      el.classList.add('active');
    });
    removedItems.forEach(val => {
      const el = this.mainElement.querySelector(`[data-brand-id="${val}"]`);
      if (!el) return;

      el.classList.remove('active');
    });

    this.renderSelectedBrands();
  }

  handleItemClick = (e, brand) => {
    e.stopPropagation();
    if (brand.isActive) {
      this.main.toggleBrand(brand.brandFilter);
      EventEmitter.emit(
        this.state.filter.brands.includes(brand.brandFilter)
          ? 'txtSidebarBrandApplied'
          : 'txtSidebarBrandRemoved',
        brand.brandFilter
      );
    }
  }

  handleIndexClick = (e) => {
    if (this.isIndexDragging) return;

    const letter = e.target.innerText;
    const li = this.mainElement.querySelector(`[data-brand-index="${letter}"]`);
    // scroll to the position
    if (window.innerWidth < 768) {
      // get element scroll position based on the container
      const scrollPosition = li.offsetTop - this.mainElement.offsetTop - 100;
      this.ul.scrollTo(0, scrollPosition);
    }
    else {
      const scrollPosition = li.offsetTop - 180;
      this.ul.scrollTo(0, scrollPosition);
    }
  }

  handleSelectedClick = (brand) => {
    if (this.isDragging) return;

    this.removeBrand(brand);
  }

  resetSearch = () => {
    this.searchInput.value = '';
    this.renderBrands(convertBrands(this.state.search?.filter?.brandName), this.params.displayHeaders);
    this.searchInput.parentElement.classList.remove('is-not-empty');
    this.query = null;
  }

  removeBrand(brandName) {
    this.main.toggleBrand(brandName);
    EventEmitter.emit('txtSidebarBrandRemoved', brand.brandFilter);
  }

  get indexId() {
    const { categoryId, categorySlice, params = {} } = this.state.filter;
    const { subcategory } = params;
    return (subcategory && subcategory.length ? `${subcategory.join('|')}` : categoryId) + (categorySlice ? `*${categorySlice}` : '');
  }

  updateBrands() {
    const brands = convertBrands(this.state.search?.filter?.brandName);

    this.fuse = new Fuse(brands, {
      includeScore: true,
      includeMatches: true,
      threshold: 0.4,
      keys: ['label'],
    });

    this.mainElement.innerHTML = '';

    if (
      brands
      &&
      brands.length > 20
      &&
      this.params.displaySearchBar
    ) this.renderSearchBar(brands);

    this.indexContainer = document.createElement('div');
    this.indexContainer.classList.add('brands-index-container');
    this.indexPrev = document.createElement('span');
    this.indexPrev.classList.add('prev');
    this.indexNext = document.createElement('span');
    this.indexNext.classList.add('next');

    this.indexDiv = document.createElement('div');
    this.indexDiv.classList.add('brands-index');
    
    this.indexContainer.appendChild(this.indexPrev);
    this.indexContainer.appendChild(this.indexDiv);
    this.indexContainer.appendChild(this.indexNext);
    this.mainElement.appendChild(this.indexContainer);

    if (this.params.displaySelectedBrands) {
      this.selected = document.createElement('div');
      this.selected.classList.add('brands-selected');
      this.mainElement.appendChild(this.selected);
    }

    this.ul = document.createElement('ul');
    this.mainElement.appendChild(this.ul);

    this.renderBrands(brands, this.params.displayHeaders);
    setTimeout(() => {
      if (this.selected) {
        this.conveyer = new Conveyer(this.selected);
        this.conveyer.on('beginScroll', () => {
          this.isDragging = true;
        });
        this.selected.addEventListener('mouseup', () => {
          setTimeout(() => {
            this.isDragging = false;
          }, 100);
        });
      }
      if (this.indexDiv) {
        this.indexConveyer = new Conveyer(this.indexDiv);
        this.indexConveyer.on('beginScroll', () => {
          this.isIndexDragging = true;
        });
        this.indexConveyer.on('finishScroll', (e) => {
          this.isIndexDragging = false;
        });
        const handleScroll = () => {
          const maxScroll = this.indexDiv.scrollWidth - this.indexDiv.clientWidth - 5;
          if (this.indexDiv.scrollLeft >= maxScroll) {
            this.indexNext.classList.add('disabled');
          }
          else if (this.indexDiv.scrollLeft === 0) {
            this.indexPrev.classList.add('disabled');
          }
          else {
            this.indexNext.classList.remove('disabled');
            this.indexPrev.classList.remove('disabled');
          }
        }
        this.indexDiv.addEventListener('scroll', handleScroll);
        this.indexPrev.classList.add('disabled');
        this.indexPrev.addEventListener('click', () => {
          this.indexDiv.scrollTo(this.indexDiv.scrollLeft - 60, 0);
        });
        this.indexNext.addEventListener('click', () => {
          this.indexDiv.scrollTo(this.indexDiv.scrollLeft + 60, 0);
        });
      }

      this.updateSelectedBrands([], this.state.filter.brands);
    }, 10);
  }

  search = (e) => {
    const query = e.target.value;
    if (query) {
      const found = this.fuse.search(query);
      const items = found.map(f => f.item);
      this.renderBrands(items);
      e.target.parentElement.classList.add('is-not-empty');
      return;
    }

    e.target.parentElement.classList.remove('is-not-empty');

    this.renderBrands(convertBrands(this.state.search?.filter?.brandName), this.params.displayHeaders);
  }

  renderSearchBar(brands) {
    const form = document.createElement('form');
    const input = document.createElement('input');
    const resetButton = document.createElement('button');
    resetButton.classList.add('reset-button');
    resetButton.type = 'button';
    input.placeholder = `Search`;
    form.appendChild(input);
    form.appendChild(resetButton);
    this.mainElement.appendChild(form);

    this.searchInput = input;

    input.addEventListener('keyup', this.search);
    resetButton.addEventListener('click', this.resetSearch);
  }

  renderSelectedBrands() {
    if (!this.selected) return;

    const { brands = [] } = this.state.filter;

    this.selected.innerHTML = '';

    if (!brands) return;

    brands.forEach(brand => {
      const element = document.createElement('span');
      element.innerHTML = brand;
      this.selected.appendChild(element);

      element.addEventListener('click', () => this.handleSelectedClick(brand));
    });
  }

  renderBrands(brands, displayHeaders) {
    const { brands: currentBrands = [] } = this.state.filter;

    if (!brands) return;

    this.ul.innerHTML = '';

    const indexLetters = [];

    let currentLetter = null;
    brands.filter(b => b.isActive).forEach((brand, refIndex) => {
      if (!brand?.brandFilter) return;

      const nextLetter = (
        !isNaN(parseInt(brand.label[0], 10)) || brand.label[0].match(/^[^a-zA-Z0-9]+$/)
          ? '#'
          : brand.label[0]
      );
      if (displayHeaders && currentLetter !== nextLetter.toUpperCase()) {
        currentLetter = nextLetter.toUpperCase();
        const li = document.createElement('li');
        li.setAttribute('data-brand-index', currentLetter);
        li.classList.add('header');
        li.innerHTML = `<span>${currentLetter}</span>`;
        this.ul.appendChild(li);

        indexLetters.push(currentLetter);
      }

      const li = document.createElement('li');
      li.innerHTML = `<span>${brand.label}</span>`;
      li.setAttribute('data-brand-id', brand.brandFilter);
      li.setAttribute('data-brand-ref-index', refIndex);
      this.ul.appendChild(li);

      if (currentBrands?.includes(brand.brandFilter)) {
        li.classList.add('active');
      }
      if (!brand.isActive) {
        li.classList.add('is-disabled');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, brand));
    });

    if (displayHeaders) {
      this.indexContainer.classList.remove('hidden');
      this.indexDiv.innerHTML = '';
      indexLetters.forEach(letter => {
        const span = document.createElement('span');
        span.innerText = letter;
        this.indexDiv.appendChild(span);

        span.addEventListener('click', this.handleIndexClick);
      });
    }
    else {
      this.indexContainer.classList.add('hidden');
    }

    if (this.ul.innerHTML === '') {
      const li = document.createElement('li');
      li.classList.add('empty-brands');
      li.innerHTML = 'Unfortunately, there are<br />no results matching your request.';
      this.ul.appendChild(li);
    }

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(convertBrands(this.state.search?.filter?.brandName));
    }
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new BrandFilter(params);
};
