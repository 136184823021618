import Handlebars from 'handlebars';
import Widget from '@yesplz/visualfilter/src/modules/Widget';
import { findLabelByLng } from '@yesplz/core';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import remove from 'lodash/remove';
import { formatCurrency } from '@yesplz/core';

const { document } = window;

const TEMPLATE = `
<div>
  <ul>
    {{#each items}}
      <li data-type="{{this.type}}" data-value="{{this.value}}" class="is-active{{#if this.nonRemovable}} non-removable{{/if}}">
        {{this.label}}
      </li>
    {{/each}}
  </ul>
</div>
`

const shippingOptions = [
  { label: 'Overseas - Standard', value: 'OVERSEAS_STANDARD' },
  { label: 'Priority', value: 'PRIORITY' },
  { label: 'Overseas - Pre-order', value: 'OVERSEAS_PRE_ORDER' },
  { label: 'Overseas - Made to order', value: 'OVERSEAS_MADETOORDER' },
];

const discounts = [
  { label: 'up to 20% off', value: '-20' },
  { label: '20%-30% off', value: '20-30' },
  { label: '30%-50% off', value: '30-50' },
  { label: '50%-75%+ off', value: '50-' },
];

const newArrivals = [
  { label: 'New today', value: '1' },
  { label: 'New for 7 days', value: '7' },
  { label: 'New for 30 days', value: '30' },
];

export class Chips extends Widget {
  defaultParams = {
    containerClassName: 'simple-presets-occasions-tags',
    ignoreParams: [],
    nonRemovables: [],
    currency: {},
    templates: {
      layout: TEMPLATE,
    },
    onBeforeRender: null,
    onRedered: null,
  };

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(this.params.templates.layout);

    const element = document.createElement('div');
    element.className = this.params.containerClassName;
    this.mainElement = element;

    this.items = [];
  }

  assignEventHandlers() {
    const childElements = this.mainElement.querySelectorAll('li');
    childElements.forEach(childElement => {
      childElement.addEventListener('click', () => {
        const type = childElement.getAttribute('data-type');
        const value = childElement.getAttribute(`data-value`);

        this.handleValueRemoval(type, value);
      });
    });
  }

  didMount() {
    this.renderItems()
  }

  didUpdate() {
    this.renderItems();
  }

  handleValueRemoval(type, value) {
    if (this.params.nonRemovables.includes(type)) return;

    if (!type || !value) return;
    
    const { categoryId } = this.state.filter;
    const newFilter = pick(cloneDeep(this.state.filter), ['occasion', 'mood', 'params', 'presetIndex']);
    const category = this.main.categories[categoryId];
    const isBodyPart = category.partList && category.partList.includes(type);

    if (isBodyPart) newFilter.params[type] = 'all';
    else if (Array.isArray(newFilter.params[type])) remove(newFilter.params[type], v => `${v}` === value);
    else if (Array.isArray(newFilter[type])) remove(newFilter[type], v => `${v}` === value);
    else if (typeof newFilter.params[type] === 'string') newFilter.params[type] = null;

    newFilter.presetIndex = null;

    this.setFilter(newFilter);
  }

  buildItems() {
    const { ignoreParams } = this.params;
    const { lng } = this.state.config;
    const { occasion, presetIndex, params, categoryId } = this.state.filter;
    const category = this.main.categories[categoryId];
    this.items = [];

    const push = (type, value, label) => {
      this.items.push({ type, value, label });
    }

    if (!ignoreParams.includes('preset') && presetIndex) {
      const preset = find(category.presets, { value: presetIndex });
      if (preset)
        push('preset', presetIndex, findLabelByLng(preset.label, lng));
    }

    if (!ignoreParams.includes('occasion') && occasion) {
      [...(Array.isArray(occasion) ? occasion : [occasion])].forEach(value => {
        const { label } = find(category.occasionsList, { value });
        push('occasion', value, findLabelByLng(label, lng));
      });
    }

    Object.entries(params).forEach(([key, value]) => {
      if (!ignoreParams.includes('vibe') && key === 'vibe' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const vibe = find(category.vibesList, { value });
          push('vibe', value, vibe ? findLabelByLng(vibe.label, lng) : value);
        });
      }

      const part = find(category.parts, { name: key });
      if (part && part.isPublished && value !== 'all') {
        push(
          key,
          value,
          `${
            findLabelByLng(category.tn[key][value].label, lng)
          } ${
            findLabelByLng(part.label, lng).toLowerCase()
          }`
        );
      }

      if (!ignoreParams.includes('pattern') && key === 'design') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const pattern = find(category.patterns, { value });
          if (pattern)
            push(key, value, findLabelByLng(pattern.label, lng));
        });
      }
      if (!ignoreParams.includes('material') && key === 'material') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const { label } = find(category.materials, { value });
          push(key, value, findLabelByLng(label, lng));
        });
      }
      if (!ignoreParams.includes('color') && key === 'color') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, capitalize(value));
        });
      }
      if (!ignoreParams.includes('wash') && key === 'wash') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const wash = find(category.wash, { value });
          if (wash)
            push(key, value, findLabelByLng(wash.label, lng));
        });
      }
      if (!ignoreParams.includes('materialText') && key === 'materialText') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, capitalize(value));
        });
      }
      if (!ignoreParams.includes('size') && key === 'size') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, `Size ${value}`);
        });
      }
      if (!ignoreParams.includes('shippingOption') && key === 'shippingOption') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const option = find(shippingOptions, { value });
          push(key, value, option.label);
        });
      }
      if (!ignoreParams.includes('discount') && key === 'discount') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const discount = find(discounts, { value });
          push(key, value, discount.label);
        });
      }
      if (!ignoreParams.includes('newArrivals') && key === 'newArrivals') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const newArrival = find(newArrivals, { value });
          push(key, value, newArrival.label);
        });
      }
      if (!ignoreParams.includes('price') && key === 'price' && value && value !== '0-' && value !== '-') {
        let prices = value.split('-');
        const formattedPrices = prices.map(p => formatCurrency(parseInt(p, 10) || 1, this.params.currency));
        let label = formattedPrices.join(" — ");
        if (!prices[1]) {
          label = formattedPrices[0] + ' or more';
        }
        else if (!prices[0]) {
          label = formattedPrices[1] + ' or less';
        }
        push(key, value, label);
      }
      if (!ignoreParams.includes('brands') && key === 'brands') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, value);
        });
      }
      if (!ignoreParams.includes('subcategory') && key === 'subcategory') {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const subcategory = find(category.subcategories, { value });
          if (subcategory)
            push(key, value, findLabelByLng(subcategory.label, lng));
          else {
            const name = value.split('/').pop();
            push(key, value, name);
          }
        });
      }
    });

    if (!params.subcategory || params?.subcategory.length === 0) {
      push('categoryId', categoryId, findLabelByLng(category.label, lng));
    }
  }

  getItems() {
    return this.items;
  }

  renderItems() {
    this.buildItems();

    let items = this.getItems()
      .map(item => ({
        ...item,
        nonRemovable: this.params.nonRemovables.includes(item.type),
      }));

    if (typeof this.params.onBeforeRender === 'function') {
      items = this.params.onBeforeRender(items) || items;
    }

    this.mainElement.innerHTML = this.template({
      items,
    });

    if (items.length)
      this.mainElement.classList.remove('empty');
    else
      this.mainElement.classList.add('empty');

    this.assignEventHandlers();

    if (typeof this.params.onRedered === 'function') {
      this.params.onRedered(items);
    }
  }

  render() {
    this.renderItems();
    return this.mainElement;
  }
}

export default (params) => {
  return new Chips(params);
};
