import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';

export default function fillPublishedListItem(originalItems = [], publishedItems = []) {
  const list = cloneDeep(publishedItems).map(item => {
    const originalItem = find(originalItems, { value: item.value }) || {};

    return {
      ...originalItem,
      ...item,
    };
  });

  return list;
}
