import Handlebars from 'handlebars';
import isEmpty from 'lodash/isEmpty';
import Widget from '../modules/Widget';
import buttonSvg from '@yesplz/core-models/assets/svg/vf-button-top.svg';
import topIcon from '@yesplz/core-models/assets/svg/vf-button-top.svg';
import shoesIcon from '@yesplz/core-models/assets/svg/vf-button-shoes.svg';
import pantsIcon from '@yesplz/core-models/assets/svg/vf-button-pants.svg';
import skirtIcon from '@yesplz/core-models/assets/svg/vf-button-skirt.svg';
import outerwearIcon from '@yesplz/core-models/assets/svg/vf-button-outerwear.svg';
import wdressIcon from '@yesplz/core-models/assets/svg/vf-button-dress.svg';
import wbagIcon from '@yesplz/core-models/assets/svg/vf-button-women-bag.svg';
import earringIcon from '@yesplz/core-models/assets/svg/vf-button-earring.svg';
import mshoesIcon from '@yesplz/core-models/assets/svg/vf-button-men-shoes.svg';
import mbagsIcon from '@yesplz/core-models/assets/svg/vf-button-men-bag.svg';
import walletIcon from '@yesplz/core-models/assets/svg/vf-button-wallet.svg';
import closeSvg from '@yesplz/core-models/assets/svg/close-black.svg';
import arrowDownSvg from '@yesplz/core-models/assets/svg/angle-down.svg';

const { document } = window;

const HEADER_TEMPLATE = `
<div class="ResponsiveFilter-header">
  <div class="ResponsiveFilter-header-blank"></div>
  <div class="ResponsiveFilter-header-category">
    <select id="category-selector"></select>
    <div id="category-label" class="ResponsiveFilter-header-category-label"></div>
  </div>
  <div id="header-close" class="ResponsiveFilter-header-close">
    <img src="${closeSvg}" alt="" />
  </div>
</div>
`

const LAYOUT = `
  <div id="visual-filter-wrap" class="ResponsiveFilter-wrapper">
    <div class="ResponsiveFilter-container">
      {{{headerCategoryHtml}}}
      <div id="visual-filter"></div>
      <button id="advanced-toggle" class="ResponsiveFilter-advanced-toggle">
        {{advancedFilterLabel}}
        <img scr="${arrowDownSvg}" alt="" />
      </button>
      <div class="ResponsiveFilter-advanced">
        <ul class="ResponsiveFilter-tab-switch" id="tab-switch">
          {{#if params.showEditorsPicks}}
          <li class="active" data-tab="editors-picks">
            <span>{{params.tabLabels.editorsPicks}}
          </li>
          {{/if}}
          <li data-tab="style-filter">
            <span>{{params.tabLabels.styles}}</span>
          </li>
          {{#if params.showDesignFilter}}
          <li class="disabled-lg" data-tab="design-filter">
            <span>{{params.tabLabels.design}}</span>
          </li>
          {{/if}}
          {{#if params.showColorFilter}}
          <li class="disabled-lg" data-tab="color-filter">
            <span>{{params.tabLabels.color}}</span>
          </li>
          {{/if}}
          {{#if params.showMaterialFilter}}
          <li data-tab="material-filter">
            <span>{{params.tabLabels.material}}</span>
          </li>
          {{/if}}
          {{#if params.showPriceFilter}}
          <li data-tab="price-filter">
            <span>{{params.tabLabels.price}}</span>
          </li>
          {{/if}}
        </ul>
        <div class="ResponsiveFilter-tabs" id="tab-contents">
          <div class="active" id="editors-picks"></div>
          <div id="style-filter"></div>
          <div id="design-filter" class="visible-lg"></div>
          <div id="color-filter" class="visible-lg"></div>
          <div id="material-filter"></div>
          <div id="price-filter"></div>
        </div>
      </div>
    </div>
    <div class="ResponsiveFilter-backdrop"></div>
  </div>
  <button id="float-toggle" class="FloatButton"><img src="${buttonSvg}" alt=""></button>
`;

class FullVisualFilter extends Widget {
  defaultParams = {
    showEditorsPicks: true,
    showDesignFilter: true,
    showColorFilter: true,
    showMaterialFilter: true,
    showPriceFilter: true,
    showTooltipsToggler: true,
    defaultTab: null,
    tabLabels: {
      editorsPicks: 'Editors\' Picks',
      stylesAsPrefix: false,
      styles: 'Styles',
      design: 'Design',
      color: 'Colors',
      material: 'Materials',
      price: 'Price',
    },
    advancedFilterLabel: 'Advanced filter',
    visualFilterParams: {},
    editorsPicksParams: {
      title: (this.params.tabLabels && this.params.tabLabels.editorsPicks) || null,
    },
    styleFilterParams: {
      title: (this.params.tabLabels && this.params.tabLabels.styles) || null,
    },
    designFilterParams: {
      title: (this.params.tabLabels && this.params.tabLabels.design) || null,
    },
    colorFilterParams: {
      title: (this.params.tabLabels && this.params.tabLabels.color) || null,
    },
    materialFilterParams: {
      title: (this.params.tabLabels && this.params.tabLabels.material) || null,
    },
    priceFilterParams: {
      title: (this.params.tabLabels && this.params.tabLabels.price) || null,
    },
  };

  constructor(params) {
    super(params);

    this.layoutTemplate = Handlebars.compile(LAYOUT);
    this.headerTemplate = Handlebars.compile(
      params.headerTemplate ?? HEADER_TEMPLATE
    );

    this.wrapContainer = null;
    this.categoryLabel = null;

    this.activeMaterialTabCategories = params.materialFilterParams
      ? params.materialFilterParams
        .materialTypes.reduce((categoryIds, material) => {
          if (material.activeIn) {
            return [ ...categoryIds, ...material.activeIn ];
          }
          return categoryIds;
        }, [])
        .reduce((unique, cat) => (
          unique.includes(cat) ? unique : [...unique, cat]
        ), [])
      : [];
  }

  didUpdate(prevState) {
    if (this.state.filter.category !== prevState.filter.category) {
      this.changeFloatingButtonIcon(this.state.filter.category);
      this.updateCategoryLabel(this.state.filter.categoryId);
    }

    if (this.state.filter.bodyPart !== prevState.filter.bodyPart) {
      this.setStyleTabTitle();
    }
  }

  checkUpdateIndicators() {
    const { filter } = this.state;

    if (this.params.showEditorsPicks) {
      if (this.state.filter.presetIndex) {
        this.displayChangedTabIndicator('editors-picks');
      }
      else {
        this.hideChangedTabIndicator('editors-picks');
      }
    }

    if (this.params.showDesignFilter) {
      if (!isEmpty(filter.params.design)) {
        this.displayChangedTabIndicator('design-filter');
      }
      else {
        this.hideChangedTabIndicator('design-filter');
      }
    }

    if (this.params.showColorFilter) {
      if (!isEmpty(filter.params.color)) {
        this.displayChangedTabIndicator('color-filter');
      }
      else {
        this.hideChangedTabIndicator('color-filter');
      }
    }

    if (this.params.showMaterialFilter) {
      if (filter.params.material && filter.params.material.length > 0) {
        this.displayChangedTabIndicator('material-filter');
      }
      else {
        this.hideChangedTabIndicator('material-filter');
      }
    }
  }

  checkEmptyTabs() {
    const materialTabSwitch = this.wrapContainer.querySelector(`[data-tab="material-filter"]`);
    const colorTabSwitch = this.wrapContainer.querySelector(`[data-tab="color-filter"]`);
    if (materialTabSwitch && this.activeMaterialTabCategories.includes(this.state.filter.categoryId)) {
      materialTabSwitch.classList.remove('disabled');
      materialTabSwitch.classList.remove('last');
      materialTabSwitch.classList.add('last');
    }
    else {
      if (materialTabSwitch) {
        materialTabSwitch.classList.add('disabled');
      }
      if (colorTabSwitch) {
        colorTabSwitch.classList.add('last');
      }
    }
  }

  update() {
    this.checkUpdateIndicators();
    this.checkEmptyTabs();
  }

  didMount() {
    this.wrapContainer = this.container.querySelector('#visual-filter-wrap');

    const closeButton = this.container.querySelector('#header-close');
    if (closeButton) closeButton.addEventListener('click', this.toggleOpen);

    const openButton = this.container.querySelector('#float-toggle');
    openButton.addEventListener('click', this.toggleOpen);

    const advancedToggleButton = this.container.querySelector('#advanced-toggle');
    advancedToggleButton.addEventListener('click', this.advancedToggle);

    const tabSwitch = this.container.querySelector('#tab-switch');
    Array.from(tabSwitch.children).forEach(switcher => {
      switcher.addEventListener('click', (e) => {
        const tab = e.target.tagName === 'SPAN'
          ? e.target.parentNode
          : e.target;
        const tabName = tab.getAttribute('data-tab');
        this.changeTab(tabName);
      });
    });

    this.categoryLabel = this.wrapContainer.querySelector('#category-label');
    this.fillCategories();

    this.checkUpdateIndicators();
    this.setStyleTabTitle();

    this.main.on('presetChange', () => {
      this.changeTab('editors-picks');
    });
  }

  changeTab(tabName) {
    const activeTabSwitch = document.querySelector('#tab-switch .active');
    if (activeTabSwitch)
      activeTabSwitch.classList.remove('active');

    const nextTabSwitch = document.querySelector(`[data-tab="${tabName}"]`);
    if (nextTabSwitch)
      nextTabSwitch.classList.add('active');

    const activeTab = document.querySelector('#tab-contents > div.active');
    if (activeTab)
      activeTab.classList.remove('active');

    const nextTab = document.querySelector(`#tab-contents > #${tabName}`);
    if (nextTab)
      nextTab.classList.add('active');
  }

  setStyleTabTitle() {
    const { categoryId, bodyPart } = this.state.filter;
    const category = this.main.categories[categoryId];
    const styleTabSwitch = document.querySelector(`[data-tab="style-filter"]`);
    if (category && category.partListLabels) {
      styleTabSwitch.innerHTML = `<span>${
        category.partListLabels[category.partList.indexOf(bodyPart)]
      }</span>`;
    }
    else {
      styleTabSwitch.innerHTML = `<span>${this.params.tabLabels.styles}</span>`;
    }
  }

  displayChangedTabIndicator(tabName) {
    if (!this.wrapContainer) return;
    const tabSwitch = this.wrapContainer.querySelector(`[data-tab="${tabName}"]`);
    tabSwitch.classList.add('changed');
  }

  hideChangedTabIndicator(tabName) {
    if (!this.wrapContainer) return;
    const tabSwitch = this.wrapContainer.querySelector(`[data-tab="${tabName}"]`);
    tabSwitch.classList.remove('changed');
  }

  fillCategories() {
    const categories = this.main.categories;
    const select = this.wrapContainer.querySelector('#category-selector');

    if (!select) return;

    Object.keys(categories).forEach((categoryId, index) => {
      if (!index || this.state.filter.categoryId === categoryId) {
        this.updateCategoryLabel(categoryId);
      }

      const option = document.createElement('option');
      if (this.state.filter.categoryId === categoryId) {
        option.selected = true;
      }
      option.value = categoryId;
      option.innerText = categories[categoryId].label;
      select.appendChild(option);
    });

    select.addEventListener('change', (e) => {
      const categoryId = e.target.value;
      this.main.setCategory(categoryId);
      this.updateCategoryLabel(categoryId);
    });
  }

  updateCategoryLabel(categoryId) {
    if (!this.categoryLabel || !this.main.categories[categoryId]) return;
    this.categoryLabel.innerHTML = this.main.categories[categoryId].label;
  }

  changeFloatingButtonIcon(category) {
    function getIcon(category) {
      switch (category) {
        case 'wshoes':
          return shoesIcon;
        case 'wpants':
          return pantsIcon;
        case 'mpants':
          return pantsIcon;
        case 'wskirt':
          return skirtIcon;
        case 'wouter':
          return outerwearIcon;
        case 'mouter':
          return outerwearIcon;
        case 'wdress':
          return wdressIcon;
        case 'wearring':
          return earringIcon;
        case 'wbag':
          return wbagIcon;
        case 'mshirts':
          return topIcon;
        case 'mshoes':
          return mshoesIcon;
        case 'mbag':
          return mbagsIcon;
        case 'mwallet':
          return walletIcon;
        case 'wwallet':
          return walletIcon;
        default:
          return topIcon;
      }
    }

    if (!this.wrapContainer) return null;

    const imgSrc = getIcon(category);
    document.querySelector('#float-toggle img')
      .setAttribute('src', imgSrc);
  }

  toggleOpen = () => {
    if (this.wrapContainer.classList.contains('active')) {
      this.wrapContainer.classList.remove('active', 'advanced-opened');
    }
    else {
      window.scrollTo(0, 0);
      this.wrapContainer.classList.add('active');
    }
  }

  advancedToggle = () => {
    this.wrapContainer.classList.toggle('advanced-opened');
  }

  render() {
    const element = document.createElement('div');

    element.className = 'ResponsiveFilter';

    element.innerHTML = this.layoutTemplate({
      headerCategoryHtml: this.headerTemplate(),
      advancedFilterLabel: this.params.advancedFilterLabel,
      params: this.params,
    });

    setTimeout(() => {
      this.main.addWidget(
        this.main.widgets.VisualFilter({
          container: '#visual-filter',
          onFilterChange: () => {
            this.changeTab('style-filter');
          },
          showTooltipsToggler: this.params.showTooltipsToggler,
          ...this.params.visualFilterParams,
          onSVGLoaded: () => {
            // TODO: Review this part, edge case, when editor's picks are disabled
            if (this.params.defaultTab) {
              this.changeTab(this.params.defaultTab);
            }
            else if (this.params.showEditorsPicks) {
              this.changeTab('editors-picks');
            }

            if (this.params.visualFilterParams.onSVGLoaded) {
              this.params.visualFilterParams.onSVGLoaded();
            }
          },
        })
      );

      this.main.addWidget(
        this.main.widgets.StyleFilter({
          container: '#style-filter',
          ...this.params.styleFilterParams,
        })
      );

      if (this.params.showEditorsPicks) {
        this.main.addWidget(
          this.main.widgets.EditorsPicks({
            container: '#editors-picks',
            ...this.params.editorsPicksParams,
          })
        );
      }

      if (this.params.showDesignFilter) {
        this.main.addWidget(
          this.main.widgets.PatternFilter({
            container: '#design-filter',
            ...this.params.designFilterParams,
          })
        );
      }

      if (this.params.showColorFilter) {
        this.main.addWidget(
          this.main.widgets.ColorFilter({
            container: '#color-filter',
            ...this.params.colorFilterParams,
          })
        );
      }

      if (this.params.showMaterialFilter) {
        this.main.addWidget(
          this.main.widgets.MaterialFilter({
            container: '#material-filter',
            ...this.params.materialFilterParams,
          })
        );
      }

      if (this.params.showPriceFilter) {
        this.main.addWidget(
          this.main.widgets.PriceRangeFilter({
            container: '#price-filter',
            ...this.params.priceFilterParams,
          })
        );
      }
    }, 10);

    return element;
  }
}

export default (params) => {
  return new FullVisualFilter(params);
};
