export const USE_TOOLTIP = process.env.REACT_APP_HIDE_TOOLTIP !== 'true';

export const REVIEW_CATEGORIES = [
  { "label": "Women All", "category": "women" },
  { "label": "Women Tops", "category": "wtop" },
  { "label": "Women Dresses", "category": "wdress" },
  { "label": "Women Outerwears", "category": "wouter" },
  { "label": "Women Knits", "category": "wknit" },
  { "label": "Women Pants", "category": "wpants" },
  { "label": "Women Skirts", "category": "wskirt" },
  { "label": "Women Dress Shoes", "category": "wdressshoes" },
  { "label": "Women Sneakers", "category": "wsneakers" },
  { "label": "Women Bags", "category": "wbag" },
  { "label": "Women Wallets", "category": "wwallet" },
  { "label": "Women Earrings", "category": "wearring" },
  { "label": "Men All", "category": "men" },
  { "label": "Men T-Shirts", "category": "mtshirts" },
  { "label": "Men Shirts", "category": "mshirts" },
  { "label": "Men Outerwears", "category": "mouter" },
  { "label": "Men Knits", "category": "mknit" },
  { "label": "Men Pants", "category": "mpants" },
  { "label": "Men Dress Shoes", "category": "mdressshoes" },
  { "label": "Men Sneakers", "category": "msneakers" },
  { "label": "Men Bags", "category": "mbag" },
  { "label": "Men Wallets", "category": "mwallet" },
];
