import axios from 'axios'
import camelcaseKeys from 'camelcase-keys';

const SHOULD_BE_REFETCHED = 'favorites/SHOULD_BE_REFETCHED';
const FETCHING_STARTED = 'favorites/FETCHING_STARTED';
const FETCHING_SUCCESS = 'favorites/FETCHING_SUCCESS';
const FETCHING_ERROR = 'favorites/FETCHING_ERROR';

const initialState = {
  shouldBeRefetched: true,
  fetching: false,
  fetchingError: null,
  products: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCHING_STARTED:
      return {
        ...state,

      };
    case FETCHING_SUCCESS:
      return {
        ...state,
        products: [ ...action.products ],
      };
    case FETCHING_ERROR:
      return {
        ...state,
        fetchingError: true,
      };
    case SHOULD_BE_REFETCHED:
      return {
        ...state,
        shouldBeRefetched: true,
      };
    default:
      return state;
  }
}

export function shouldBeRefetched() {
  return { type: SHOULD_BE_REFETCHED };
}

export function fetchFavorites() {
  return async (dispatch, getState) => {
    const { favoritesStorage } = getState();
    console.log('fetchFavorites');
    console.log(favoritesStorage.products);

    dispatch({ type: FETCHING_STARTED});

    const idToCategoryIdMap = new Map();
    try {
      const response = await axios.get('/products', {
        params: {
          productId: Object.entries(favoritesStorage.products)
            .reduce((allIds, [categoryId, ids]) => {
              ids.forEach(id => idToCategoryIdMap.set(id, categoryId));
              return allIds.concat(ids);
            }, [])
            .join(','),
        }
      });

      const products = response.data.results.map(product => (camelcaseKeys({
        ...product,
        categoryId: idToCategoryIdMap.get(product.productId),
      }, { deep: true })));

      dispatch({
        type: FETCHING_SUCCESS,
        products,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: FETCHING_ERROR });
    }
  };
}
