import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import { categories as defaultCategories } from 'config/vf-config.js';

const listProps = ['materials', 'moodsList', 'occasionsList', 'patterns', 'subtypesList', 'vibesList', 'presetsList', 'wash'];

const updatedBaseCategories = {
  "wshirts":"wtop",
  "wtshirts":"wtop",
  "wblouse":"wtop",
  "wtrenchcoats":"wcoats",
  "wjumpers":"wouter",
  "wjackets":"wouter",
  "wtrousers":"wpants",
  "wjeans":"wpants",
  "wjeans2":"wpants2",
  "wknit":"wpullover",
  "wknitvests":"wpullover",
  "mtrenchcoats":"mcoats",
  "mjackets":"mouter",
  "mjumpers":"mouter",
  "mknitvests":"mpullover",
  "mknit":"mpullover",
  "mtrousers":"mpants",
  "mjeans":"mpants",
};

export default function cleanConfiguration(configuration) {
  return Object.entries(configuration).reduce((categories, [categoryId, categoryBase]) => {
    const category = cloneDeep(categoryBase);
    delete category.vfSvgSource;
    delete category.overrideBodyPartGroupName;
    delete category.defaultVal;

    if (category.baseCategory && (defaultCategories[category.baseCategory] && !defaultCategories[category.baseCategory].tnSpriteOptions)) {
      delete category.tnSprite;
    }

    if (category.presetList && !category.presetsList) {
      category.presetsList = category.presetList;
    }

    delete category.presetList;
    delete category.tnSpriteOptions;

    category.baseCategory = updatedBaseCategories[category.baseCategory] || category.baseCategory;

    /**
     * Update TN labels
     */
    // if (category.tn)
    //   Object.keys(category.tn).forEach(part => {
    //     Object.keys(category.tn[part]).forEach(tnKey => {
    //       category.tn[part][tnKey].label = defaultCategories[category.baseCategory].tn[part][tnKey].label;
    //     });
    //   });
    /** end */

    listProps.forEach(listName => {
      if (category[listName] && Array.isArray(category[listName])) {
        category[listName] = category[listName].map(item => pick(item, ['label', 'value', 'name', 'preset']));
      }
    });

    if (category.tn) {
      Object.keys(category.tn).forEach(part => {
        delete category.tn[part].all;

        Object.keys(category.tn[part]).forEach(value => {
          if (category.tn[part][value].tnClass) {
            delete category.tn[part][value].tnClass;
          }
        });
      });
    }

    categories[categoryId] = category;

    return categories;
  }, {});
}
