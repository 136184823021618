const sortBy = require('lodash/sortBy');

function buildParentIndex(categories) {
  const parentIndex = {};
  categories.forEach((category, index) => {
    const parentId = category.parentId ? category.parentId : 'root';
    if (!parentIndex[parentId]) {
      parentIndex[parentId] = [];
    }
    parentIndex[parentId].push({
      categoryId: category.categoryId,
      index: index,
    });
  });
  return parentIndex;
}

function buildTree(categories) {
  const parentIndex = buildParentIndex(categories);

  function buildItem(category) {
    return {
      categoryId: category.categoryId,
      label: category.label,
      sorting: category.sorting,
      isPublished: category.isPublished,
      children: buildChildren(category.categoryId),
    };
  }

  function buildChildren(categoryId) {
    const childrenIndex = parentIndex[categoryId];
    if (childrenIndex) {
      return sortBy(childrenIndex.map(({ index }) => {
        const category = categories[index];
        return buildItem(category);
      }), 'sorting');
    }
    return null;
  }

  const items = [];
  (parentIndex?.root || []).forEach(({ index }) => {
    const category = categories[index];
    items.push(buildItem(category));
  });
  return sortBy(items, 'sorting');
}

module.exports = buildTree;