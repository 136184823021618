import axios from 'axios'
import findIndex from 'lodash/findIndex'

// Actions
const REQUEST_ADMIN_PRESETS = 'admin/REQUEST_ADMIN_PRESETS'
const SET_ADMIN_PRESETS = 'admin/SET_ADMIN_PRESETS'
const SYNC_ADMIN_PRESETS = 'admin/SYNC_ADMIN_PRESETS'
const REQUEST_ADD_ADMIN_PRESET = 'admin/REQUEST_ADD_ADMIN_PRESET'
const ADD_ADMIN_PRESET = 'admin/ADD_ADMIN_PRESET'
const DELETE_ADMIN_PRESET = 'admin/DELETE_ADMIN_PRESET'
const PUBLISH_ADMIN_PRESET = 'admin/PUBLISH_ADMIN_PRESET'
const UNPUBLISH_ADMIN_PRESET = 'admin/UNPUBLISH_ADMIN_PRESET'
const SET_ACTIVE_ADMIN_PRESET = 'admin/SET_ACTIVE_ADMIN_PRESET'

// Dashboard settings
const SET_DASHBOARD_SETTING = 'admin/SET_DASHBOARD_SETTING'
const SET_DASHBOARD_ACTIVE = 'admin/SET_DASHBOARD_ACTIVE'

const defaultState = {
  loading: false,
  presets: [],
  activePreset: null,
  setting: {
    published: {
      types: []
    },
    all: {
      types: []
    },
    active: 'published'
  }
}

// Reducer
export default function reducer (state = defaultState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case REQUEST_ADMIN_PRESETS:
      return {
        ...state,
        loading: true
      }
    case SET_ADMIN_PRESETS:
      return {
        ...state,
        loading: false,
        presets: payload.presets
      }
    case SYNC_ADMIN_PRESETS:
      return {
        ...state,
        loading: false,
        presets: [ ...state.presets ]
      }
    case SET_ACTIVE_ADMIN_PRESET:
      return {
        ...state,
        activePreset: payload.preset
      }
    case REQUEST_ADD_ADMIN_PRESET:
      return {
        ...state,
        loading: true
      }
    case ADD_ADMIN_PRESET:
      return {
        ...state,
        loading: false,
        presets: [ ...state.presets, payload.preset ]
      }
    case DELETE_ADMIN_PRESET:
      const presetID = findIndex(state.presets, p => p.id === payload.presetId)
      const presets = [ ...state.presets ]
      if (presetID > -1) {
        presets.splice(presetID, 1)
      }
      return {
        ...state,
        presets
      }
    case PUBLISH_ADMIN_PRESET:
      const publishedPreset = findIndex(state.presets, p => p.id === payload.presetId)
      const presetsByPublish = [ ...state.presets ]
      if (publishedPreset > -1) {
        presetsByPublish[publishedPreset].published = true
      }
      return {
        ...state,
        presets: presetsByPublish
      }
    case UNPUBLISH_ADMIN_PRESET:
      const unPublishedPreset = findIndex(state.presets, p => p.id === payload.presetId)
      const presetsByUnPublish = [ ...state.presets ]
      if (unPublishedPreset > -1) {
        presetsByUnPublish[unPublishedPreset].published = false
      }
      return {
        ...state,
        presets: presetsByUnPublish
      }
    case SET_DASHBOARD_SETTING:
      return {
        ...state,
        setting: { ...state.setting, [payload.tab]: payload.settings }
      }
    case SET_DASHBOARD_ACTIVE:
      return {
        ...state,
        setting: { ...state.setting, active: payload.tab }
      }
    default: return state
  }
}

export const setDashboardActive = (tab) => ({
  type: SET_DASHBOARD_ACTIVE,
  payload: { tab }
})
export const setDashboardSettings = (tab, settings) => ({
  type: SET_DASHBOARD_SETTING,
  payload: { tab, settings }
})

export const requestPresets = () => ({ type: REQUEST_ADMIN_PRESETS })
export const requestAddPreset = () => ({ type: REQUEST_ADD_ADMIN_PRESET })
export const removePreset = (presetId) => ({
  type: DELETE_ADMIN_PRESET,
  payload: { presetId }
})
export const publishPreset = (presetId) => ({
  type: PUBLISH_ADMIN_PRESET,
  payload: { presetId }
})
export const unpublishPreset = (presetId) => ({
  type: UNPUBLISH_ADMIN_PRESET,
  payload: { presetId }
})

export const setPresets = (presets) => ({
  type: SET_ADMIN_PRESETS,
  payload: { presets }
})

export const syncPresets = () => ({
  type: SYNC_ADMIN_PRESETS
})

export const setActivePreset = (preset) => ({
  type: SET_ACTIVE_ADMIN_PRESET,
  payload: { preset }
})

export const addPreset = (preset) => ({
  type: ADD_ADMIN_PRESET,
  payload: { preset }
})

// Fetch Preset data

export const fetchPresets = () => {
  return async (dispatch) => {
    dispatch(requestPresets())
    try {
      const response = await axios.get('/admin/presets')
      dispatch(setPresets(response.data))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}

export const fetchAddPreset = (preset) => {
  return async (dispatch) => {
    dispatch(requestAddPreset())
    try {
      const response = await axios.post('/admin/presets', { ...preset })
      dispatch(addPreset(response.data))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}

export const fetchRemovePreset = (presetId) => {
  return async (dispatch) => {
    try {
      await axios.delete(`/admin/presets/${presetId}`)
      dispatch(removePreset(presetId))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}

export const fetchPublishPreset = (presetId) => {
  return async (dispatch) => {
    try {
      await axios.put(`/admin/presets/${presetId}/publish`, null)
      dispatch(publishPreset(presetId))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}

export const fetchUnPublishPreset = (presetId) => {
  return async (dispatch) => {
    try {
      await axios.delete(`/admin/presets/${presetId}/publish`)
      dispatch(unpublishPreset(presetId))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}
