import axios from 'axios'
import { CATEGORY_WTOP, CATEGORY_WSHOES, CATEGORY_WPANTS, CATEGORY_MPANTS, CATEGORY_MSHIRTS, FILTERS,
  CATEGORY_MOUTER, CATEGORY_MKNIT, CATEGORY_MSHOES, CATEGORY_MWALLET, CATEGORY_MTSHIRTS,
  CATEGORY_MBAG, CATEGORY_WSKIRT, CATEGORY_WOUTER, CATEGORY_WDRESS, CATEGORY_WEARRING,
  CATEGORY_WKNIT, CATEGORY_WWALLET, CATEGORY_WBAG
} from '@yesplz/core-web/config/constants'
import { Preset, VisualFilter } from '@yesplz/core-models'
import { getCatCfg } from '@yesplz/core-models/src/VFCatCfg'
import { updatePresetFavorite, mapPresetFavorites } from './helpers'
import reduce from 'lodash/reduce'

const { localStorage } = window
const isDev = process.env.NODE_ENV === 'development'

// Actions
const SET_FILTER = 'filters/SET_FILTER'
const SYNC_FILTER = 'filters/SYNC_FILTER'
const SET_SECONDARY_FILTER = 'filters/SET_SECONDARY_FILTER'
const SET_PRESETS = 'filters/SET_PRESETS'
const SET_FAVORITE_PRESETS = 'filters/SET_FAVORITE_PRESETS'
export const LIKE_PRESET = 'filters/LIKE_PRESET'
export const UNLIKE_PRESET = 'filters/UNLIKE_PRESET'
const SET_LAST_BODY_PART = 'filters/SET_LAST_BODY_PART'
const TOOGLE_VISUAL_FILTER = 'filters/TOOGLE_VISUAL_FILTER'
const SET_ONBOARDING = 'filters/SET_ONBOARDING'
const SET_DESKTOP_ONBOARDING = 'filters/SET_DESKTOP_ONBOARDING'

const shouldShowDesktopOnboarding = () => {
  const getConfig = () => {
    try {
      return localStorage.getItem('desktop_onboarded')
    } catch (e) {
      console.log(e)
    }

    return null
  }

  return !getConfig()
}

const defaultState = {
  // visual filters
  [CATEGORY_WTOP]: {
    data: {
      ...getCatCfg(CATEGORY_WTOP).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_WSHOES]: {
    data: {
      ...getCatCfg(CATEGORY_WSHOES).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_WPANTS]: {
    data: {
      ...getCatCfg(CATEGORY_WPANTS).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_MPANTS]: {
    data: {
      ...getCatCfg(CATEGORY_MPANTS).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_MSHIRTS]: {
    data: {
      ...getCatCfg(CATEGORY_MSHIRTS).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_MKNIT]: {
    data: {
      ...getCatCfg(CATEGORY_MKNIT).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_MOUTER]: {
    data: {
      ...getCatCfg(CATEGORY_MOUTER).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_MTSHIRTS]: {
    data: {
      ...getCatCfg(CATEGORY_MTSHIRTS).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_MSHOES]: {
    data: {
      ...getCatCfg(CATEGORY_MSHOES).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_MWALLET]: {
    data: {
      ...getCatCfg(CATEGORY_MWALLET).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_MBAG]: {
    data: {
      ...getCatCfg(CATEGORY_MBAG).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_WSKIRT]: {
    data: {
      ...getCatCfg(CATEGORY_WSKIRT).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_WOUTER]: {
    data: {
      ...getCatCfg(CATEGORY_WOUTER).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_WDRESS]: {
    data: {
      ...getCatCfg(CATEGORY_WDRESS).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_WEARRING]: {
    data: {
      ...getCatCfg(CATEGORY_WEARRING).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_WKNIT]: {
    data: {
      ...getCatCfg(CATEGORY_WKNIT).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_WWALLET]: {
    data: {
      ...getCatCfg(CATEGORY_WWALLET).propDefaultVal,
      sale: 0,
      color: ''
    }
  },
  [CATEGORY_WBAG]: {
    data: {
      ...getCatCfg(CATEGORY_WBAG).propDefaultVal,
      sale: 0,
      color: ''
    }
  },

  // secondary filters such as ocasions, prices, sales, etc
  secondary: {},
  // stuff filters such as ...
  stuff: {
  },
  lastBodyPart: VisualFilter.getLastBodyPart(),
  presets: [],
  favoritePresets: [],
  presetsFetched: false,
  expanded: false,
  onboarding: VisualFilter.shouldShowOnboarding(),
  onboardDesktop: shouldShowDesktopOnboarding()
}

// Reducer
export default function reducer (state = defaultState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SET_FILTER:
      if (!payload.filters) {
        return state
      }
      return { ...state, [payload.category]: { data: payload.filters } }
    case SYNC_FILTER:
      if (!payload.filters) {
        return state
      }
      return {
        ...state,
        ...reduce(payload.filters, (acc, filters, category) => ({
          ...acc,
          [category]: {
            data: filters
          }
        }), {})
      }
    case SET_SECONDARY_FILTER:
      return { ...state, secondary: payload.filters }
    case SET_PRESETS:
      return {
        ...state,
        presets: mapPresetFavorites(payload.favoritePresetNames, payload.presets),
        presetsFetched: true
      }
    case SET_LAST_BODY_PART:
      return {
        ...state,
        lastBodyPart: payload.lastBodyPart
      }
    case LIKE_PRESET:
      return {
        ...state,
        presets: updatePresetFavorite(payload.presetName, true, state.presets)
      }
    case UNLIKE_PRESET:
      return {
        ...state,
        presets: updatePresetFavorite(payload.presetName, false, state.presets)
      }
    case SET_FAVORITE_PRESETS:
      return {
        ...state,
        favoritePresets: payload.favoritePresets
      }
    case TOOGLE_VISUAL_FILTER:
      return {
        ...state,
        expanded: payload.expanded
      }
    case SET_ONBOARDING:
      return {
        ...state,
        onboarding: payload.onboarding
      }
    case SET_DESKTOP_ONBOARDING:
      return {
        ...state,
        onboardDesktop: payload.onboardDesktop
      }
    default: return state
  }
}

// Actions creator
export function setFilter (category, filters) {
  const prevSavedFilters = JSON.parse(localStorage.getItem(FILTERS))
  // save to local storage
  localStorage.setItem(FILTERS, JSON.stringify({
    ...prevSavedFilters,
    [category]: filters
  }))

  return { type: SET_FILTER, payload: { category, filters } }
}

export function setSecondaryFilter (filters) {
  return { type: SET_SECONDARY_FILTER, payload: { filters } }
}

export function setPresets (presets, favoritePresetNames) {
  return { type: SET_PRESETS, payload: { presets, favoritePresetNames } }
}

export function setLastBodyPart (lastBodyPart) {
  // set to localStorage
  VisualFilter.saveLastBodyPart(lastBodyPart)
  return { type: SET_LAST_BODY_PART, payload: { lastBodyPart } }
}

/**
 * sync filters data from local storage to store
 */
export function syncFilter () {
  const filters = JSON.parse(localStorage.getItem(FILTERS))
  return { type: SYNC_FILTER, payload: { filters } }
}

/**
 * sync favorite presets data from local storage to store
 */
export function syncFavoritePresets () {
  const favoritePresets = Preset.getFavoritePresets()
  return { type: SET_FAVORITE_PRESETS, payload: { favoritePresets } }
}

export function toggleVisualFilter (expanded = true) {
  return { type: TOOGLE_VISUAL_FILTER, payload: { expanded } }
}

export function setOnboarding (onboarding = true) {
  return { type: SET_ONBOARDING, payload: { onboarding } }
}

export function setDesktopOnboarding (onboardDesktop = true) {
  return { type: SET_DESKTOP_ONBOARDING, payload: { onboardDesktop } }
}

/**
 * get list of presets available
 */
export function fetchPresets (category, filters) {
  return async dispatch => {
    try {
      let url = '/allcategories/editorspicks'

      if (category) {
        url = `/categories/${category}/editorspicks`
      }

      const response = await axios.get(url, {
        params: {
          ...filters || {}
        }
      })

      const favoritePresetNames = Preset.getFavoritePresetNames()
      dispatch(setPresets(response.data, favoritePresetNames))

      return response
    } catch (e) {
      console.log('Error!', e)
    }
  }
}

export function likePreset (preset, tracker = {}) {
  return (dispatch) => {
    Preset.like(preset)
    // sync presets from local storage, temporary solutions before api ready
    dispatch(syncFavoritePresets())

    dispatch({
      type: LIKE_PRESET,
      payload: { presetName: preset.name },
      meta: {
        mixpanel: {
          event: 'Like Preset',
          props: {
            ...tracker.defaultProperties,
            name: preset.name
          }
        }
      }
    })
  }
}

export function unlikePreset (preset, tracker = {}) {
  return (dispatch) => {
    Preset.unlike(preset)

    // sync presets from local storage, temporary solutions before api ready
    dispatch(syncFavoritePresets())

    dispatch({
      type: UNLIKE_PRESET,
      payload: { presetName: preset.name },
      meta: {
        mixpanel: {
          event: 'Unlike Preset',
          props: {
            ...tracker.defaultProperties,
            name: preset.name
          }
        }
      }
    })
  }
}

export function saveFilterAsPreset (preset, name) {
  return dispatch => {
    Preset.savePreset(preset, name)
    // sync presets from local storage, temporary solutions before api ready
    dispatch(syncFavoritePresets())

    dispatch({
      type: LIKE_PRESET,
      payload: { presetName: preset.name },
      meta: {
        mixpanel: {
          event: 'Like Preset',
          props: {
            dev: isDev,
            name: 'Custom Preset',
            preset: preset
          }
        }
      }
    })
  }
}

export function deleteFilterFromPreset(preset, name, key) {

  return dispatch => {
    Preset.removePreset(preset, name, key)
    // sync presets from local storage, temporary solutions before api ready
    dispatch(syncFavoritePresets())

    dispatch({
      type: UNLIKE_PRESET,
      payload: { presetName: name },
      meta: {
        mixpanel: {
          event: 'Unlike Preset',
          props: {
            dev: isDev,
            name: name
          }
        }
      }
    })
  }
}
