import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import Widget from '../modules/Widget';
import { findLabelByLng } from '@yesplz/core';

class BodyPartList extends Widget {
  defaultParams = {
    items: [],
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.ul = document.createElement('ul');

    this.mainElement.appendChild(this.ul);
  }

  didMount() {
    this.renderOptions();
  }

  didUpdate(prevState) {
    const listName = this.params.listName;
    if (prevState.filter.params[listName] !== this.state.filter.params[listName]) {
      let el = this.mainElement.querySelector(`[data-body-part-${listName}-id="${this.state.filter.params[listName]}"]`);
      if (el) {
        el.classList.add('active');
      }
      
      el = this.mainElement.querySelector(`[data-body-part-${listName}-id="${prevState.filter.params[listName]}"]`);
      if (el) {
        el.classList.remove('active');
      }
    }
  }

  handleItemClick = (e, value) => {
    e.stopPropagation();
    const listName = this.params.listName;
    const { params } = this.state.filter;
    const newParams = { ...params };

    if (newParams[listName] === value) {
      newParams[listName] = 'all';
    }
    else {
      newParams[listName] = value;
    }
    this.setFilter({
      params: newParams,
    });
  }

  renderOptions() {
    const { lng } = this.state.config;
    const { params } = this.state.filter;
    const listName = this.params.listName;
    this.ul.innerHTML = '';

    this.params.items.forEach((option, refIndex) => {
      const li = document.createElement('li');
      li.innerHTML = findLabelByLng(option.label, lng);
      li.setAttribute(`data-body-part-${listName}-id`, option.value);
      this.ul.appendChild(li);

      if (params[listName] && (params[listName] === option.value || params[listName].includes(option.value))) {
        li.classList.add('active');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, option.value));
    });
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new BodyPartList(params);
};
