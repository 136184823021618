import SearchBar from './SearchBar';
import CollapsibleFilters from './CollapsibleFilters';
import ProductsList from './ProductsList';
import SortBy from './SortBy';
import PageSize from './PageSize';
import Pagination from './Pagination';
import Categories from './Categories';
import BrandFilter from './BrandFilter';
import ColorFilter from './ColorFilter';
import MaterialTextList from './MaterialTextList';
import ListFilter from './ListFilter';
import PriceRangeFilter from './PriceRangeFilter';
import Chips from './Chips';
import TotalFound from './TotalFound';
import MobileTabbedFilters from './MobileTabbedFilters';
import SaleCheckbox from './SaleCheckbox';
import BodyPartsList from './BodyPartsList';
import PatternsFilter from './PatternsFilter';
import DynamicTreeViewMenu from './DynamicTreeViewMenu';
import InternalCategoriesFilter from './InternalCategoriesFilter';
import AiMessage from './AiMessage';
import DualSearchBar from '@yesplz/core/src/wconcept/widgets/DualSearchBar';

export const textSearchWidgets = {
  SearchBar,
  CollapsibleFilters,
  ProductsList,
  SortBy,
  PageSize,
  Pagination,
  Categories,
  BrandFilter,
  ColorFilter,
  MaterialTextList,
  ListFilter,
  PriceRangeFilter,
  Chips,
  TotalFound,
  MobileTabbedFilters,
  SaleCheckbox,
  BodyPartsList,
  PatternsFilter,
  DynamicTreeViewMenu,
  InternalCategoriesFilter,
};

export const aiSearchWidgets = {
  CollapsibleFilters,
  ProductsList,
  PageSize,
  Pagination,
  Categories,
  BrandFilter,
  ColorFilter,
  MaterialTextList,
  ListFilter,
  PriceRangeFilter,
  Chips,
  TotalFound,
  MobileTabbedFilters,
  SaleCheckbox,
  BodyPartsList,
  PatternsFilter,
  DynamicTreeViewMenu,
  InternalCategoriesFilter,
  AiMessage,
}

export const dualSearchWidgets = {
  DualSearchBar,
  CollapsibleFilters,
  ProductsList,
  SortBy,
  PageSize,
  Pagination,
  Categories,
  BrandFilter,
  ColorFilter,
  MaterialTextList,
  ListFilter,
  PriceRangeFilter,
  Chips,
  TotalFound,
  MobileTabbedFilters,
  SaleCheckbox,
  BodyPartsList,
  PatternsFilter,
  DynamicTreeViewMenu,
  InternalCategoriesFilter,
  AiMessage,
}

