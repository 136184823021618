import Handlebars from 'handlebars';
import Widget from '../modules/Widget';
import aiStylistIcon from '../assets/ai-stylist-icon.svg';


const { document } = window;

const TEMPLATE = `
  <img src={{icon}} alt="AI Stylist Icon"/>
  <div class="yesplz-ai-message">
    <p>{{message}}</p>
    <div class="yesplz-ai-message-loader">
      <span class="yesplz-ai-message-loader-text">One sec...</span>
      <div class="skeleton-container">
        <div class="skeleton"></div>
        <div class="skeleton"></div>
      </div>
    </div>
  </div>
`;

export class AiMessage extends Widget {
  defaultParams = {
    containerClassName: 'yesplz-ai-message-container',
    templates: {
      layout: TEMPLATE,
    },
    onBeforeRender: null,
    onRendered: null,
    icon: aiStylistIcon,
  };

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(this.params.templates.layout);

    const element = document.createElement('div');
    element.className = this.params.containerClassName;
    this.mainElement = element;

    this.message = '';
  }

  didUpdate(prevState) {
    if (this.state.search.failed !== prevState.search.failed && this.state.search.failed) {
      this.setMessage("Oops! We couldn't complete your search - please check your internet connection and try again.")
    } else if (this.state.search.message !== prevState.search.message) {
      this.setMessage(this.state.search.message);
    }

    if (this.state.search.isLoading !== prevState.search.isLoading) {
      if (this.state.search.isLoading && !this.state.search.results) {
        this.mainElement.classList.add('is-loading');
      } else if (!this.state.search.isLoading) {
        this.mainElement.classList.remove('is-loading');
      }
    }
  }

  setMessage(message) {
    this.message = message;
    this.renderMessage();
  }

  renderMessage() {
    let message = this.message;

    if (typeof this.params.onBeforeRender === 'function') {
      message = this.params.onBeforeRender(message) || message;
    }

    this.mainElement.innerHTML = this.template({
      message,
      icon: this.params.icon,
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(message);
    }
  }

  render() {
    if (this.state.filter.searchMode === 'ai') {
      this.renderMessage();
      return this.mainElement;
    }
    return null;
  }
}

export default (params) => {
  return new AiMessage(params);
};
