const subscribers = {};
let state = {
  searchMode: "text",
};
export default {
  emit(event, ...args) {
    if (subscribers.hasOwnProperty(event)) {
      if (state.searchMode === "ai" && event.startsWith("txtSidebar")) {
        event = event.replace("txtSidebar", "AISearchSidebar");
      }
      subscribers[event].forEach((subscriber) => {
        subscriber(...args);
      });
    }
  },
  on(event, subscriber) {
    if (!subscribers.hasOwnProperty(event)) {
      subscribers[event] = [];
    }
    subscribers[event].push(subscriber);
  },
  off(event, subscriber) {
    if (!subscribers.hasOwnProperty(event)) {
      subscribers[event] = [];
    }
    subscribers[event] = subscribers[event].filter(
      (attached) => attached !== subscriber
    );
  },
  setState(newState) {
    state = { ...state, ...newState };
  },
};
