import Handlebars from 'handlebars';
import Widget from '../modules/Widget';

const { document } = window;

class CategoryTitle extends Widget {
  defaultParams = {
    templates: {
      title: '<h3>{{title}}</h3>',
    }
  };

  constructor(params) {
    super(params);

    const element = document.createElement('span');
    element.className = this.params.containerClassName;
    this.mainElement = element;

    this.titleTemplate = Handlebars.compile(this.params.templates.title);
  }

  didUpdate(prevState) {
    if (
      this.state.filter.categoryId !== prevState.filter.categoryId
      ||
      this.state.config.layoutTitle !== prevState.config.layoutTitle
    ) {
      this.render();
    }
  }

  render() {
    const { categoryId } = this.state.filter;
    const category = this.main.categories[categoryId];
    this.mainElement.innerHTML = this.titleTemplate({
      title: (
        category
          ? category.label
          : this.state.config.layoutTitle
            ? this.state.config.layoutTitle
            : ''
      )
    });
    return this.mainElement;
  }
}

export default (params) => {
  return new CategoryTitle(params);
};
