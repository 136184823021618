import Handlebars from 'handlebars';
import Splide from '@splidejs/splide';
import Widget from '../modules/Widget';
import EventEmitter from '@yesplz/visualfilter/src/modules/EventEmitter';
import { formatCurrency } from '@yesplz/core';

const PRODUCT_TEMPLATE = `
<a href="{{url}}" class="yesplz-product-detail">
  <div class="yesplz-product-detail-image">
    <img src="{{frontImg}}" width="400" id="objImg">
  </div>
  <div class="yesplz-product-detail-description">
    <h4>{{brand}}</h4>
    <h2>{{name}}</h2>
    <p class="yesplz-product-detail-price">
    {{#if isSale}}
      {{originalPrice}} - {{price}}
    {{else}}
      {{price}}
    {{/if}}
    </p>
  </div>
</div>
`;

const { document } = window;

class SimilarProductsSlider extends Widget {
  defaultParams = {
    limit: 20,
    slider: {
      type      : 'loop',
      perPage   : 5,
      speed     : 1000,
      autoplay  : true,
      pagination: false,
    },
    templates: {
      product: PRODUCT_TEMPLATE,
    },
    productUrl: (product) => (`product.html?productId=${product.productId}`),
    currency: {},
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = 'splide';
    element.innerHTML = `
      <div class="splide__track">
        <ul class="splide__list">
        </ul>
      </div>
    `;
    this.mainElement = element;
    this.productContainer = element.querySelector('ul');

    this.productTemplate = Handlebars.compile(this.params.templates.product || PRODUCT_TEMPLATE);

    /**
     * Lately analytics for "Buy" and "Add to Basket" buttons
     */
    const addToBasketButton = document.querySelector(".goodsView .buttonBox .btn_comCart");
    const buyButton = document.querySelector(".goodsView .buttonBox .btn_comBuy");
    if (addToBasketButton) {
      addToBasketButton.addEventListener('click', () => {
        const eventName = this.isYmalRecommendation ? 'ymalProductAddToBasketClick' : 'sfProductAddToBasketClick';
        EventEmitter.emit(eventName, {
          productId: this.main.productId,
        });
      });
    }
    if (buyButton) {
      buyButton.addEventListener('click', () => {
        const eventName = this.isYmalRecommendation ? 'ymalProductBuyClick' : 'sfProductBuyClick';
        EventEmitter.emit(eventName, {
          productId: this.main.productId,
        });
      });
    }
  }

  formatCurrency(number) {
    return formatCurrency(number,  this.params.currency);
  }

  get isYmalRecommendation() {
    return location.search.includes('ymal');
  }

  didMount() {
    this.main.fetchSimilarProducts(0, this.params.limit)
      .then((data) => {
        EventEmitter.emit('ymalProdcutsFetched', {
          productId: this.main.productId,
        });
        this.renderProducts(data);
      })
      .catch(() => {
        EventEmitter.emit('ymalProdcutsFetchedError', {
          productId: this.main.productId,
        });
      });
  }

  renderProducts(data) {
    this.productContainer.innerHTML = '';
    data.recommends.forEach(item => {
      const { product } = item;
      const discountValue = (
        product.originalPrice && product.salePrice !== product.originalPrice
          ? 100 - Math.ceil((product.salePrice / product.originalPrice) * 100)
          : ''
      );
      const productUrl = this.params.productUrl(product);

      this.productContainer.insertAdjacentHTML('beforeend', `
        <li class="splide__slide yesplz-recommendation" data-product-id="${product.productId}">
          ${this.productTemplate({
            ...product,
            url: productUrl,
            srcUrl: productUrl,
            isSale: product.originalPrice && product.salePrice !== product.originalPrice,
            price: this.formatCurrency(product.salePrice),
            originalPrice: this.formatCurrency(product.originalPrice),
            discountValue,
            frontImg: product.frontImgSrc,
            brand: product.brandName,
            name: product.productName,
          })}
        </li>
      `);
    });

    const splide = new Splide( '.splide', {
      ...this.params.slider,
    }).mount();

    splide.on('click', (slide) => {
      const productId = slide.slide.getAttribute('data-product-id');
      EventEmitter.emit('ymalListProductClick', { productId });
    });
  }

  render() {
    return this.mainElement;
  }
}

export default (params) => {
  return new SimilarProductsSlider(params);
};
