import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import grey from '@material-ui/core/colors/grey'

// core-redux
import { login } from '@yesplz/core-redux/ducks/admin/auth'

// core-web
import history from '@yesplz/core-web/config/history'

// desktop
import LeftLogo from 'assets/images/login-bg.png'

import './Register.scss'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  cssLabel: {
    '&$cssFocused': {
      color: grey[500]
    }
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: grey[500]
    }
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: grey[500]
    }
  },
  notchedOutline: {}
})

class Register extends Component {
  static propTypes = {
    classes: PropTypes.object,
    authorized: PropTypes.bool,
    login: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      isOpenMenu: false,
      username: '',
      password: ''
    }
  }

  componentDidMount () {
    const { authorized } = this.props
    if (authorized) {
      history.push('/')
    }
  }

  componentDidUpdate (prevProps) {
    const { authorized } = this.props
    if (!prevProps.authorized && authorized) {
      history.push('/')
    }
  }

  handleChangeInput = (name) => (e) => {
    this.setState({ [name]: e.target.value })
  }

  handleSubmit = () => {
    const { login } = this.props
    const { username, password } = this.state
    if (this.validateAuthInfo()) {
      login(username, password)
    }
  }

  validateAuthInfo = () => {
    const { username, password } = this.state
    if (!username || username.length < 1 || !password || password.length < 1) {
      return false
    }

    return true
  }

  render () {
    const { classes } = this.props

    return (
      <div id='Admin-Login' className='Register'>
        <div className='left-container'>
          <img src={LeftLogo} alt='' />
        </div>
        <div className='right-container'>
          <div className='right-header'>Hi, {process.env.REACT_APP_RETAILER_NAME}</div>
          <div className='login-form'>
            <TextField
              className={classes.margin}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused
                }
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              label='Login ID'
              variant='outlined'
              id='username'
              type='text'
              name='username'
              onChange={this.handleChangeInput('username')}
            />
            <TextField
              className={classes.margin}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused
                }
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              label='Password'
              variant='outlined'
              id='loginPassword'
              type='password'
              name='password'
              onChange={this.handleChangeInput('password')}
            />
            <button className='login-btn' onClick={this.handleSubmit}>SIGN IN</button>
          </div>
          <div className='lost-text'>Lost your password?</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authorized: !!state.AdminAuth.userInfo
})

export default compose(
  connect(
    mapStateToProps,
    {
      login
    }
  ),
  withStyles(styles)
)(Register)
