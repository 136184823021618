import client from '@yesplz/client';
import * as widgets from './widgets';
import EventEmitter from '@yesplz/visualfilter/src/modules/EventEmitter';

export default class Product {
  widgetsContainer = [];

  constructor(params) {
    const {
      productId,
    } = params;

    this.productId = productId;
    this.widgets = widgets;

    EventEmitter.emit('ymalWidgetLoaded', { productId });
  }

  addWidget(widget) {
    this.mountWidget(widget);
  }

  mountWidget = widget => {
    this.widgetsContainer.push(widget);
    widget.main = this;
    widget.mount();
  }

  fetchProduct() {
    return client.product(this.productId);
  }

  fetchSimilarProducts(offset = 0, limit = 20) {
    return client.yml(this.productId, offset, limit);
  }

  fetchCFProducts(offset = 0, limit = 20) {
    return client.cf(this.productId, offset, limit);
  }
}
