import colorMetallicImg from '@yesplz/core/src/wconcept/assets/color-metallic.png';
import colorSilverImg from '@yesplz/core/src/wconcept/assets/color-silver.png';
import colorGoldImg from '@yesplz/core/src/wconcept/assets/color-gold.png';
import colorMultyImg from '@yesplz/core/src/wconcept/assets/color-multi.png';
import colorMultyHandsomeImg from '@yesplz/core/src/handsome/assets/color-multi.png';
export const BASE_IMG_PATH = process.env.BASE_IMG_PATH || '';

export const RETAILER = process.env.RETAILER || null;

// categories
export const CATEGORY_WTOP = 'wtop'
export const CATEGORY_WSHOES = 'wshoes'
export const CATEGORY_WPANTS = 'wpants'
export const CATEGORY_WDRESS = 'wdress'
export const CATEGORY_WSKIRT = 'wskirt'
export const CATEGORY_WOUTER = 'wouter'
export const CATEGORY_WEARRING = 'wearring'
export const CATEGORY_WKNIT = 'wknit'
export const CATEGORY_WWALLET = 'wwallet'
export const CATEGORY_WBAG = 'wbag'

export const CATEGORY_MSHIRTS = 'mshirts'
export const CATEGORY_MTSHIRTS = 'mtshirts'
export const CATEGORY_MPANTS = 'mpants'
export const CATEGORY_MKNIT = 'mknit'
export const CATEGORY_MOUTER = 'mouter'
export const CATEGORY_MSHOES = 'mshoes'
export const CATEGORY_MWALLET = 'mwallet'
export const CATEGORY_MBAG = 'mbag'

// fabric colors dictionary
// export const FABRIC_COLORS = {
//   red: '#E03E3E',
//   pink: '#F0A4BD',
//   beige: '#FAF0DC',
//   orange: '#FF8400',
//   yellow: '#E0D03E',
//   black: '#000000',
//   white: '#FFFFFF',
//   green: '#3EE059',
//   blue: '#3E60E0',
//   purple: '#7F3EE0',
//   brown: '#663300',
//   pastel: 'linear-gradient(221deg, #8ff5d3 14%, #fdb4e3 80%)',
//   grey: '#dddddd',
// };

// WConcept colors
const WCONCEPT_COLORS = {
  red: '#ba3939',
  pink: '#ff9ecf',
  orange: '#ff9453',
  yellow: '#ffdf48',
  black: '#161616',
  white: '#FFFFFF',
  green: '#5fa564',
  blue: '#5ca2dd',
  purple: '#9c80d1',
  brown: '#ae8760',
  grey: '#aaaaaa',
  metallic: `url(${colorSilverImg})`,
  silver: `url(${colorMetallicImg})`,
  gold: `url(${colorGoldImg})`,
  multi: `url(${colorMultyImg})`,
};

// The Handsome colors
const HANDSOME_COLORS = {
  beige: '#fae7c4',
  black: '#000000',
  blue: '#0f45bc',
  brown: '#673915',
  burgundy: '#741313',
  camel: '#876c41',
  gold: '#ffc733',
  green: '#0f6f0e',
  grey: '#444445',
  ivory: '#fff8d9',
  khaki: '#465626',
  lavender: '#9c81bb',
  metal: '#9d9fa2',
  mint: '#95d0ab',
  navy: '#061836',
  olive: '#5d682d',
  orange: '#ee6423',
  pink: '#ea589b',
  purple: '#833b95',
  red: '#ec1e24',
  silver: '#c0c0c0',
  sky: '#a2d6f3',
  violet: '#4c2b7b',
  white: '#ffffff',
  yellow: '#ffea0a',
  multi: `url(${colorMultyHandsomeImg})`,
};

const DEFAULT_COLORS = {
  red: '#f82144',
  pink: '#f8bcde',
  beige: '#FAF0DC',
  orange: '#FF8400',
  yellow: '#ffd602',
  black: '#0d0d0d',
  white: '#FFFFFF',
  green: '#3d715a',
  blue: '#1700ff',
  purple: '#9e0cf1',
  brown: '#593b33',
  pastel: 'linear-gradient(221deg, #8ff5d3 14%, #fdb4e3 80%)',
  grey: '#dddddd',
}

const HANDSOME_COLOR_LABELS = {
  beige: 'BEIGE',
  black: 'BLACK',
  blue: 'BLUE',
  brown: 'BROWN',
  burgundy: 'BURGUNDY',
  camel: 'CAMEL',
  gold: 'GOLD',
  green: 'GREEN',
  grey: 'GREY',
  ivory: 'IVORY',
  khaki: 'KHAKI',
  lavender: 'LAVENDER',
  metal: 'METAL',
  mint: 'MINT',
  navy: 'NAVY',
  olive: 'OLIVE',
  orange: 'ORANGE',
  pink: 'PINK',
  purple: 'PURPLE',
  red: 'RED',
  silver: 'SILVER',
  sky: 'SKY',
  violet: 'VIOLET',
  white: 'WHITE',
  yellow: 'YELLOW',
  multi: 'MULTI',
};

const DEFAULT_COLOR_LABELS = {
  red: [ { 'lng': 'en', 'label': 'Red' }, { 'lng': 'ko', 'label': '빨강' } ],
  pink: [ { 'lng': 'en', 'label': 'Pink' }, { 'lng': 'ko', 'label': '분홍' } ],
  beige: [ { 'lng': 'en', 'label': 'Beige' }, { 'lng': 'ko', 'label': '베이지' } ],
  orange: [ { 'lng': 'en', 'label': 'Orange' }, { 'lng': 'ko', 'label': '주황' } ],
  yellow: [ { 'lng': 'en', 'label': 'Yellow' }, { 'lng': 'ko', 'label': '노랑' } ],
  black: [ { 'lng': 'en', 'label': 'Black' }, { 'lng': 'ko', 'label': '블랙' } ],
  white: [ { 'lng': 'en', 'label': 'White' }, { 'lng': 'ko', 'label': '화이트' } ],
  green: [ { 'lng': 'en', 'label': 'Green' }, { 'lng': 'ko', 'label': '초록' } ],
  blue: [ { 'lng': 'en', 'label': 'Blue' }, { 'lng': 'ko', 'label': '파랑' } ],
  purple: [ { 'lng': 'en', 'label': 'Purple' }, { 'lng': 'ko', 'label': '보라' } ],
  brown: [ { 'lng': 'en', 'label': 'Brown' }, { 'lng': 'ko', 'label': '브라운' } ],
  pastel: [ { 'lng': 'en', 'label': 'Pastel' }, { 'lng': 'ko', 'label': '파스텔' } ],
  grey: [ { 'lng': 'en', 'label': 'Grey' }, { 'lng': 'ko', 'label': '그레이' } ],
  all: [ { 'lng': 'en', 'label': 'Reset' }, { 'lng': 'ko', 'label': '초기화' } ],
}

export const COLOR_LABELS = RETAILER === 'handsome' ? HANDSOME_COLOR_LABELS : DEFAULT_COLOR_LABELS;

export const FABRIC_COLORS = RETAILER === 'wconcept2024' ? WCONCEPT_COLORS : RETAILER === 'handsome' ? HANDSOME_COLORS : DEFAULT_COLORS;

export const NEXT_BEST_MATCH_SCORE = 99.0;

export const ANALYTICS_GA_KEY = process.env.REACT_APP_GA_TRACKING_ID || process.env.ANALYTICS_GA_KEY || 'UA-120529562-2';
