import { supabase } from "../supabase";

let womenPrompts = [];
let menPrompts = [];
let unisexPrompts = [];

async function fetchPrompts(type = 'women') {
  
  let query = supabase
    .from('prompts')
    .select('prompt');

  if (type === 'unisex') {
    query = query.in('gender', ['unisex']);
  } else {
    query = query.in('gender', [type, 'unisex']);
  }

  const { data: prompts, error } = await query;

  return prompts?.map(prompt => prompt.prompt);
}

async function setRemotePrompts() {
  let newWomenPrompts = await fetchPrompts('women');
  let newMenPrompts = await fetchPrompts('men');
  let newUnisexPrompts = await fetchPrompts('unisex');
  
  if (newWomenPrompts) womenPrompts = newWomenPrompts;
  if (newMenPrompts) menPrompts = newMenPrompts;
  if (newUnisexPrompts) unisexPrompts = newUnisexPrompts;
  
  return { womenPrompts, menPrompts, unisexPrompts };
}

export {
  womenPrompts,
  menPrompts,
  unisexPrompts,
  setRemotePrompts,
};
