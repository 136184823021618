import isNil from 'lodash/isNil';
import Handlebars from 'handlebars';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const { document } = window;

class Pagination extends Widget {
  defaultParams = {
    visiblePagesLimit: 7,
    containerClassName: null,
    templates: {
      main: `<div class="paging">{{{pagesHtml}}}</div>`,
      page: `<a data-page="{{page}}" href="#">{{page}}</a>`,
      currentPage: `<strong class="on">{{page}}</strong>`,
      prevPage: `<a data-page="{{page}}" href="#">&lt;</a>`,
      prevPageDisabled: ``,
      nextPage: `<a data-page="{{page}}" href="#">&gt;</a>`,
      nextPageDisabled: ``,
      firstPage: `<a data-page="{{page}}" href="#">&lt;&lt;</a>`,
      firstPageDisabled: ``,
      lastPage: `<a data-page="{{page}}" href="#">&gt;&gt;</a>`,
      lastPageDisabled: ``,
    },
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    if (this.params.containerClassName)
      element.className = this.params.containerClassName;
    this.mainElement = element;

    this.mainTemplate = Handlebars.compile(this.params.templates.main);
    this.pageTemplate = Handlebars.compile(this.params.templates.page);
    this.currentPageTemplate = Handlebars.compile(
      this.params.templates.currentPage
    );

    this.prevPageTemplate = Handlebars.compile(this.params.templates.prevPage);
    this.prevPageDisabledTemplate = Handlebars.compile(this.params.templates.prevPageDisabled);
    this.nextPageTemplate = Handlebars.compile(this.params.templates.nextPage);
    this.nextPageDisabledTemplate = Handlebars.compile(this.params.templates.nextPageDisabled);

    this.firstPageTemplate = Handlebars.compile(this.params.templates.firstPage);
    this.firstPageDisabledTemplate = Handlebars.compile(this.params.templates.firstPageDisabled);
    this.lastPageTemplate = Handlebars.compile(this.params.templates.lastPage);
    this.lastPageDisabledTemplate = Handlebars.compile(this.params.templates.lastPageDisabled);
  }

  handlePageClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const page = e.target.getAttribute('data-page');
    const limit = parseInt(this.state.filter.limit, 10);

    const offset = limit * (page - 1);

    EventEmitter.emit('paginationClick', {
      offset,
      limit,
      page,
    });

    this.setFilter({
      offset,
      params: this.state.filter.params,
    });

    window.scrollTo(0, 0);
  }

  renderPagination() {
    const { visiblePagesLimit } = this.params;
    const offset = parseInt(this.state.filter.offset, 10);
    const limit = parseInt(this.state.filter.limit, 10);
    const { counts } = this.state.search;
    if (counts && counts.total && counts.total > limit) {
      const totalPages = Math.ceil(counts.total / limit);
      const currentPage = Math.ceil(offset / limit) + 1;
      const prevPage = currentPage > 1 ? currentPage - 1 : null;
      const nextPage = currentPage < totalPages ? currentPage + 1 : null;

      let startPage = currentPage - Math.floor(visiblePagesLimit / 2);
      let endPage = currentPage + Math.floor(visiblePagesLimit / 2);

      if (totalPages < visiblePagesLimit) {
        startPage = 1;
        endPage = totalPages;
      }
      else if (endPage >= totalPages) {
        endPage = totalPages;
        startPage = endPage - (visiblePagesLimit - 1);
      }
      else if (startPage < 1) {
        startPage = 1;
        endPage = visiblePagesLimit;
      }

      let pagesHtml = (
        prevPage
          ? this.firstPageTemplate({ page: 1 }) + this.prevPageTemplate({ page: prevPage })
          : this.firstPageDisabledTemplate() + this.prevPageDisabledTemplate()
      );
      for (let page = startPage; page <= endPage; page++) {
        pagesHtml += page === currentPage
          ? this.currentPageTemplate({
            page,
            startPage,
            endPage,
          })
          : this.pageTemplate({
            page,
            startPage,
            endPage,
          });
      }
      pagesHtml += (
        nextPage
          ? this.nextPageTemplate({ page: nextPage }) + this.lastPageTemplate({ page: totalPages })
          : this.nextPageDisabledTemplate() + this.lastPageDisabledTemplate()
      );

      this.mainElement.innerHTML = this.mainTemplate({ pagesHtml });

      const pageElements = this.mainElement.querySelectorAll('[data-page]');
      pageElements.forEach(
        element => element.addEventListener('click', this.handlePageClick)
      );
    }
    else {
      this.mainElement.innerHTML = '';
    }
  }

  update() {
    this.renderPagination();
  }

  render() {
    this.renderPagination();
    return this.mainElement;
  }
}

export default (params) => {
  return new Pagination(params);
};
