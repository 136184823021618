import get from 'lodash/get';
import Hashids from 'hashids';
import Cookies from 'js-cookie';
import EventEmitter from './EventEmitter';
import Hooks from './Hooks';
import { ANALYTICS_EVENTS_TO_SUBSCRIBE } from '../configs/analytics';

const ANALYTICS_GA_KEY = process.env.REACT_APP_GA_TRACKING_ID || process.env.ANALYTICS_GA_KEY || 'UA-120529562-2';
const RETAILER = process.env.RETAILER || process.env.REACT_APP_RETAILER_NAME || null;

const hashids = new Hashids(RETAILER || 'usdemo');

function getRandomString(length) {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for ( var i = 0; i < length; i++ ) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
}

function generateYPID() {
  const hashid = hashids.encode(Date.now());
  const randomString = getRandomString(10);
  return `YP1.${hashid}-${randomString}`;
}

export function getYPID() {
  let ypid = Cookies.get('ypa');
  if (!ypid) {
    ypid = generateYPID();
    Cookies.set('ypa', generateYPID(), { expires: 365 * 2 });
  }
  return ypid;
}

const YPID = getYPID();

if (!window.gtag) {
  const sc = window.document.createElement('script');
  sc.src = `https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_GA_KEY}`;
  window.document.head.prepend(sc);
  window.dataLayer = window.dataLayer || [];
  window.gtag = function (){dataLayer.push(arguments);}
}

window.gtag('js', new Date());
window.gtag('config', ANALYTICS_GA_KEY);

const { gtag } = window;

export const handleEvent = (action, label, type = 'event') => {
  const params = {
    send_to: ANALYTICS_GA_KEY,
  };

  // if (RETAILER) {
  //   params.event_category = RETAILER;
  // }
  if (typeof label === 'string') {
    params.event_label = label;
  }
  else if (label && action.labelProperty && typeof label === 'object') {
    if (typeof action.labelProperty === 'function') {
      params.event_label = action.labelProperty(label);
    }
    else {
      params.event_label = get(label, action.labelProperty);
    }
  }

  const eventType = type;
  const eventName = (
    typeof action === 'string'
      ? action
      : action.event
  );

  gtag(...Hooks.call('analyticsEvent', [
    eventType,
    eventName,
    params
  ]));
};

/**
 * Array of strings with event names, same names would be sent
 * to GA as events. If Subscriber receives string it will be sent
 * as a label for GA event.
 *
 * Also, if subscriber recieves object, we can sst event settings object as:
 * { event: 'sfListProductClick', labelProperty: 'productId' }
 *
 * `event` param telling where to subscribe and which name to send.
 * `labelProperty` param telling which property to take from recieving object.
 * it can be equal to object path string `object.param.param`
 */
ANALYTICS_EVENTS_TO_SUBSCRIBE.map(action => {
  EventEmitter.on(
    (
      typeof action === 'string'
        ? action
        : action.event
    ),
    (label) => handleEvent(action, label)
  );
});
