import Handlebars from 'handlebars';
import Widget from '../modules/Widget';

const { document } = window;

const DEFAULT_LAYOUT = `
  {{#each items}}
    <option value="{{this.value}}">{{this.label}}</option>
  {{/each}}
`;

class SortBySelect extends Widget {
  defaultParams = {
    containerClassName: '',
    items: [
      { label: 'From lowest price', value: 'price:asc' },
      { label: 'Name', value: 'name' },
      { label: 'Newest', value: 'newest' },
    ],
    template: DEFAULT_LAYOUT,
    onRendered: () => {},
  };

  constructor(params) {
    super(params);

    this.layoutTemplate = Handlebars.compile(this.params.template);

    const element = document.createElement('select');
    element.className = this.params.containerClassName;
    this.mainElement = element;

    this.mainElement.addEventListener('change', (e) => this.handleChange(e.target.value));
  }

  didMount() {
    this.updateSelectedOption();
  }

  didUpdate() {
    this.updateSelectedOption();
  }

  updateSelectedOption() {
    let { sort } = this.state.filter;
    if (!sort) {
      sort = this.params.items[0].value;
      this.handleChange(sort);
    }
    const option = this.mainElement.querySelector(`option[value="${sort || ''}"]`);
    if (option) {
      option.selected = true;
    }
  }

  handleChange = (sort) => {
    this.setFilter({
      sort: sort || null,
    });
  }

  render() {
    this.mainElement.innerHTML = this.layoutTemplate({
      items: this.params.items,
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(this.params.items);
    }

    return this.mainElement;
  }
}

export default (params) => {
  return new SortBySelect(params);
};
