import Handlebars from 'handlebars';
import Widget from '../modules/Widget';

const { document } = window;

const TEMPLATE = `
  {{#each categories}}
    <option value="{{this.id}}">{{this.label}}</option>
  {{/each}}
`

class CategorySelect extends Widget {
  defaultParams = {};

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(TEMPLATE);

    const element = document.createElement('select');
    element.className = this.params.containerClassName || 'select-category';
    this.mainElement = element;

    this.lastCategoryId = null;

    this.mainElement.addEventListener('change', (e) => this.changeCategory(e.target.value));
  }

  changeCategory = (categoryId) => {
    this.main.setCategory(categoryId);
  }

  didMount() {
    this.updateSelected();
  }

  didUpdate() {
    this.updateSelected();
  }

  updateSelected() {
    if (this.lastCategoryId === this.state.filter.categoryId) return;

    const selectedOption = this.mainElement
      .querySelector(`option[value="${this.state.filter.categoryId}"]`);
    if (selectedOption) {
      selectedOption.selected = true;
    }

    this.lastCategoryId = this.state.filter.categoryId;
  }

  render() {
    this.mainElement.innerHTML = this.template({
      categories: Object.entries(this.main.categories).map(([categoryId, category]) => ({
        id: categoryId,
        label: category.label
          .replace('Men ', '')
          .replace('Women ', ''),
      })),
    });

    return this.mainElement;
  }
}

export default (params) => {
  return new CategorySelect(params);
};
