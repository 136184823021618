import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import MenuItemForm from './MenuItemForm';
import TreeMenu from './TreeMenu';
import styles from './CategoriesTree.module.scss';
import cleanConfiguration from '../visual-filter-editor/cleanConfiguration';

export default function CategoriesTree() {
  const [items, setItems] = useState([]);
  const [configuration, setConfiguration] = useState(null);
  const [openCategories, setOpenCategories] = useState([]);
  const [categoryCreateFormData, setCategoryCreateFormData] = useState(null);
  const AdminAuth = useSelector(({ AdminAuth }) => AdminAuth);
  const { userInfo } = AdminAuth;
  const { retailer } = userInfo;

  async function getConfiguration() {
    const configurationReponse = await axios({
      method: 'get',
      url: `/retailers/${retailer}/configuration`,
    });
    const treeMenuReponse = await axios({
      method: 'get',
      url: `/retailers/${retailer}/tree-menu`,
    });
    setConfiguration(configurationReponse.data);
    setItems(treeMenuReponse.data);
  }

  async function updateConfiguration(newConfiguration) {
    const configuration = cleanConfiguration(newConfiguration);
    setConfiguration(configuration);
    const reponse = await axios({
      method: 'post',
      url: `/retailers/${retailer}/configuration`,
      data: { configuration: configuration },
    });
    setConfiguration(reponse.data);
  }

  async function updateTreeMenu(treeMenu) {
    setItems(treeMenu);
    await axios({
      method: 'post',
      url: `/retailers/${retailer}/tree-menu`,
      data: { treeMenu: treeMenu },
    });
  }

  useEffect(() => {
    setConfiguration(null);
    getConfiguration();
  }, [retailer]);

  const handlePublishClick = (pathIndex) => {
    const newItems = cloneDeep(items);
    const path = pathIndex.join('.children.');
    const item = get(newItems, path);
    item.isPublished = !item.isPublished;
    updateTreeMenu(newItems);
  }

  const handleLevelUpClick = (pathIndex) => {
    const newItems = cloneDeep(items);

    if (pathIndex.length <= 1) return;

    let path = pathIndex.slice(0, -1).join('.children.') + '.children';
    const parentItems = path ? get(newItems, path) : newItems;

    path = pathIndex.slice(0, -2).join('.children.') + '.children';
    const grandparentItems = path !== '.children' ? get(newItems, path) : newItems;

    const itemIndex = pathIndex[pathIndex.length - 1];
    const temp = parentItems[itemIndex];
    parentItems.splice(itemIndex, 1);

    const parentIndex = pathIndex[pathIndex.length - 2];
    grandparentItems.splice(parentIndex + 1, 0, temp);

    updateTreeMenu(newItems);
  }

  const handleLevelDownClick = (pathIndex) => {
    const newItems = cloneDeep(items);

    const index = pathIndex[pathIndex.length - 1];

    if (index === 0) return;

    let path = pathIndex.slice(0, -1).join('.children.') + '.children';
    const parentItems = path !== '.children' ? get(newItems, path) : newItems;

    const temp = parentItems[index];
    parentItems.splice(index, 1);
    if (!parentItems[index - 1].children) parentItems[index - 1].children = [];
    parentItems[index - 1].children.push(temp);

    if (!openCategories.includes(parentItems[index - 1].categoryId)) {
      setOpenCategories([...openCategories, parentItems[index - 1].categoryId]);
    }
    updateTreeMenu(newItems);
  }

  const handleUpwardClick = (pathIndex) => {
    const newItems = cloneDeep(items);

    const index = pathIndex[pathIndex.length - 1];

    if (index === 0) return;

    let path = pathIndex.slice(0, -1).join('.children.') + '.children';
    const parentItems = path !== '.children' ? get(newItems, path) : newItems;

    const temp = parentItems[index - 1];
    parentItems[index - 1] = parentItems[index];
    parentItems[index] = temp;

    updateTreeMenu(newItems);
  }

  const handleDownwardClick = (pathIndex) => {
    const newItems = cloneDeep(items);
    const index = pathIndex[pathIndex.length - 1];

    let path = pathIndex.slice(0, -1).join('.children.') + '.children';
    const parentItems = path !== '.children' ? get(newItems, path) : newItems;

    if (index === parentItems.length - 1) return;

    const temp = parentItems[index + 1];
    parentItems[index + 1] = parentItems[index];
    parentItems[index] = temp;

    updateTreeMenu(newItems);
  }

  const insertNewItem = (pathIndex, newItem) => {
    const newItems = cloneDeep(items);

    let path = pathIndex.join('.children.');
    const parentItem = path ? get(newItems, path) : { children: newItems };

    if (!parentItem.children) parentItem.children = [];
    parentItem.children.push({
      ...newItem,
      ...(
        newItem.categoryId !== 'url'
          ? { categoryId: newItem.categoryId }
          : { url: newItem.url }
      ),
    });

    if (!openCategories.includes(path)) {
      setOpenCategories([...openCategories, path]);
    }

    updateTreeMenu(newItems);
    setCategoryCreateFormData(null);
  }

  const handleCreateClick = (parentPath) => {
    setCategoryCreateFormData({
      parentPath,
    });
  }

  const handleDeleteClick = (pathIndex) => {
    const newItems = cloneDeep(items);

    let path = pathIndex.slice(0, -1).join('.children.') + '.children';
    const parentItems = path !== '.children' ? get(newItems, path) : newItems;
    const index = pathIndex[pathIndex.length - 1];

    const item = parentItems[index];
    parentItems.splice(index, 1);

    updateTreeMenu(newItems);

    const newConfiguration = cloneDeep(configuration);
    delete newConfiguration[item.categoryId];
    updateConfiguration(newConfiguration);
  }

  const handleChange = ({ parentPath, itemPath, ...data }) => {
    if (parentPath) {
      insertNewItem(parentPath, data);
    }
    else if (itemPath) {
      const newItems = cloneDeep(items);

      let path = itemPath.join('.children.');
      const item = path !== '.children' ? get(newItems, path) : newItems;
      console.log(item);
    }
  };

  const categories = Object.values(configuration || {});

  return (
    <>
      <MenuItemForm
        parentPath={(categoryCreateFormData || {}).parentPath}
        itemPath={(categoryCreateFormData || {}).itemPath}
        defaultValues={(categoryCreateFormData || {}).item}
        categories={categories}
        open={!!categoryCreateFormData}
        onClose={() => setCategoryCreateFormData(null)}
        onChange={handleChange}
      />
      <div className={styles['root']}>
        <div className={styles['container']}>
          <h2>Menu editor</h2>
          {configuration ? <div className={styles['tree']}>
            <TreeMenu
              id="main"
              onOpenCloseClick={pathIndex => {
                const path = pathIndex.join('.children.');
                if (openCategories.includes(path)) {
                  setOpenCategories(openCategories.filter(id => id !== path));
                }
                else {
                  setOpenCategories([ ...openCategories, path ]);
                }
              }}
              path={[]}
              {...{
                items,
                openCategories,
                configuration,
                onEditClick: (pathIndex) => {
                  const path = pathIndex.join('.children.');
                  const item = cloneDeep(get(items, path));
                  delete item.children;
                  setCategoryCreateFormData({ itemPath: pathIndex, item });
                },
                onDeleteClick: handleDeleteClick,
                onCreateClick: handleCreateClick,
                onPublishClick: handlePublishClick,
                onLevelUpClick: handleLevelUpClick,
                onLevelDownClick: handleLevelDownClick,
                onUpwardClick: handleUpwardClick,
                onDownwardClick: handleDownwardClick,
              }}
            />
          </div> : null}
        </div>
      </div>
    </>
  );

  // function handleDragEnd(event) {
  //   console.log ('event', event)
  // }
}
