import "core-js/stable";
import "regenerator-runtime/runtime";
import { fetchConfiguration, extendedCategories, patternsFilterParams } from '@yesplz/visualfilter/src/extended-configuration';
import { VisualFilter } from '@yesplz/visualfilter';
import { Product } from '@yesplz/product';
import { TextSearch } from '@yesplz/textsearch';
import findLabelByLng from './findLabelByLng';
import formatCurrency from './formatCurrency';
import sortCategories from './sortCategories';
import fillPublishedListItem from './fillPublishedListItem';
import { fillCategoriesByParent, cleanChildrenByParents } from './fillCategoriesByParent';
import aiStylist from './AiStylist';
import buildTree from './buildTree';

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const yesplz = {
  VisualFilter,
  Product,
  TextSearch,
  fetchConfiguration,
  aiStylist,
};

const StyleFilter = VisualFilter;

export default yesplz;

export {
  StyleFilter,
  VisualFilter,
  Product,
  TextSearch,
  fetchConfiguration,
  findLabelByLng,
  formatCurrency,
  sortCategories,
  fillPublishedListItem,
  fillCategoriesByParent,
  cleanChildrenByParents,
  extendedCategories,
  patternsFilterParams,
  aiStylist,
  buildTree,
};
