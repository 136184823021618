const isDev = process.env.NODE_ENV === 'development'

// Actions
const SET_FAVORITE_SETTING = 'favorite/SET_FAVORITE_SETTING'

const defaultState = {
  // visual filters
  items: {
    listingView: 'single',
    filters: {
      types: [],
      occasions: [],
      price: [],
      sale: []
    }
  },
  styles: {
    listingView: 'single',
    filters: {
      types: []
    }
  }
}

// Reducer
export default function reducer (state = defaultState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SET_FAVORITE_SETTING:
      return {
        ...state,
        [payload.favoriteType]: { ...payload.setting }
      }
    default: return state
  }
}

// Actions creator
export function setFavoriteSetting (favoriteType, setting) {
  return { type: SET_FAVORITE_SETTING, payload: { favoriteType, setting } }
}

/**
 * set favorite setting
 */
export function fetchFavoriteSetting (favoriteType, setting) {
  return dispatch => {
    dispatch(setFavoriteSetting(favoriteType, setting))
  }
}
