import Handlebars from 'handlebars';
import Widget from '../modules/Widget';

const { document } = window;

class SaleCheckbox extends Widget {
  defaultParams = {
    className: 'yesplz-sale-checkbox',
    containerClassName: '',
    onRendered: () => {},
  };

  constructor(params) {
    super(params);

    this.checkbox = null;
  }
  
  didMount() {
    this.checkbox.addEventListener('change', (e) => this.handleChange(e.target.checked));
    this.updateChecked();
  }

  didUpdate(prevState) {
    if (this.state.filter.params.sale !== prevState.filter.params.sale) {
      this.updateChecked();
    }
  }

  updateChecked() {
    this.checkbox.checked = this.state.filter.params.sale === 'saleonly';
  }

  handleChange = (checked) => {
    this.setFilter({
      params: {
        ...this.state.filter.params,
        sale: checked ? 'saleonly' : null,
      },
    });
  }

  render() {
    this.container.classList.add(this.params.className);
    const label = document.createElement('label');
    const checkbox = document.createElement('input');
    checkbox.setAttribute('type', 'checkbox');

    const span = document.createElement('span');
    span.innerText = 'Sale Only';
    
    label.appendChild(checkbox);
    label.appendChild(span);

    this.checkbox = checkbox;

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(this.params.items);
    }

    return label;
  }
}

export default (params) => {
  return new SaleCheckbox(params);
};
