import Handlebars from 'handlebars';
import Widget from '../modules/Widget';
import { formatCurrency } from '@yesplz/core';

const PRODUCT_TEMPLATE = `
<div class="yesplz-product-detail">
  <div class="yesplz-product-detail-image">
    <img src="{{frontImg}}" width="400" id="objImg">
  </div>
  <div class="yesplz-product-detail-description">
    <h4>{{brand}}</h4>
    <h2>{{name}}</h2>
    <p class="yesplz-product-detail-price">
    {{#if isSale}}
      {{originalPrice}} - {{price}}
    {{else}}
      {{price}}
    {{/if}}
    </p>
  </div>
</div>
`;

const { document } = window;

class ProductDetail extends Widget {
  defaultParams = {
    templates: {
      product: PRODUCT_TEMPLATE,
    },
    currency: {},
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'product';
    this.mainElement = element;

    this.productTemplate = Handlebars.compile(this.params.templates.product || PRODUCT_TEMPLATE);
  }

  didMount() {
    this.main.fetchProduct()
      .then((product) => {
        this.renderProduct(product);
      });
  }

  formatCurrency(number) {
    return formatCurrency(number,  this.params.currency);
  }

  async fetchProduct() {
    const product = await this.main.fetchProduct();
    return product;
  }

  renderProduct(product) {
    this.mainElement.innerHTML = this.productTemplate({
      frontImg: product.variants[0].images[0].srcUri,
      name: product.productName,
      brand: product.brandName,
      isSale: !!(product.originalPrice && product.originalPrice !== product.salePrice),
      originalPrice: this.formatCurrency(product.originalPrice),
      price: this.formatCurrency(product.salePrice),
    });
  }

  render() {
    return this.mainElement;
  }
}

export default (params) => {
  return new ProductDetail(params);
};
