import { DynamicTreeViewMenu } from './DynamicTreeViewMenu';
import { findLabelByLng, extendedCategories } from '@yesplz/core';
import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';

class InternalCategoriesFilter extends DynamicTreeViewMenu {
  constructor(params) {
    super({
      ...params,
      filterParam: 'category',
      valueName: 'category',
      // title: 'Categories',
      // filterParamName: 'category',
      // listParamName: 'category',
      // listMiddleware: null,
      // multiple: true,
    });

    this.categoryLabels = {
      ...Object.keys(extendedCategories).reduce((acc, key) => {
        acc[key] = findLabelByLng(extendedCategories[key].label);
        return acc;
      }, {}),
      wshoes: 'Shoes',
      wknit: 'Knitwear',
      mshoes: 'Shoes',
      mknit: 'Knitwear',
      mbag: 'Bags',
      wbag: 'Bags',
      mwallet: 'Wallets',
      wwallet: 'Wallets',
    }
  }

  formatItems(items) {
    const categories = [
      { label: 'Women', categoryId: 'women', isActive: true, isSelected: false, children: [] },
      { label: 'Men', categoryId: 'men', isActive: true, isSelected: false, children: [] },
      { label: 'Other', categoryId: 'other', isActive: true, isSelected: false, children: [] },
    ]

    Object.values(items).forEach((item) => {
      const { value } = item;
      if (['all', 'error'].includes(value)) return;

      const firstChar = value[0].toLowerCase();

      const key = firstChar === 'w' ? 0 : firstChar === 'm' ? 1 : 2;

      if (['women', 'men', 'other'].includes(value)) {
        categories[key].isActive = item.isActive;
        categories[key].isSelected = item.isSelected;
        return;
      }

      const newItem = {
        ...item,
        categoryId: value,
        label: this.renderLabel(item),
      };
      categories[key].children.push(newItem);

      this.itemsIndex[newItem.categoryId] = newItem;
    });

    // Update isActive status based on whether a category has children
    categories.forEach(category => {
      if (category.children.length > 0) {
        category.isActive = true;
      }
      this.itemsIndex[category.categoryId] = category;
    });

    return categories;
  }

  // get items() {
  //   const { listParamName } = this.params;
  //   const items = (
  //     typeof this.params.listMiddleware === 'function'
  //       ? this.params.listMiddleware(this.state.search?.filter?.[listParamName])
  //       : this.convertList(this.state.search?.filter?.[listParamName])
  //   ).filter(item => !['all', 'women', 'men', 'other', 'error'].includes(item.value));

  //   return items;
  // }

  renderLabel(item) {
    if (this.categoryLabels[item.value])
      return findLabelByLng(this.categoryLabels[item.value])
        .replace('Women ', '')
        .replace('Men ', '');

    return item.value;
  }
}

export default (params) => {
  return new InternalCategoriesFilter(params);
};
