import { createReducer } from '@reduxjs/toolkit';
import { setFilter } from '../actions/filterActions';
import Hooks from '../../modules/Hooks';

const initialFilter = {
  categoryId: null,
  category: null,
  categorySlice: null,
  bodyPart: null,
  params: {},
  sort: null,
  offset: 0,
  limit: 60,
  presetIndex: null,
  occasion: null,
  mood: null,
};

const multiSelectParams = ['design', 'color', 'material'];

const filter = createReducer(initialFilter, {
  [setFilter.type]: (filter, { payload }) => {
    if (payload.params) {
      multiSelectParams.forEach(name => {
        if (typeof payload.params[name] === 'string') {
          payload.params[name] = payload.params[name].split(',');
        }
      });
    }

    const filterAfter = {
      ...filter,
      ...payload,
    };

    // If offset is not present in payload, reset it.
    if (!payload.offset) {
      filterAfter.offset = 0;
    }

    return Hooks.call('filter.setComplite', filterAfter);
  },
});

export default filter;
