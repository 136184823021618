import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import Sticky from 'react-sticky-el';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import ArrowBack from '@material-ui/icons/ArrowBack';
import isPlainObject from 'lodash/isPlainObject';

import {
  fetchRecommendedProducts,
  fetchRandomRecommendedProducts,
} from '@yesplz/core-redux/ducks/admin/review';

import { USE_TOOLTIP } from 'config/consts';

import Loading from 'components/Loading';
import Footer from 'components/Footer';
import TooltipSwitch from 'components/TooltipSwitch';
import SearchBar from './SearchBar';
import Product from './Product';

import './ReviewProduct.scss';

const PredictionTable = ({ predictions }) => {
  if (!isPlainObject(Object.values(predictions)[0])) {
    predictions = { '': predictions };
  }

  return (
    Object.entries(predictions).map(([title, params], index) => (
      <div key={index}>
        <h3>{title}</h3>
        <table>
          <tbody>
            {Object.entries(params).map(([title, value], index) => (
              <tr key={index}>
                <td><strong>{title}</strong></td>
                <td>{typeof value === 'object' ? '' : value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ))
  );
};

export default ({ match }) => {
  const classes = useStyles();
  const { params: { productId } } = match;
  const { viewedProduct } = useSelector(({ AdminReview }) => AdminReview);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const fullSearchParams = location.search ? qs.parse(location.search.slice(1)) : {};
  const { category, backUrl } = fullSearchParams;

  function fetchRandom() {
    dispatch(fetchRandomRecommendedProducts(category));
  }

  useEffect(() => {
    if (productId === 'random') {
      fetchRandom();
    }
    else {
      dispatch(fetchRecommendedProducts(productId, true));
    }
  }, [productId]);

  const product = viewedProduct;

  if (!product) return <Loading />;

  const textPrediction = product.product.textPredictions ? JSON.parse(product.product.textPredictions.replaceAll("'", '"')) : null;
  const rawPredictions = product.product.rawPredictions ? JSON.parse(product.product.rawPredictions.replaceAll("'", '"')) : null;

  let imageUrl = product.product.frontImgSrc;
  if (product.product.frontImg.includes('handsome'))
    imageUrl = 'https://kr-images.yesplz.ai/handsome/media/' + product.product.frontImg;

  return (
    <div className="ReviewProduct-product-detail" id="MainScroll">
      <Sticky
        scrollElement="#MainScroll"
        stickyClassName={classes.sticky}
      >
        <div className={classes.header}>
          <div className={classes.searchRoot}>
            <SearchBar
              onSearch={(search) => {
                history.push(`/review?search=${search}`);
              }}
              onRandomClick={() => {
                if (productId === 'random') {
                  fetchRandom();
                }
                else {
                  history.push(`/review/product/random${location.search}`);
                }
              }}
            />
          </div>
          {USE_TOOLTIP && <div className={classes.switchRoot}>
            <TooltipSwitch
              checked={isTooltipActive}
              onChange={() => setIsTooltipActive(!isTooltipActive)}
            />
          </div>}
        </div>
      </Sticky>
      <div className='ReviewProduct-product-detail-container'>
        <div className='horizontal-container'>
          <div className='product-image'>
            <img src={imageUrl} alt='' />
          </div>
          <div className='product-detail'>
            <div className='product-id'>{product.product.productId}</div>
            <h3 className='product-name'>{product.product.productName}</h3>
            <p className='product-brand'>{product.product.brandName}</p>
            <div className='product-sizes' />
            {/* <div className="product-prediction-wrap">
              <div className='product-prediction'>{product.product.textPredictions}</div>
              <div className='product-prediction'>{product.product.rawPredictions}</div>
            </div> */}
            <div className="product-prediction-tables">
              {textPrediction && <PredictionTable predictions={textPrediction} />}
              {rawPredictions && <PredictionTable predictions={rawPredictions} />}
            </div>
            {backUrl && <div className="ReviewProduct-product-detail-button-container">
              <Button
                component={Link}
                variant="outlined"
                color="default"
                size="large"
                to={backUrl}
              >
                <SvgIcon>
                  <ArrowBack />
                </SvgIcon> Back to the list
              </Button>
            </div>}
          </div>
        </div>
      </div>
      <div className='product-list-container'>
        <h3>YOU MAY ALSO LIKE</h3>
        {
          product && product.recommends
          &&
          <div className="ReviewProduct-recommended">
            {product.recommends.map((product) => (
              <Product
                key={product.product.productId}
                searchParams={fullSearchParams}
                isTooltipActive={isTooltipActive}
                product={product}
              />
            ))}
          </div>
        }
      </div>
      {backUrl && <div className="ReviewProduct-product-detail-button-bottom-container">
        <Button
          component={Link}
          variant="outlined"
          color="default"
          size="large"
          to={backUrl}
        >
          <SvgIcon>
            <ArrowBack />
          </SvgIcon> Back to the list
        </Button>
      </div>}
      <Footer />
    </div>
  );
}

const useStyles = makeStyles({
  sticky: {
    zIndex: 1000,
    boxShadow: '0 10px 8px 0 rgba(0, 0, 0, 0.08)',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px 15px 20px',
    backgroundColor: '#ffffff',
    position: 'relative',
  },
  searchRoot: {
    flex: 1,
  },
  switchRoot: {},
});
