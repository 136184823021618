import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import client from '@yesplz/client';
import Fuse from 'fuse.js';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import Conveyer from "@egjs/conveyer";

const { document } = window;

export class BrandFilter extends Widget {
  defaultParams = {
    title: 'Brands',
    displaySearchBar: false,
    displayHeaders: false,
    sortedBrands: [],
    activeBrands: null,
    items: null,
    filterBy: 'resultFilters',
    displaySelectedBrands: true,
    showDisabledBrands: false,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.brandsByCategories = {};

    this.fuse = new Fuse([], {
      includeScore: true,
      includeMatches: true,
      threshold: 0.4,
      keys: ['label'],
    });

    this.ul = document.createElement('ul');
    this.searchInput = null;
    this.indexDiv = null;

    this.indexConveyer = null;

    this.isIndexDragging = false;
    this.isDragging = false;

    this.query = null;
  }

  didMount() {
    if (this.params.filterBy === 'resultFilters') {
      this.renderResultBrands();
    }
    else {
      this.fetchAndRenderBrands();
    }

    if (this.selected) {
      this.conveyer = new Conveyer(this.selected);
      this.conveyer.on('beginScroll', () => {
        this.isDragging = true;
      });
      this.selected.addEventListener('mouseup', () => {
        setTimeout(() => {
          this.isDragging = false;
        }, 100);
      });
    }
    if (this.indexDiv) {
      this.indexConveyer = new Conveyer(this.indexDiv);
      this.indexConveyer.on('beginScroll', () => {
        this.isIndexDragging = true;
      });
      this.indexConveyer.on('finishScroll', (e) => {
        this.isIndexDragging = false;
      });
      const handleScroll = () => {
        const maxScroll = this.indexDiv.scrollWidth - this.indexDiv.clientWidth - 5;
        if (this.indexDiv.scrollLeft >= maxScroll) {
          this.indexNext.classList.add('disabled');
        }
        else if (this.indexDiv.scrollLeft === 0) {
          this.indexPrev.classList.add('disabled');
        }
        else {
          this.indexNext.classList.remove('disabled');
          this.indexPrev.classList.remove('disabled');
        }
      }
      this.indexDiv.addEventListener('scroll', handleScroll);
      this.indexPrev.classList.add('disabled');
      this.indexPrev.addEventListener('click', () => {
        this.indexDiv.scrollTo(this.indexDiv.scrollLeft - 60, 0);
      });
      this.indexNext.addEventListener('click', () => {
        this.indexDiv.scrollTo(this.indexDiv.scrollLeft + 60, 0);
      });
    }
  }

  didUpdate(prevState) {
    if (
      this.params.filterBy === 'resultFilters'
      &&
      !isEqual(
        prevState.search?.filters?.brandName,
        this.state.search?.filters?.brandName
      )
    ) {
      this.renderResultBrands();
    }
    else if (this.params.filterBy !== 'resultFilters' && (
      prevState.filter.categoryId !== this.state.filter.categoryId
      ||
      !isEqual(prevState.filter.params.subcategory, this.state.filter.params.subcategory)
      ||
      prevState.filter.categorySlice !== this.state.filter.categorySlice
    )) {
      this.fetchAndRenderBrands();
    }
    else if (
      !isEqual(prevState.filter.params.brands, this.state.filter.params.brands)
    ) {
      const prevBrands = prevState.filter.params.brands;
      const brands = this.state.filter.params.brands;
      const addedItems = difference(brands, prevBrands);
      const removedItems = difference(prevBrands, brands);
      addedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-brand-id="${val}"]`);
        if (!el) return;

        el.classList.add('active');
      });
      removedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-brand-id="${val}"]`);
        if (!el) return;

        el.classList.remove('active');
      });

      this.renderSelectedBrands();
    }
  }

  handleItemClick = (e, brand) => {
    e.stopPropagation();
    const { categoryId, params } = this.state.filter;

    EventEmitter.emit('brandClick', {
      categoryId,
      brand: brand.brandsFilter,
    });

    let { brands = [] } = params;
    if (brands.includes(brand.brandsFilter)) {
      brands = brands.filter(b => b !== brand.brandsFilter)
      EventEmitter.emit('brandRemoved', {
        categoryId,
        brand: brand.brandsFilter,
      });
    }
    else {
      brands = [...brands, brand.brandsFilter];
      EventEmitter.emit('brandApplied', {
        categoryId,
        brand: brand.brandsFilter,
      });
    }

    this.setFilter({
      params: {
        ...params,
        brands,
      },
    });
  }

  get indexId() {
    const { categoryId, categorySlice, params } = this.state.filter;
    const { subcategory } = params;
    return (subcategory && subcategory.length ? `${subcategory.join('|')}` : categoryId) + (categorySlice ? `*${categorySlice}` : '');
  }

  get brands() {
    let brands = [];
    let setBrands = [];

    if (this.params.sortedBrands && this.params.sortedBrands.length) {
      setBrands = cloneDeep(this.params.sortedBrands).map(value => ({ label: value, brandsFilter: value, isActive: false }));
    }

    let fetchedBrands = [];
    if (this.params.filterBy === 'resultFilters') {
      fetchedBrands = sortBy(this.state.search?.filters?.brandName
        ? Object.values(this.state.search?.filters?.brandName).map(b => ({ brandsFilter: b.value, label: b.value, indexer: b.value.toUpperCase(), isActive: b.isActive }))
        : [], b => b.indexer)
    }
    else {
      fetchedBrands = Array.isArray(this.brandsByCategories[this.indexId])
        ? this.brandsByCategories[this.indexId].map(b => ({ ...b, isActive: b.isActive !== undefined ? b.isActive : true }))
        : [];
    }

    const fetchedBrandsIndex = keyBy(fetchedBrands, 'brandsFilter');
    const setBrandsIndex = keyBy(setBrands, 'brandsFilter');

    
    setBrands.forEach(b => {
      if (fetchedBrandsIndex[b.brandsFilter]) {
        brands.push({
          ...b,
          isActive: fetchedBrandsIndex[b.brandsFilter].isActive,
        });
      }
    });
    
    fetchedBrands.forEach(b => {
      if (!setBrandsIndex[b.brandsFilter]) {
        brands.push(b);
      }
    });
    
    if (Array.isArray(this.params.activeBrands) && this.params.activeBrands.length) {
      brands = brands.filter(b => this.params.activeBrands.includes(b.brandsFilter));
    }

    return brands;
  }

  set brands(brands) {
    this.brandsByCategories[this.indexId] = brands;
  }

  async fetchAndRenderBrands() {
    const { searchAdditionalParams } = this.state.config;
    const id = this.indexId;
    if (!Array.isArray(this.brandsByCategories[this.indexId])) {
      let data = [];
      try {
        data = await client.brandsByCategories(id, searchAdditionalParams);
      } catch (e) {
        console.log(`Brand fetching failed: ${id}`);
      }
      this.brands = Array.isArray(data) ? data : isObject(data) && !isEmpty(data) ? Object.values(data)[0] : data;
    }

    this.fuse.setCollection(this.brands);
    this.renderBrands(this.brands);
    this.renderSelectedBrands();
  }

  renderPredefinedBrands() {
    this.fuse.setCollection(this.brands);
    this.renderBrands(this.brands);
    this.renderSelectedBrands();
  }

  renderResultBrands() {
    this.fuse.setCollection(this.brands);
    
    if (this.query) {
      const found = this.fuse.search(this.query);
      const items = found.map(f => f.item);
      this.renderBrands(items, true);
      return;
    }

    this.renderBrands(this.brands);
    this.renderSelectedBrands();
  }

  resetSearch = () => {
    this.searchInput.value = '';
    this.renderBrands(this.brands);
    this.searchInput.parentElement.classList.remove('is-not-empty');
    this.query = null;
  }

  search = (e) => {
    this.isTyping = true;

    const query = e.target.value;
    if (!query) {
      this.renderBrands(this.brands);
      this.query = null;
      return;
    }

    e.target.parentElement.classList.add('is-not-empty');
    const found = this.fuse.search(query);
    const items = found.map(f => f.item);
    this.query = query;

    this.renderBrands(items, true);
  }

  removeBrand(brand) {
    const { categoryId, params } = this.state.filter;
    const { brands } = params;

    EventEmitter.emit('brandRemoved', {
      categoryId, brand,
    });

    this.setFilter({
      params: {
        ...params,
        brands: brands.filter(b => b !== brand),
      },
    });
  }

  renderSearchBar() {
    const form = document.createElement('form');
    const input = document.createElement('input');
    const resetButton = document.createElement('button');
    resetButton.classList.add('reset-button');
    resetButton.type = 'button';
    input.placeholder = `Search`;
    form.appendChild(input);
    form.appendChild(resetButton);
    this.mainElement.appendChild(form);

    this.searchInput = input;

    input.addEventListener('keyup', this.search);
    resetButton.addEventListener('click', this.resetSearch);
  }

  handleSelectedClick = (brand) => {
    if (this.isDragging) return;

    this.removeBrand(brand);
  }

  handleIndexClick = (e) => {
    if (this.isIndexDragging) return;

    const letter = e.target.innerText;
    const li = this.mainElement.querySelector(`[data-brand-index="${letter}"]`);
    // scroll to the position
    if (window.innerWidth < 768) {
      // get element scroll position based on the container
      const scrollPosition = li.offsetTop - this.mainElement.offsetTop - 100;
      this.ul.scrollTo(0, scrollPosition);
    }
    else {
      const scrollPosition = li.offsetTop - 180;
      this.ul.scrollTo(0, scrollPosition);
    }
  }

  renderSelectedBrands() {
    const { brands } = this.state.filter.params;

    if (this.selected) {
      this.selected.innerHTML = '';
    }

    if (!brands) return;

    brands.forEach(brand => {
      const element = document.createElement('span');
      element.innerHTML = brand;
      if (this.selected) this.selected.appendChild(element);

      element.addEventListener('click', () => this.handleSelectedClick(brand));
    });
  }

  renderBrands(brands, isSearch = false) {
    const { params: { brands: currentBrands = [] } } = this.state.filter;
    let displayHeaders = this.params.displayHeaders;

    this.ul.innerHTML = '';

    if (!brands) return;

    if (isSearch || brands.length <= 20) displayHeaders = false;

    const indexLetters = [];

    let currentLetter = null;
    brands.filter(b => b.isActive).forEach((brand, refIndex) => {
      if (!brand.label) return;

      const nextLetter = (
        !isNaN(parseInt(brand.label[0], 10)) || brand.label[0].match(/^[^a-zA-Z0-9]+$/)
          ? '#'
          : brand.label[0]
      );
      if (displayHeaders && currentLetter !== nextLetter.toUpperCase()) {
        currentLetter = nextLetter.toUpperCase();
        const li = document.createElement('li');
        li.setAttribute('data-brand-index', currentLetter);
        li.classList.add('header');
        li.innerHTML = `<span>${currentLetter}</span>`;
        this.ul.appendChild(li);

        indexLetters.push(currentLetter);
      }

      const li = document.createElement('li');
      li.innerHTML = `<span>${brand.label}</span>`;
      li.setAttribute('data-brand-id', brand.brandsFilter);
      li.setAttribute('data-brand-ref-index', refIndex);
      this.ul.appendChild(li);

      if (currentBrands?.includes(brand.brandsFilter)) {
        li.classList.add('active');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, brand));
    });

    this.indexDiv.innerHTML = '';
    if (indexLetters.length > 0) {
      this.indexContainer.classList.remove('hidden');
      indexLetters.forEach(letter => {
        const span = document.createElement('span');
        span.innerText = letter;
        this.indexDiv.appendChild(span);

        span.addEventListener('click', this.handleIndexClick);
      });
    }
    else {
      this.indexContainer.classList.add('hidden');
    }

    if (this.ul.innerHTML === '') {
      const li = document.createElement('li');
      li.classList.add('empty-brands');
      li.innerHTML = 'Unfortunately, there are<br />no results matching your request.';
      this.ul.appendChild(li);
    }

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(brands);
    }
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);

    this.renderSearchBar();

    this.indexContainer = document.createElement('div');
    this.indexContainer.classList.add('brands-index-container');
    this.indexPrev = document.createElement('span');
    this.indexPrev.classList.add('prev');
    this.indexNext = document.createElement('span');
    this.indexNext.classList.add('next');

    this.indexDiv = document.createElement('div');
    this.indexDiv.classList.add('brands-index');
    
    this.indexContainer.appendChild(this.indexPrev);
    this.indexContainer.appendChild(this.indexDiv);
    this.indexContainer.appendChild(this.indexNext);
    this.mainElement.appendChild(this.indexContainer);
    
    this.ul = document.createElement('ul');

    if (this.params.displaySelectedBrands) {
      this.selected = document.createElement('div');
      this.selected.classList.add('brands-selected');
      this.mainElement.appendChild(this.selected);
    }

    this.mainElement.appendChild(this.ul);

    return this.mainElement;
  }
}

export default (params) => {
  return new BrandFilter(params);
};
