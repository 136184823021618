import pick from 'lodash/pick';
import find from 'lodash/find';
import { getCatCfg } from './VFCatCfg';
import isEmpty from 'lodash/isEmpty';

class VfCatViewData {
  catcfg = null
  viewBoxWithNoThumnbnail = [0, 0, 250, 250]
  currentPreset = null
  extraBodyGroups = [ ]
  svgSource = null

  constructor (vfcatcfg) {
    this.catcfg = vfcatcfg
    this.curViewBox = null
    this.currentPropState = vfcatcfg.propDefaultVal
    this.presetsList = vfcatcfg.presetsList
    this.svgSource = vfcatcfg.svgSource
  }

  sanitizeFilters (filters) {
    let filterSettings = pick(filters, this.propList())
    // For some cases, interval value needs to be added.
    // And it is defined in defaultVal
    let propsFromDefaults = pick(filters, Object.keys(this.catcfg.propDefaultVal))

    return {...filterSettings, ...propsFromDefaults}
  }

  sanitizePropChange (filters, prop, new_value) {
    if (this.catcfg.sanitizePropChange) {
      this.catcfg.sanitizePropChange(filters, prop, new_value);
      return;
    }
    // Pass through by default
    filters[prop] = new_value
  }

  changePropSelection (prop, sel) {
    this.sanitizePropChange(this.currentPropState, prop, sel)
  }

  setDefaultState (filters) {
    this.currentPropState = this.sanitizeFilters(filters)
  }

  viewBox () {
    return this.viewBoxWithNoThumnbnail
  }

  setViewBox (viewBox) {
    this.curViewBox = viewBox
  }

  propCount (prop) {
    return this.catcfg.maxVal(prop) + 1
  }

  getMaxSelectionIndx (prop) {
    return this.catcfg.maxVal(prop)
  }

  get parts() {
    return this.catcfg.parts;
  }

  get disabledParts() {
    return this.catcfg.parts.filter(p => !p.isPublished).map(p => p.name);
  }

  getPart(name) {
    return find(this.parts, { name });
  }

  propList () {
    return this.catcfg.parts.map(p => p.name);
  }

  prevProp (prop) {
    const currentPropIndex = this.propList().indexOf(prop)
    const nextPropIndex = currentPropIndex > 0 ? currentPropIndex - 1 : this.propList().length - 1
    return this.propList()[nextPropIndex]
  }
  nextProp (prop) {
    const currentPropIndex = this.propList().indexOf(prop)
    const nextPropIndex = currentPropIndex < this.propList().length - 1 ? currentPropIndex + 1 : 0
    return this.propList()[nextPropIndex]
  }
  nextPreset (backward) {
    if (this.currentPreset === null) {
      this.currentPreset = 0
    }
    this.currentPreset += backward ? -1 : 1
    this.currentPreset = (this.currentPreset + this.presetsList.length) % this.presetsList.length
    return this.presetsList[this.currentPreset]
  }

  evaluateDisabledValues(prop, state) {
    if (this.catcfg.evaluateDisabledValues) {
      return this.catcfg.evaluateDisabledValues(prop, state) || [];
    }
    return [];
  }

  overrideBodyPartGroupName (state, prop, val) {
    // console.log('state, prop, val', state, prop, val);
    let override;
    let combination;
    if (typeof this.catcfg.overrideBodyPartGroupName[prop] === 'object') {
      this.catcfg.overrideBodyPartGroupName[prop].forEach(combineConfig => {
        if (override) return;

        let conditionPassed = true;
        if (combineConfig.on) {
          combineConfig.on.forEach(condition => {
            if (!conditionPassed) return;

            const [part, values] = condition;
            if (values.includes(state[part])) {
              conditionPassed = true;
            }
            else {
              conditionPassed = false;
            }
          });
        }
        if (conditionPassed) {
          override = ``;
          if (typeof combineConfig.to === 'string') {
            override = combineConfig.to;
          }
          else {
            combineConfig.to.forEach(part => {
              override = `${override}${override ? '_' : ''}${part}${state[part] ? '_' + state[part] : ''}`;
            });
          }

          combination = typeof combineConfig.to === 'string' ? [prop] : combineConfig.to;
        }
      });
    }

    if (!override && this.catcfg.overrideBodyPartGroupName_DEPRECATED) {
      override = this.catcfg.overrideBodyPartGroupName_DEPRECATED(state, prop, val)
    }

    return { override, combination };
  }

  getTnAllOverride(state, prop, val) {
    // console.log('getTnAllOverride', state, prop, val);
    let { override } = this.overrideBodyPartGroupName(state, prop, val);

    if (!override) {
      override = `${prop}_${val}`;
    }

    return override;
  }

  getBodyPartGroupName (prop, propState = null) {
    let state = propState || this.currentPropState
    let groupName = null;
    let extraProps = {};

    // if (this.catcfg.tnOptionAll && this.catcfg.tnOptionAll[prop] && state[prop] === 'all') {
    //   state[prop] = this.catcfg.tnOptionAll[prop];
    // }
    // console.log('getBodyPartGroupName');
    // console.log(prop, state);

    let { override, combination } = this.overrideBodyPartGroupName(state, prop, state[prop]);
    groupName = override;

    // console.log('override, combination', override, combination);
    // console.log('this.catcfg.tnOptionAll', this.catcfg.tnOptionAll);
    // console.log('state', state);
    const overrideState = ((tnOptionAll, combination, currentState) => {
      // console.log('inside', tnOptionAll, combination, currentState);
      return combination.reduce((overrideState, bodyPartName) => {
        // console.log(bodyPartName, overrideState);
        if (tnOptionAll[bodyPartName] && currentState[bodyPartName] === 'all') {
          overrideState[bodyPartName] = tnOptionAll[bodyPartName];
        }
        return overrideState;
      }, {});
    })(this.catcfg.tnOptionAll || {}, combination || [], state);
    // console.log('overrideState', overrideState);

    if (!isEmpty(overrideState)) {
      const { override, combination = [] } = this.overrideBodyPartGroupName({ ...state, ...overrideState }, prop, state[prop]);
      groupName = override;
      // console.log('combination', combination);
      if (combination.reduce((shouldAddClass, bodyPart) => {
        if (state[bodyPart] !== 'all') return false;
        return shouldAddClass;
      },  true)) {
        extraProps.className = 'option-all';
      }
    }

    if (!groupName) {
      if (state[prop] === 'all' && this.catcfg.tnOptionAll[prop]) {
        groupName = prop + '_' + this.catcfg.tnOptionAll[prop];
      }
      else {
        groupName = prop + '_' + (Array.isArray(state[prop]) ? state[prop][state[prop].length - 1] : state[prop]);
      }
    }

    const part = this.getPart(prop);
    if (!part.isPublished) {
      extraProps.className = 'disabled';
    }
    // console.log('groupName', groupName);
    // console.log('--------------------------');
    return {
      groupName,
      extraProps,
    };
  }

  getBodyPartGroupName_DEPRECATED (prop, propState = null) {
    let state = propState || this.currentPropState
    let groupName = null;
    let extraProps = {};

    let { override, combination } = this.overrideBodyPartGroupName(state, prop, state[prop]);
    groupName = override;

    const combinationProp = (combination && combination.length === 2 && (() => {
      const combinationProp = combination.filter(p => p !== prop)[0]
      return Object.keys(this.catcfg.tnOptionAll).includes(combinationProp) && state[combinationProp] === 'all' && combinationProp
    })());
    if (
      this.catcfg.tnOptionAll
      &&
      (state[prop] === 'all' || state[combinationProp] === 'all')
      &&
      (
        this.catcfg.tnOptionAll[prop]
        ||
        combinationProp
      )
    ) {
      let usedProp = prop;
      if (combinationProp) {
        usedProp = combinationProp;
      }
      groupName = this.getTnAllOverride({ ...state, [usedProp]: this.catcfg.tnOptionAll[usedProp] }, prop, this.catcfg.tnOptionAll[usedProp]);
      if (usedProp === prop)
        extraProps.className = 'option-all';
    }

    if (!groupName) {
      groupName = prop + '_' + (Array.isArray(state[prop]) ? state[prop][state[prop].length - 1] : state[prop]);
    }

    const part = this.getPart(prop);
    if (!part.isPublished) {
      extraProps.className = 'disabled';
    }

    return {
      groupName,
      extraProps,
    };
  }

  getHoverGroupName (prop) {
    return this.getBodyPartGroupName(prop, this.catcfg.propMaxVal)
  }

  fullbodyGroupNames () {
    return ['mannequin']
      .concat(this.catcfg.extraBodyGroups || [])
  }

  touchGroupName (prop) {
    return prop + '_touch'
  }
  touchpointGroupName (bodypart) {
    if (this.catcfg.touchpointGroupName) {
      return this.catcfg.touchpointGroupName(bodypart);
    }
    return bodypart + '_point'
  }

  evaluateDisabledParts() {
    let disabledParts = null;
    if (this.catcfg.evaluateDisabledParts) {
      disabledParts = this.catcfg.evaluateDisabledParts(
        this.currentPropState
      );
    }
    return (
      Array.isArray(disabledParts)
        ? disabledParts
        : []
    );
  }
}

export function getCatData (categoryCfg) {
  const cfg = getCatCfg(categoryCfg);
  return new VfCatViewData(cfg);
}
