
export class Hooks {
  hooks = {};

  register(name, callback) {
    if (!Array.isArray(this.hooks[name])) {
      this.hooks[name] = [];
    }

    this.hooks[name].push(callback);
  }

  unRegister(name, callback) {
    if (Array.isArray(this.hooks[name])) {
      this.hooks[name] = this.hooks[name].filter(cb => cb !== callback);
    }
  }

  call(name, ...args) {
    let newValue = args[0];
    if (Array.isArray(this.hooks[name])) {
      for (const callback of this.hooks[name]) {
        newValue = callback(...args);
      }
    }
    return newValue;
  }
}

const hooks = new Hooks();

export default hooks;
