import React, { Component } from 'react'
import notFound from '../../assets/images/404_Illustration.svg'

export default class NotFound extends Component {
  render () {
    return (
      // <FlatBanner style={styles.banner}>
      //   <h2>Oops! The page you are looking for is not available.</h2>
      // </FlatBanner>
      <div style={styles.notFound}>
        {
          <img src={notFound} alt='not-found' />
        }
      </div>
    )
  }
}

const styles = {
  banner: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10
  },
  notFound: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    width: '100%',
  }
}
