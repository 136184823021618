import baseMapping from './categories-production.json';

const mapping = baseMapping.reduce((mapping, category) => {
  mapping[category.category_id] = {
    id: category.category_id,
    rewriteUrl: category.rewrite_url,
  };
  return mapping;
}, {});

const baseCategoriesMapping = baseMapping;

export { baseCategoriesMapping };

export default mapping;
